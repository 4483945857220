/* @flow */

import * as types from "../actions";
import produce from "immer";

const data = (state: Object = {
	enabled: false
}, action) => produce(state, draft => {
    switch (action.type) {
    	case types.CAN_PLAY_AD:
            draft.enabled = false;
            return;
    }
});

export default data;