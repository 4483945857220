/* @flow */
/*eslint-disable prefer-const */

import React from 'react';
import Component from "../super";
import SearcherPage from "../../containers/searcher";


class Searcher extends Component {
  getComponent() {
  	return SearcherPage;
  }
}

Searcher.propTypes = {};

Searcher.defaultProps = {};

export default Searcher;