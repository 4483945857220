/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as moment from "moment";
import "moment/locale/es";
import "moment/locale/en-gb";
import { updateTranslation } from "../../actions";
import {
  updateCategories,
  updateTags
} from "../../actions/edit"
import { Link } from "react-router-dom";
import EditButton from "../../components/edit-button";
import TranslateButton from "../improve-translation";
import EditTagsAndCategories from "../../components/edit-tags-and-categories";
import { getTagLink } from "../../utils/link-maker";
import { getCategoryLink } from "../../utils/link-maker";
import { getPostLink } from "../../utils/link-maker";
import i18n from "../../i18n";

class PostDetailFooter extends Component {
  constructor(props: any) {
    super(props);
    this.tagsAndCategoriesChanged = null;
  }
  render() {
    return (
      <footer className="entry-footer">
        <span className="byline">
          <span className="author vcard">
            <img
              alt=""
              src="https://0.gravatar.com/avatar/f1169c3354143c5a138a38abd40121eb?s=49&amp;d=mm&amp;r=g"
              srcSet="https://0.gravatar.com/avatar/f1169c3354143c5a138a38abd40121eb?s=98&amp;d=mm&amp;r=g 2x"
              className="avatar avatar-49 photo grav-hashed grav-hijack"
              height="49"
              width="49"
              originals="49"
              scale="1.7999999523162842"
              id="grav-f1169c3354143c5a138a38abd40121eb-0"
            />
            <span className="screen-reader-text">Autor </span>
            <Link className="url fn n" to="/author/vegamecum/">
              vegamecum
            </Link>
          </span>
        </span>
        <span className="posted-on">
          <span className="screen-reader-text">Publicado el </span>
          <Link to={getPostLink(this.props.post)} rel="bookmark">
            {this._renderPublishedDate()}
            {this._renderUpdatedDate()}
          </Link>
        </span>
        <EditButton
          tagName="div"
          active={this.props.enableEdit}
          editComponent={
            <EditTagsAndCategories
              post={{
                tags: this.props.post.tags || [],
                categories: this.props.post.categories || []
              }}
              onChange={(tagsAndCategories) => {
                this.tagsAndCategoriesChanged = tagsAndCategories;
              }}
            />
          }
          disableTagName={React.Fragment}
          readComponent={
            <React.Fragment>
              {this._renderCategories(this.props.post.categories)}
              {this._renderTags(this.props.post.tags)}
            </React.Fragment>
          }
          onSavePress={() => {
            if (this.tagsAndCategoriesChanged) {
              this.props.dispatch(
                updateCategories(
                  this.props.post,
                  this.tagsAndCategoriesChanged.categories
                )
              );
              this.props.dispatch(
                updateTags(
                  this.props.post,
                  this.tagsAndCategoriesChanged.tags
                )
              );
            }
          }}
        />
      </footer>
    );
  }
  _renderCategories(categories = []) {
    //
    let space;
    categories = categories.map((category, index) => {
      space = index > 0 ? ", " : "";
      return (
        <React.Fragment key={category}>
          {space}
          <Link to={getCategoryLink(category)} rel="category tag">
            {i18n.t(`detail.related.categoryList.${category.toLowerCase()}`, {
              defaultValue: category,
            })}
          </Link>
        </React.Fragment>
      );
    });
    return (
      <span className="cat-links">
        <span className="screen-reader-text">Categorías </span>
        {categories}
      </span>
    );
  }
  _renderTags(tags = []) {
    let space;
    tags = tags.map((tag, index) => {
      space = index > 0 ? ", " : "";
      return (
        <React.Fragment key={tag}>
          {space}
          {this._renderTag(tag, index)}
        </React.Fragment>
      );
    });
    return (
      <span className="tags-links">
        <span className="screen-reader-text">Etiquetas </span>
        {tags}
      </span>
    );
  }
  _renderTag(tag, index) {
    return (
      <React.Fragment>
        <Link to={getTagLink(tag)} rel="tag">
          {tag}
        </Link>
        <TranslateButton
          active={this.props.enableImproveTranslations}
          text={tag}
          onTranslateDone={this._onTagTranslateDone.bind(this, index)}
        />
      </React.Fragment>
    );
  }
  _onTagTranslateDone(index, text) {
    this.props.dispatch(
      updateTranslation({
        language: "en",
        slug: this.props.post.slug,
        key: `posts/${this.props.post.slug}/tags/${index}`,
        text,
        tsPath: "posts/" + this.props.post.slug + "/modified",
      })
    );
  }
  _renderPublishedDate() {
    return this._renderDate(this.props.post.date, "entry-date published");
  }
  _renderUpdatedDate() {
    return this._renderDate(this.props.post.modified, "updated");
  }
  _renderDate(date, className) {
    date = moment(date).locale(i18n.getSelectedLanguage());
    return (
      <time className={className} dateTime={date.format()}>
        {date.format("LL")}
      </time>
    );
  }
}

PostDetailFooter.propTypes = {
  dispatch: PropTypes.func,
};

PostDetailFooter.defaultProps = {
  dispatch: () => {},
};

export default connect((state, props) => {
  let enableImproveTranslations = props.post.__vo && state.user.admin;
  let enableEdit = !enableImproveTranslations && state.user.admin;
  return {
    enableImproveTranslations,
    enableEdit,
  };
})(PostDetailFooter);
