/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import I18n from "../../i18n";
import _ from "lodash";

class SideMenuList extends Component {
  constructor(props: any) {
    super(props);
    this._renderSkeleton = _.memoize(this._renderSkeleton.bind(this));
  }
  render() {
    return (
      <section className="widget widget_categories">
        <h2 className="widget-title">{this.props.title}</h2>
        {this._renderList()}
      </section>
    );
  }
  _renderList() {
    if (this.props.items.length === 0) {
      return this._renderSkeleton();
    }
    return <ul>{this.props.items.map(this._renderItem)}</ul>;
  }
  _renderItem(item) {
    let name = I18n.t(
      `detail.related.categoryList.${item.name.toLowerCase()}`,
      {
        defaultValue: item.name,
      }
    );

    return (
      <li className="cat-item" key={item.name}>
        <Link to={item.link}>{name}</Link>
      </li>
    );
  }
  _renderSkeleton() {
    let totalItems = 10;
    return (
      <ContentLoader width={300} height={totalItems * 23}>
        {this._renderSkeletonItems(totalItems)}
      </ContentLoader>
    );
  }
  _renderSkeletonItems(totalItems) {
    let width,
      height = 17,
      y = 5,
      margin = 6;
    let items = [];
    for (let index = 0; index < totalItems; index++) {
      width = 60 + Math.random() * 240;
      items.push(<rect width={width} height={height} y={y} key={"" + index} />);
      y += height + margin;
    }
    return items;
  }
}

SideMenuList.propTypes = {
  dispatch: PropTypes.func,
  items: PropTypes.array,
};

SideMenuList.defaultProps = {
  dispatch: () => {},
  items: [],
};

export default SideMenuList;
