import nutrients from './nutrients';

const map = {
	name: 'diet',
	key: 'diet',
	type: 'object',
	child: [{
		name: 'info',
		key: 'i',
		type: 'object',
		child: [{
			name: 'days',
			key: 'd',
			type: 'array',
			child: [{
				name: '*',
				key: '*',
				type: 'object',
				child: [{
					name: 'name',
					key: 'n'
				}]
			}]
		},{
			name: 'meals',
			key: 'm',
			type: 'array',
			child: [{
				name: '*',
				key: '*',
				type: 'object',
				child: [{
					name: 'name',
					key: 'n',
				}, {
					name: 'startTime',
					key: 's',
				}]
			}]
		}]
		},{
		name: 'list',
		key: 'l',
		type: 'object',
		child: [{
			name: '*',
			key: '*',
			type: 'object',
			child: [{
				name: 'days',
				key: 'd',
				type: 'object',
				child: [{
					name: '*',
					key: '*',
					type: 'object',
					child: [{
						name: 'meals',
						key: 'm',
						type: 'object',
						child: [{
							name: '*',
							key: '*',
							type: 'object',
							child: [{
								name: 'name',
								key: 'n',
							}, {
								name: 'startTime',
								key: 's',
							}, {
								name: 'plates',
								key: 'p',
								type: 'object',
								child: [{
									name: '*',
									key: '*',
									type: 'object',
									child: [{
										name: 'image',
										key: 'i'
									},{
										name: 'nutrients',
										key: 'n',
										type: 'object',
										child: [{
											name: 'all',
											key: 'all',
											type: 'object',
											child: nutrients.child
										}]
									},{
										name: 'slug',
										key: 's'
									},{
										name: 'title',
										key: 't'
									},{
										name: 'units',
										key: 'u'
									},{
										name: 'summary',
										key: 'r',
										type: 'object',
										child: [{
											name: 'time_minutes',
											key: 't'
										}]
									}]
								}]
							}]
						}]
					}]
				}]
			}]
		}]
	}]
};

export default map;