/* @flow */

import * as types from '../actions'
import produce from 'immer'

const data = (
  state = {
    show: false,
    list: [],
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case types.RECEIVE_FILTERS:
        draft.list = action.data
        return
    }
  })

export default data
