/* @flow */
/*eslint-disable prefer-const */

import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import {
  detailVisualized,
  loadPostBySlug,
  updateTranslation,
} from "../../actions";
import { finishEditPostByPath, startEditPostByPath } from "../../actions/edit";
import NotFound from "../../components/not-found";
import PostArticle from "../../components/post-article";
import PostArticleSummary from "../../components/post-article-summary";
import PostSkeleton from "../../components/post-skeleton";
import { getPostLink } from "../../utils/link-maker";

class DetailContent extends Component {
  componentDidMount() {
    this.props.dispatch(detailVisualized());
    this._fetchData();
    if (this.props.enableEdit) {
      this.props.dispatch(startEditPostByPath(this.props.postPath));
    }
  }
  componentWillUnmount() {
    if (this.props.enableEdit) {
      this.props.dispatch(finishEditPostByPath(this.props.postPath));
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.initialized && !prevProps.initialized) {
      this._fetchData();
    }
    if (this.props.enableEdit && !prevProps.enableEdit) {
      console.log("componentDidUpdate startEditPostByPath");
      this.props.dispatch(startEditPostByPath(this.props.postPath));
    }
  }
  render() {
    return this._renderContent();
  }
  _renderContent() {
    if (this.props.post) {
      if (this.props.summary) {
        return (
          <PostArticleSummary
            {...this.props.post}
            isDraft={this.props.isDraft}
            hasBeforeAd={this.props.hasBeforeAd}
            hasAfterAd={this.props.hasAfterAd}
          />
        );
      } else {
        let canonical = getPostLink(this.props.post, undefined, false, this.props.isAMP);
        let currentUrl = this.props.match.url;
        if (!this.props.isDraft && canonical !== currentUrl) {
          return <Redirect to={canonical} />;
        }
        return (
          <PostArticle
            {...this.props.post}
            enableImproveTranslations={this.props.enableImproveTranslations}
            onTitleTranslateDone={(text) => {
              this.props.dispatch(
                updateTranslation({
                  language: "en",
                  slug: this.props.post.slug,
                  key: "posts/" + this.props.post.slug + "/title",
                  text,
                  tsPath: "posts/" + this.props.post.slug + "/modified",
                })
              );
            }}
          />
        );
      }
    } else if (this.props.errorLoading) {
      return (
        <React.Fragment>
          <NotFound />
          {this.props.isAdmin && (
            <button onClick={this._createEmptyPost} type="button">
              CREATE NEW
            </button>
          )}
        </React.Fragment>
      );
    } else if (this._isLoadingActive()) {
      return <PostSkeleton totalParagraphs={3} />;
    }
    return null;
  }
  _fetchData() {
    if (!this.props.post && this.props.postSlug) {
      if (!this.props.enableEdit) {
        this.props.dispatch(loadPostBySlug(this.props.postSlug));
      }
    }
  }
  _isLoadingActive() {
    if (this.props.errorLoading) {
      return false;
    }
    return (
      this.props.isFetching || (!this.props.post && !this.props.errorLoading)
    );
  }
  _createEmptyPost() {}
}

DetailContent.defaultProps = {
  dispatch: () => {},
  isFetching: false,
  post: null,
  isAMP: false
};

export default withRouter(
  connect((state, props, x) => {
    let post,
      loading = false,
      error = false;
    if (!props.post) {
      //let bucket = props.isDraft ? 'lists.drafts' : 'all';
      let bucket = "all";
      post = _.get(state.posts, `${bucket}.${props.postSlug}`);
      if (post) {
        loading = post.loading;
        error = post.error;
        post = post.merged;
      }
    } else {
      post = props.post;
    }
    return {
      initialized: state.app.initialized,
      isFetching: loading,
      errorLoading: error,
      post: post,
      isAdmin: state.user.admin,
      enableEdit:
        props.isDraft || (post ? state.user.admin && !post.__vo : false),
      enableImproveTranslations: post ? post.__vo && state.user.admin : false, //state.settings.enableImproveTranslations,
      canPlayAd: state.ads.enabled,
      orientation: state.screen.orientation,
    };
  })(DetailContent)
);
