/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import SideMenuList from '../../components/side-menu-list';
import { getFilterLink } from '../../utils/link-maker';
import I18n from '../../i18n';

class Filter extends Component {
  render() {
    return <SideMenuList title={I18n.t('sideBar.filters')} items={this._getItems()}/>
  }
  _getItems() {
    return this.props.filters.map(item => ({
      name: item.name,
      link: getFilterLink(item.name)
    }));
  }
}

Filter.propTypes = {
  dispatch: PropTypes.func,
  filters: PropTypes.array
};

Filter.defaultProps = {
  dispatch: () => {},
  filters: []
};

export default connect((state) => ({
  filters: state.filters.list,
}))(Filter);
