import * as firebaseActions from './firebase-actions';
export const LOADED_BUBBLES = "LOADED_BUBBLES";

const receiveBubbles = (data: Object): Object => {
    return {
        type: LOADED_BUBBLES,
        data
    };
};

export const fetch = (): Function => {
    return (dispatch, getState) => {
        firebaseActions.getBubbles((snapshot) => {
            dispatch(receiveBubbles(snapshot.val()));
        });
    };
};

export const addBubble = (data: Object): Function => {
    return (dispatch, getState) => {
        firebaseActions.addBubble(data);
    };
};

export const deleteBubble = (data: Object, index: number): Function => {
    return (dispatch, getState) => {
        firebaseActions.deleteBubble(data, index);
    };
};
