/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import I18n from "../../i18n";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  PinterestShareButton,
  PinterestIcon,
} from "react-share";

import { firebase } from "../../platform";

const storage = firebase.storage();

class SharePost extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <div className="sharedaddy sd-sharing-enabled">
        <div className="robots-nocontent sd-block sd-social sd-social-icon sd-sharing">
          <h3 className="sd-title">{I18n.t("detail.engagement.shareTitle")}</h3>
          <div className="sd-content">
            <ul>
              <li>
                <FacebookShareButton
                  url={this.props.link}
                  quote={this.props.title}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={this.props.link}
                  quote={this.props.title}
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </li>
              <li>
                <TelegramShareButton
                  url={this.props.link}
                  quote={this.props.title}
                  className="Demo__some-network__share-button"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
              </li>
              <li>
                <WhatsappShareButton
                  url={this.props.link}
                  quote={this.props.title}
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </li>
              {this._renderPinterestButton()}
            </ul>
          </div>
        </div>
      </div>
    );
  }
  _renderPinterestButton() {
    return (
      <li>
        <PinterestShareButton
          description={this.props.title}
          media={this.props.mainImage}
          url={this.props.link}
          className="Demo__some-network__share-button"
        >
          <PinterestIcon size={32} round />
        </PinterestShareButton>
      </li>
    );
  }
}

SharePost.propTypes = {};

SharePost.defaultProps = {};

export default SharePost;
