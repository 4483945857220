/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchData } from "../../actions";
import NotFound from "../../components/not-found";
import Pager from "../../components/pager";
import Detail from "../detail";
import _ from "lodash";
import { Helmet } from "react-helmet";
import PostSkeleton from "../../components/post-skeleton";
import ContentLoader from "react-content-loader";
import { getLinkForPathAndSearch } from "../../utils/link-maker";
import I18n from "../../i18n";
import { getPostLink } from "../../utils/link-maker";
import { withRouter } from "react-router-dom";

class Posts extends PureComponent {
  static defaultProps = {
    dispatch: () => {},
    postsContainer: {},
    isFetching: false,
  };
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    if (this.props.fetchDataOnMount) {
      this._fetchData();
    }
    if (this.props.navigateOnLoad) {
      this._navigateOnLoad();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(this.props.postFilters, prevProps.postFilters)) {
      this._fetchData();
    }
    if (
      this.props.navigateOnLoad &&
      this.props.posts !== prevProps.posts &&
      this.props.posts.length === 1
    ) {
      this._navigateOnLoad();
    }
  }
  _fetchData() {
    if (this.props.postFilters) {
      let filterHasChange = !_.isEqual(
        this.props.appliedFilters,
        this.props.postFilters
      );
      if (filterHasChange) {
        console.log(
          "this.props.postFilters",
          this.props.postFilters,
          this.props.listKey
        );
        this.props.dispatch(
          fetchData(this.props.postFilters, this.props.listKey, true)
        );
      }
    }
  }
  render() {
    let content;
    let pager;
    let header;
    if (this.props.isFetching) {
      header = this._renderHeader();
      content = this._renderLoading();
      if (typeof document !== "undefined") {
        document.body.classList.remove("search-no-results");
      }
    } else if (this.props.posts.length === 0 || this.props.isError) {
      content = this._renderEmptyContent();
      if (typeof document !== "undefined") {
        document.body.classList.add("search-no-results");
      }
    } else {
      if (typeof document !== "undefined") {
        document.body.classList.remove("search-no-results");
      }
      header = this._renderHeader();
      content = this._renderList();
      pager = this._renderPager();
    }
    return (
      <main id="main" className="site-main" role="main">
        {this._renderMeta()}
        {header}
        {content}
        {pager}
      </main>
    );
  }
  _renderHeader() {
    if (this.props.searchText) {
      let width = 400;
      let height = 28;
      const content = this.props.postFilters ? (
        this.props.searchText.text
      ) : (
        <ContentLoader style={{ width, height }} width={width} height={height}>
          <rect width={width} height={height} />
        </ContentLoader>
      );
      return (
        <header className="page-header">
          <h1 className="page-title">{content}</h1>
        </header>
      );
    }
  }
  _renderPager() {
    if (this.props.pager) {
      return (
        <Pager
          pager={this.props.pager}
          navigationParams={this.props.navigationParams}
        />
      );
    }
  }
  _renderLoading() {
    return _.range(this.props.loadingSkeletonNumber).map((index) => (
      <PostSkeleton key={`loading-${index}`} />
    ));
  }
  _renderList() {
    console.log(this.props.posts.length);
    return _.compact(
      this.props.posts.map((item, index) => {
        return this._renderItem(item, index);
      })
    );
  }
  _renderMeta() {
    let description = I18n.t("about.subtitle");
    let image =
      "https://cdn.vegamecum.com/image/width=270/vegamecum.com%2Flogos%2Fmsapp.png";
    let title = this.props.searchText
      ? `${this.props.searchText.text} | Vegamecum`
      : `Vegamecum | ${I18n.t("about.subtitle")}`;
    let link = getLinkForPathAndSearch(
      this.props.navigationParams,
      undefined,
      true
    );
    return (
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={link} />
        <link
          rel="alternate"
          hreflang="x-default"
          href={getLinkForPathAndSearch(
            this.props.navigationParams,
            "x-default",
            true
          )}
        />
        <link
          rel="alternate"
          hreflang="es"
          href={getLinkForPathAndSearch(
            this.props.navigationParams,
            "es",
            true
          )}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={getLinkForPathAndSearch(
            this.props.navigationParams,
            "en",
            true
          )}
        />
        <meta name="description" content={description} />
        <meta name="twitter:card" content={"summary_large_image"} />
        <meta name="twitter:site" content={"@vegamecum"} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta
          property="og:locale"
          content={{ es: "es_ES", en: "en_EN" }[I18n.getSelectedLanguage()]}
        />
        <meta property="og:type" content={"article"} />
        <meta property="og:image" content={image} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={link} />
        <meta property="og:site_name" content={"Vegamecum"} />
        <meta
          property="article:publisher"
          content={"https://www.facebook.com/Vegamecum-1705334993044654"}
        />
        <meta
          property="article:author"
          content={"https://www.facebook.com/Vegamecum-1705334993044654"}
        />
        <meta property="fb:app_id" content={"1676595619311398"} />
      </Helmet>
    );
  }
  _renderItem(item, index) {
    if (!item.slug) {
      return;
    }
    return (
      <Detail
        key={item.slug}
        post={item}
        summary={true}
        isDraft={this.props.listKey === "drafts"}
        hasBeforeAd={false}
        hasAfterAd={index === 0 || index === 1}
      />
    );
  }
  _renderEmptyContent() {
    let text,
      value,
      title,
      error = true;
    if (
      this.props.searchText &&
      this.props.searchText.searchType === "search"
    ) {
      title = I18n.t("notFound.searcher.title");
      text = I18n.t("notFound.searcher.subtitle");
      value = this.props.searchText.value;
      error = false;
    }
    return (
      <NotFound error={error} title={title} text={text} searchValue={value} />
    );
  }
  _navigateOnLoad() {
    if (this.props.posts.length === 1) {
      let link = getPostLink(this.props.posts[0]);
      if (link) {
        this.props.history.replace(link);
      }
    }
  }
  onSelectedPost(post) {
    if (this.props.onSelectedPost) {
      this.props.onSelectedPost(post);
    }
  }
}

export default withRouter(
  connect(function (state, props) {
    let postsContainer = state.posts.lists[props.listKey];
    if (!postsContainer || !postsContainer.posts) {
      postsContainer = {
        pager: {
          current: 1,
          total: 1,
        },
        loading: true,
        posts: [],
      };
    }

    let fetchDataOnMount =
      postsContainer.posts.length === 0 || props.fetchDataOnMount;

    return {
      loadingSkeletonNumber: 3,
      appliedFilters: postsContainer.filters,
      isError: postsContainer.error,
      isFetching: postsContainer.loading,
      posts: postsContainer.posts || [],
      pager: postsContainer.pager,
      fetchDataOnMount,
    };
  })(Posts)
);
