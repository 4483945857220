import { XMLSerializer } from 'xmldom';
import _ from 'lodash';
import {getIngredientText, getScalelessUnit} from "./ingredient-parser";
const Serializer = new XMLSerializer().serializeToString;
const RegexIngredient = new RegExp('(\\d+)-ingredient-(\\d+)-(\\d+)', 'g');
const RegexIngredientReplacer = new RegExp('\{(\\d+)-ingredient-(\\d+)-(\\d+)\}', 'g');

export const getStepInfo = (node, recipe, i) => {
    let step = {};
    let nodeText = Serializer(node);
    let meta = getStepMeta(nodeText);
    step.index = i;
    step.title = meta.title || 'Paso '+(i+1);
    step.icon = meta.icon || null;
    let {rawText, regexText, textRegex} = parseStepTextNode(node);
    step.text = rawText;
    if(rawText !== regexText) {
        step.regexText = regexText;    
    }
    if(rawText !== textRegex) {
        step.textRegex = textRegex;
    }
    let figures = node.getElementsByTagName('figure');
    let src;
    step.images = [];
    step.figures = [];
    if(figures.length > 0){
        _.each(figures, (figure) => {
            src = figure.getElementsByTagName('img')[0].getAttribute('src');
            step.images.push(src);
            step.figures.push({
                path: src,
                caption: figure.getElementsByTagName('figcaption')[0].textContent
            });
        });
    }
    step.notes = getStepNote(nodeText);
    step.timer = getStepTimer(nodeText);
    return step;
}



const getStepNote = (text) => {
    let regex = (new RegExp('nota-\\d+', 'g')),
        match = text.match(regex);
    return match ? match.map((found) => {
        return parseInt(found.substring(5),10)-1;
    }) : [];
}

const getStepTimer = (text) => {
    let regex = (new RegExp('wait-\\d+', 'g')),
        match = text.match(regex);
    return match ? parseInt(match[0].substring(5),10) : null;
}

const getStepMeta = (text) => {
    let regex = (new RegExp('paso-[^\\s|"]*', 'g')),
        match = text.match(regex);
    if(match && match.length > 0){
        let m = match[0].substring(5);
        m = m.split('-');
        m = m[0].replace(/_/g,' ');
        return {
            title: m.charAt(0).toUpperCase() + m.slice(1),
            icon: m[1],
        }
    }
    return {
        title: null
    };
}

const parseStepTextNode = (node) => {
    //var spans = node.childNodes('span');
    let nodeSerialized, regexResult;
    let regexText = [], textRegex = [];
    _.each(node.childNodes, (child) => {
        nodeSerialized = Serializer(child);
        regexResult = RegexIngredient.exec(nodeSerialized);
        if(regexResult && regexResult.length === 4) {
            regexText.push(regexResult[0]);
            textRegex.push('{'+regexResult[0]+'}');
        } else {
            regexText.push(child.textContent);
            textRegex.push(child.textContent);
        }
    })
    regexText = regexText.join('');
    textRegex = textRegex.join('');
    return {
        rawText: node.textContent,
        textRegex: textRegex,
        regexText: regexText
    }
}

export const getProcessedStepText = (step, ingredients, units, scaleLess) => {
    let text,
        match,
        regex;
    if(step.textRegex) {
        text = step.textRegex;
        regex = RegexIngredientReplacer;
    } else {
        text = step.regexText;    
        regex = RegexIngredient;
    }
    
    if(text) {
        text = text.replace(regex, function(match, percent, blockNum, ingredientNum, index, wholeText) {
            if(arguments.length === 6){
                try{
                    return getProcessedTextForIngredient(ingredients, percent, blockNum, ingredientNum, units, scaleLess);
                } catch (e) {
                    
                }
            }
            return match;
        });
    }
    
    return text || step.text;
}


export const getProcessedTextForIngredient = (ingredients, percent, blockNum, ingredientNum, units, scaleLess) => {
    let coef = 1,
        ingredient,
        scaleLessUnit;

    coef = percent/100;
    ingredient = _.clone(ingredients[blockNum].list[ingredientNum]);
    ingredient.gramsValue = ingredient.gramsValue*coef;
    scaleLessUnit = scaleLess && getScalelessUnit(ingredient, units);
    return getIngredientText(ingredient, units, scaleLessUnit);
}