/* @flow */

import { combineReducers } from "redux";
import produce from "immer";
import reduceReducers from 'reduce-reducers';
import * as types from "../actions";
import nav from './nav';
import quotes from './quotes';
import popover from './popover';
import toast from './toast';
import menu from './menu';
import alarms from './alarms';
import extras from './extras';
import currentRecipes from './current-recipes';
import bubbles from './bubbles';
import search from './search';
import filters from './filters';
import categories from './categories';
import playlist from './playlist';
import carts from './carts';
import planified from './planified';
import diets from './diets';
import ingredients from './ingredients';
import user from './user';
import nutrients from './nutrients';
import app from './app';
import settings from './settings';
import postsLists from './posts-lists';
import firebase from './firebase';
import ads from './ads';
import screen from './screen';

const data = (state = {
    isFetching: false
}, action) => produce(state, draft => {
    switch (action.type) {
        case types.REQUEST_DATA:
            draft.isFetching = true;
            return;
        case types.REQUEST_DATA_FINISHED:
            draft.isFetching = false;
            return;
    }
});

const appReducer = combineReducers({
    data,
    quotes,
    popover,
    toast,
    menu,
    alarms,
    filters,
    playlist,
    search,
    carts,
    planified,
    diets,
    ingredients,
    user,
    nutrients,
    app,
    settings,
    firebase,
    ads,
    screen,
    extras,
    bubbles,
    currentRecipes,
    posts: postsLists,
    categories,
    nav
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === types.RESET_STORE) {
        state = undefined;
    }

    return appReducer(state, action);
};

/*
const initReducer = (state = {}, action) => produce(state, draft => {
    console.time("reduction " + action.type);
});

const finishReducer = (state = {}, action) => produce(state, draft => {
    console.timeEnd("reduction " + action.type);
});

export default reduceReducers(initReducer, rootReducer, finishReducer);
*/
export default rootReducer;