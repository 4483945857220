/* @flow */

import React, { Component } from 'react';

class View extends Component {
  static defaultProps = {
    tagName: 'div'
  }
  render() {
    return (
      <this.props.tagName style={this.props.style} onClick={this.props.onPress}>{this.props.children}</this.props.tagName>
    );
  }
}

export default View;
