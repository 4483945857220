/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import SideMenuList from '../../components/side-menu-list';
import { getCategoryLink } from '../../utils/link-maker';
import _ from 'lodash';

class ImproveTranslation extends Component {
  constructor() {
    super()
    this.state = {
      isTranslating: false
    }
  }
  render() {
    if(!this.props.active) {
      return null;
    }
    if(this.state.isTranslating) {
      return (
        <React.Fragment>
          <div style={{margin: '10px 0px',padding: '10px', border: '1px dashed'}}>
          {this._renderInputs(this.props.text)}
          </div>
          <button onClick={this._onSavePress.bind(this)} type="button">SAVE</button>
          <button onClick={this._onCancelPress.bind(this)} type="button">CANCEL</button>
        </React.Fragment>
      );
    }
    return (
      <button onClick={this._onImprovePressed.bind(this)} type="button">Mejorar Traducción</button>
    )
  }
  _renderInputs(obj, path = []) {
    if(_.isString(obj)) {
      let stringPath = path.join('.');
      if(this._isValidPath(stringPath)) {
        return (
          <label key={stringPath}>
            <span>{stringPath}</span>
            <textarea type="text" onChange={this.onInputChange.bind(this, path)} defaultValue={obj}></textarea>
          </label>
        );
      }
    } else if(_.isObject(obj)) {
      return _.map(obj, (v, k) => {
        return this._renderInputs(v, [...path, k]);
      });
    }
  }
  _isValidPath(path) {
    if(this.props.paths === '*') {
      return true;
    }
    return !!_.find(this.props.paths, (validPath) => {
      if(_.isRegExp(validPath)) {
        return validPath.test(path);
      } else {
        return path === validPath;
      }
    });
  }
  _onImprovePressed() {
    this.setState({
      isTranslating: true
    });
    this.changeValues = {};
  }
  onInputChange(path, e) {
    if(path.length > 0) {
      _.set(this.changeValues, path, e.currentTarget.value);
    } else {
      this.changeValues = e.currentTarget.value;
    }
  }
  _onSavePress() {
    console.log(this.changeValues);
    this.props.onTranslateDone(this.changeValues);
    this.setState({
      isTranslating: false
    });
  }
  _onCancelPress() {
    this.setState({
      isTranslating: false
    });
  }
}

ImproveTranslation.propTypes = {
  dispatch: PropTypes.func
};

ImproveTranslation.defaultProps = {
  dispatch: () => {},
  onTranslateDone: () => {},
  paths: '*',
  text: ''
};

export default connect((state) => ({
  
}))(ImproveTranslation);
