const map = {
	name: 'calendar',
	key: 'calendar',
	type: 'object',
	child: [{
		name: '*',
		key: '*',
		type: 'object',
		child: [{
			name: 'duration',
			key: 'd'
		},{
			name: 'mainImage',
			key: 'i'
		},{
			name: 'slug',
			key: 's'
		},{
			name: 'time',
			key: 't'
		},{
			name: 'title',
			key: 'n'
		},{
			name: 'summary',
			key: 'r',
			type: 'object'
		}]
	}]
};

export default map;