/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { fetchData } from "../../actions";
import SideMenuList from '../../components/side-menu-list';
import { getPostLink } from '../../utils/link-maker';
import I18n from '../../i18n';

const LAST_RECIPES_LIST_KEY = 'last-recipes-list';

class LastRecipesList extends Component {
  componentDidMount() {
    if (this.props.fetchDataOnMount) {
      this._fetchData();
    }
  }
  componentDidUpdate(nextProps) {
    if(this.props.initialized && !nextProps.initialized) {
      this._fetchData();
    }
  }
  render() {
    return <SideMenuList title={I18n.t('sideBar.last')} items={this._getItems()}/>
  }
  _fetchData() {
    this.props.dispatch(fetchData(['search', {
      searchParam: "title:* orderBy date:desc",
      next: {
        from: 0,
        limit: 10
      } 
    }], LAST_RECIPES_LIST_KEY, true));  
  }
  _getItems() {
    return this.props.posts.map(post => ({
      name: post.title,
      link: getPostLink(post)
    }));
  }
}

LastRecipesList.propTypes = {
  dispatch: PropTypes.func,
};

LastRecipesList.defaultProps = {
  dispatch: () => {},
};

export default connect((state, props) => {
  let postsContainer = state.posts.lists[LAST_RECIPES_LIST_KEY] || {
    'error': false,
    'loading': true,
    'posts': [],
  };
  let fetchDataOnMount = postsContainer.posts.length === 0 || props.fetchDataOnMount
  return {
    initialized: state.app.initialized,
    isError: postsContainer.error,
    isFetching: postsContainer.loading,
    posts: postsContainer.posts || [],
    fetchDataOnMount
  };
})(LastRecipesList);
