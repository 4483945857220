/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import NotFound from "../../components/not-found";
import I18n from "../../i18n";
import _ from "lodash";
import { Helmet } from "react-helmet";

class Posts extends PureComponent {
  static defaultProps = {
    dispatch: () => {},
    postsContainer: {},
    isFetching: false,
    fetchDataOnMount: true,
  };
  constructor(props: any) {
    super(props);
  }
  render() {
    if (typeof document !== "undefined") {
      document.body.classList.add("search-no-results");
    }
    return (
        <main id="main" className="site-main" role="main">
          {this._renderMeta()}
          <NotFound error={true} />
        </main>
    );
  }
  _renderMeta() {
    let title = `Vegamecum | ${I18n.t("about.subtitle")}`;
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  }
}

export default connect(function (state, props) {
  return {};
})(Posts);
