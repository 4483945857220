import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Root from "./containers/root";
import { unregister } from "./registerServiceWorker";
import { loadableReady } from '@loadable/component'

if (process.env.NODE_ENV !== "development") {
  function noop() {}
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

loadableReady(() => {
  ReactDOM.hydrate(<Root />, document.getElementById("page"));
})

unregister();
