/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from "react";
import _ from "lodash";
import I18n from "../../i18n";
import clsx from "clsx";

class EditSummary extends PureComponent {
  constructor(props: any) {
    super(props);
    this.summary = _.cloneDeep(this.props.post.summary);
    this.state = {
      updateHook: 0,
    };
  }
  render() {
    return (
      <table>
        <tbody>
          <tr>
            <td>
              {I18n.t("detail.sections.preparation.summary.difficultRate")}
            </td>
            <td>
              {I18n.t("detail.sections.preparation.summary.preparationTime")}
            </td>
            <td>
              {I18n.t("detail.sections.preparation.summary.totalTime")}
            </td>
            <td>{I18n.t("detail.sections.preparation.summary.portion")}</td>
          </tr>
          <tr>
            <td>
              <select
                onChange={this._onChange.bind(this, ["difficult"])}
                defaultValue={this.summary.difficult}
              >
                <option value="Muy fácil">Muy fácil</option>
                <option value="Fácil">Fácil</option>
                <option value="Media">Media</option>
                <option value="Difícil">Difícil</option>
              </select>
            </td>
            <td>
              <input
                onChange={this._onChange.bind(this, ["time_minutes_cooking"])}
                type="time"
                defaultValue={this._formatTime(
                  this.summary.time_minutes_cooking
                )}
                placeholder="Duración"
              />
            </td>
            <td>
              <input
                onChange={this._onChange.bind(this, ["time_minutes"])}
                type="time"
                defaultValue={this._formatTime(
                  this.summary.time_minutes
                )}
                placeholder="Duración total"
              />
              <input
                onChange={this._onChange.bind(this, ["time_annotation"])}
                type="text"
                defaultValue={this.summary.time_annotation}
                placeholder="Esperas"
              />
            </td>
            <td>
              <input
                onChange={this._onChange.bind(this, ["units", "value"])}
                type="number"
                defaultValue={this.summary.units.value}
                placeholder="Raciones"
              />
              <select
                onChange={this._onUnitTypeChange}
                defaultValue={this.summary.units.key}
              >
                {_.map(this.props.allUnits, ({key, name}, keyIndex) => (
                  <option key={keyIndex} value={keyIndex}>{key || name}</option>
                ))}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  _onUnitTypeChange = ({currentTarget}) => {
    let value = this.props.allUnits[currentTarget.value];
    this._onChange(["units", "key"], {
      currentTarget: {
        value: currentTarget.value
      }
    });
    this._onChange(["units", "type"], {
      currentTarget: {
        value
      }
    })
  }
  _onChange(path, { currentTarget }) {
    let {value, type} = currentTarget;
    if(type === 'time') {
      let [hours, minutes] = value.split(':');
      value = parseInt(hours) * 60 + parseInt(minutes);
    } else if(type === 'number') {
      value = parseInt(value)
    }
    
    _.set(this.summary, path, value);
    this._updateSummary(this.summary);
  }
  _updateSummary(summary, forceRender = false) {
    this.summary = summary;
    this.props.onChange(summary);
    if (forceRender) {
      this.setState({
        updateHook: this.state.updateHook + 1,
      });
    }
  }
  _pad(num) {
    return ("0" + num).slice(-2);
  }
  _formatTime(minutes) {
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${this._pad(hours)}:${this._pad(minutes)}`;
  }
}

EditSummary.propTypes = {};

EditSummary.defaultProps = {
  onChange: () => {},
};

export default EditSummary;
