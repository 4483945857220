/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { fetchData } from "../../actions";
import PostsNavigation from '../../components/posts-navigation';
import { getPostLink } from '../../utils/link-maker';

const PREVIOUS_RECIPES_LIST_KEY = 'previous-recipes-list-';
const NEXT_RECIPES_LIST_KEY = 'next-recipes-list-';

class NextPreviousPostNavigation extends Component {
  componentDidMount() {
    this.props.dispatch(fetchData(['search', {
        searchParam: `priorityKey:>${this.props.priorityKey} orderBy date:desc`,
        next: {
          from: 0,
          limit: 1
        } 
      }], PREVIOUS_RECIPES_LIST_KEY + this.props.slug, true));  
    
      this.props.dispatch(fetchData(['search', {
        searchParam: `priorityKey:<${this.props.priorityKey} orderBy date:asc`,
        next: {
          from: 0,
          limit: 1
        } 
      }], NEXT_RECIPES_LIST_KEY + this.props.slug, true));  
  }
  render() {
    return <PostsNavigation next={this._getNextItem()} previous={this._getPreviousItem()}/>
  }
  _getNextItem() {
    if(this.props.nextPosts && this.props.nextPosts.length > 0) {
      let post = this.props.nextPosts[0];
      return {
        name: post.title,
        link: getPostLink(post),
      }
    }
  }
  _getPreviousItem() {
    if(this.props.previousPosts && this.props.previousPosts.length > 0) {
      let post = this.props.previousPosts[0];
      return {
        name: post.title,
        link: getPostLink(post),
      }
    }
  }
}

NextPreviousPostNavigation.propTypes = {
  dispatch: PropTypes.func,
};

NextPreviousPostNavigation.defaultProps = {
  dispatch: () => {},
};

export default connect((state, props) => {
  let previousPostsContainer = state.posts.lists[PREVIOUS_RECIPES_LIST_KEY + props.slug] || {
    'error': false,
    'loading': true,
    'posts': [],
  };
  let nextPostsContainer = state.posts.lists[NEXT_RECIPES_LIST_KEY + props.slug] || {
    'error': false,
    'loading': true,
    'posts': [],
  };
  return {
    //isError: postsContainer.error,
    //isFetching: postsContainer.loading,
    previousPosts: previousPostsContainer.posts || [],
    nextPosts: nextPostsContainer.posts || []
  };
})(NextPreviousPostNavigation);
