/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import AdSense from "react-adsense";
import { Helmet } from "react-helmet";
import { getPostLink } from "../../utils/link-maker";
import { getJSONLD } from "../../utils/json-ld";
import SharePost from "../share-post";
import I18n from "../../i18n";
import PostDetailIntroduction from "../../containers/post-detail/introduction";
import PostDetailPreparation from "../../containers/post-detail/preparation";
import PostDetailNotes from "../../containers/post-detail/notes";
import PostDetailIngredients from "../../containers/post-detail/ingredients";
import PostDetailSummary from "../../containers/post-detail/summary";
import PostDetailFooter from "../../containers/post-detail/footer";
import PostDetailTitle from "../../containers/post-detail/title";
import NextPreviousPostsNavigation from "../../containers/next-previous-posts-navigation";
import RelatedPosts from "../../containers/related-posts";
import Image from "../../containers/post-image";
import TranslateButton from "../../containers/improve-translation";

class PostArticle extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    let mainImage = this._getMainImage();
    return (
      <main id="main" className="site-main" role="main">
        {this._renderMeta()}
        <aside
          key={`list-before-ad-${this.props.slug}`}
          className="ads ads_before"
        >
          <AdSense.Google
            style={{ display: "block" }}
            responsive="true"
            client="ca-pub-3496513159625922"
            slot="9806512890"
            format="auto"
          />
        </aside>
        <article
          key={this.props.slug}
          className="post type-post status-publish format-standard hentry category-entrantes tag-crudivegano tag-dip tag-entrante tag-fiestas tag-navidad tag-pate tag-pate-vegetal tag-pimienta tag-raw tag-sin-gluten tag-tomate-seco tag-untable tag-vegano tag-vegetariano"
        >
          <PostDetailTitle post={this.props} />
          <div className="entry-content">
            {this._renderMainImage(mainImage)}
            <PostDetailIntroduction post={this.props} />
            <PostDetailSummary post={this.props} />
            <PostDetailIngredients post={this.props} />
            <PostDetailPreparation post={this.props} />
            {this._renderSecondaryImages()}
            <PostDetailNotes post={this.props} />
            <p>
              {I18n.t("detail.engagement.text")}{" "}
              <img
                className="wp-smiley emoji"
                src="https://s1.wp.com/wp-content/mu-plugins/wpcom-smileys/simple-smile.svg"
                alt=":)"
                width="16"
                height="16"
                scale="0"
              />
            </p>
            <p>
              <span style={{ color: "#cc99ff" }}>
                <em>{I18n.t("detail.engagement.share")}</em>
              </span>
            </p>
            <SharePost
              {...this.props}
              link={getPostLink(this.props, null, true)}
              mainImage={mainImage.path}
            />
            <RelatedPosts {...this.props} />
          </div>
          <PostDetailFooter post={this.props} />
        </article>
        <aside
          key={`list-after-ad-${this.props.slug}`}
          className="ads ads_after"
        >
          <AdSense.Google
            style={{ display: "block" }}
            responsive="true"
            client="ca-pub-3496513159625922"
            slot="3480777693"
            format="auto"
          />
        </aside>
        <NextPreviousPostsNavigation {...this.props} />
      </main>
    );
  }
  _renderMeta() {
    let description = "";
    let paragraphs =
      this.props.introduction && this.props.introduction.split("</p");
    let image =
      "https://cdn.vegamecum.com/image/width=270/vegamecum.com%2Flogos%2Fmsapp.png";
    if (this.props.figures) {
      let figure = this.props.figures.find((figure) => {
        return figure.main;
      });
      if (figure && figure.path) {
        image = figure.path;
      }
    }

    if (paragraphs && paragraphs.length >= 1) {
      description = paragraphs[0].replace(/<[^>]*>/g, "");
    }
    let title = `${this.props.title} | Vegamecum`;
    let link = getPostLink(this.props, undefined, true);
    let JSONLD = getJSONLD(this.props, {
      title,
      link,
      description,
      language: I18n.getSelectedLanguage(),
    });
    return (
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={link} />
        <link
          rel="alternate"
          hreflang="x-default"
          href={getPostLink(this.props, "x-default", true)}
        />
        <link
          rel="alternate"
          hreflang="es"
          href={getPostLink(this.props, "es", true)}
        />
        <link
          rel="alternate"
          hreflang="en"
          href={getPostLink(this.props, "en", true)}
        />
        <meta name="description" content={description} />
        <meta name="twitter:card" content={"summary_large_image"} />
        <meta name="twitter:site" content={"@vegamecum"} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta
          property="og:locale"
          content={{ es: "es_ES", en: "en_EN" }[I18n.getSelectedLanguage()]}
        />
        <meta property="og:type" content={"article"} />
        <meta property="og:image" content={image} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={link} />
        <meta property="og:site_name" content={"Vegamecum"} />
        <meta
          property="article:publisher"
          content={"https://www.facebook.com/Vegamecum-1705334993044654"}
        />
        <meta
          property="article:author"
          content={"https://www.facebook.com/Vegamecum-1705334993044654"}
        />
        <meta property="fb:app_id" content={"1676595619311398"} />
        <meta property="article:published_time" content={this.props.date} />
        <meta property="article:modified_time" content={this.props.modified} />
        <meta property="og:updated_time" content={this.props.modified} />
        <script type="application/ld+json">{JSON.stringify(JSONLD)}</script>
      </Helmet>
    );
  }
  _renderMainImage(figure) {
    if (figure) {
      return (
        <Image
          post={this.props}
          mainImage={true}
          index={figure.index}
          path={figure.path}
          caption={figure.caption}
        />
      );
    } else {
      let emptyIndex = this.props.figures ? this.props.figures.length : 0;
      return <Image post={this.props} mainImage={true} index={emptyIndex} />;
    }
  }
  _renderSecondaryImages() {
    if (!this.props.figures) {
      return;
    }
    let figures = [];
    this.props.figures.forEach((figure, index) => {
      if (!figure.main) {
        figures.push(
          <Image
            key={`key-${index}`}
            index={index}
            post={this.props}
            path={figure.path}
            caption={figure.caption}
          />
        );
      }
    });
    let index = this.props.figures.length;
    figures.push(
      <Image key={`key-${index}`} index={index} post={this.props} />
    );
    return figures;
  }
  _getMainImage() {
    let figure;
    if (this.props.figures) {
      let figureIndex = this.props.figures.findIndex((figure) => {
        return figure.main;
      });
      figureIndex = figureIndex !== -1 ? figureIndex : 0;
      figure = {
        ...this.props.figures[figureIndex],
        index: figureIndex,
      };
    }
    return figure
      ? figure
      : {
          path: this.props.mainImage || "",
          caption: "",
        };
  }
}

PostArticle.propTypes = {};

PostArticle.defaultProps = {};

export default PostArticle;
