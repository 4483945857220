/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getLinkForPathAndSearch } from '../../utils/link-maker';

class Footer extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return <footer id="colophon" className="site-footer" role="contentinfo">
			<div className="site-info">
        <span className="site-title"><a href="/es">Español</a></span>
        <span><a href="/en">English</a></span>
			</div>
		</footer>
  }
}

Footer.propTypes = {
  
};

Footer.defaultProps = {
};

export default Footer;