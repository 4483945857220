/* @flow */
/*eslint-disable prefer-const */

import Filter from "../../containers/filter";
import React, { Component, useRef } from "react";
import I18n from "../../i18n";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fade, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import ListSubheader from "@material-ui/core/ListSubheader";
import { getLinkForPathAndSearch } from "../../utils/link-maker";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  inputInputSmall: {
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  searchSmall: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  listHeader: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.primary.main,
  },
}));

function SearchBox(props) {
  const classes = useStyles();
  const history = useHistory();
  const inputRef = useRef(null);
  const renderGroup = (params) => [
    <ListSubheader
      className={classes.listHeader}
      key={params.key}
      component="div"
    >
      {params.group === "recipe"
        ? I18n.t("searcher.suggested")
        : I18n.t("searcher.contains")}
    </ListSubheader>,
    params.children,
  ];
  const [open, setOpen] = React.useState(false);

  return (
    <Autocomplete
      freeSolo
      clearOnBlur
      blurOnSelect
      clearOnEscape
      open={open}
      onInputChange={(e, value) => {
        props.setSearchingText(value);
      }}
      //inputValue={props.searchingText}
      onChange={(e, option) => {
        if (option) {
          if (option.slug) {
            let link = getLinkForPathAndSearch({
              path: `/posts/${option.slug}`,
            });
            history.push(link);
          } else {
            history.push(
              `/${I18n.getSelectedLanguage()}?s=${props.searchingText}`
            );
          }
        }
        if (inputRef && inputRef.current) {
          inputRef.current.blur();
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.text === value.text}
      getOptionLabel={(option) => option.text || option}
      options={[
        ...props.searchSuggestionsPosts,
        ...props.searchSuggestionsIngredients,
      ]}
      groupBy={(option) => option.type}
      renderGroup={renderGroup}
      loading={props.isFetching}
      renderInput={(params) => (
        <div
          className={`${classes.search} ${
            props.fullWidth ? classes.searchBig : classes.searchSmall
          }`}
          ref={params.InputProps.ref}
        >
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            {...params.inputProps}
            fullWidth={props.fullWidth}
            placeholder={I18n.t("sideBar.search")}
            classes={{
              root: classes.inputRoot,
              input: `${classes.inputInput} ${
                props.fullWidth
                  ? classes.inputInputBig
                  : classes.inputInputSmall
              }`,
            }}
            inputProps={{ "aria-label": "search", ref: inputRef }}
            endAdornment={
              <React.Fragment>
                {props.isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            }
          />
        </div>
      )}
    />
  );
}

// class SeachBox extends Component {
//   constructor(props: any) {
//     super(props);
//   }
//   render() {
//     return (
//       <form
//         role="search"
//         method="get"
//         className="search-form"
//         action={`/${I18n.getSelectedLanguage()}`}
//         onSubmit={this._submitForm.bind(this)}
//       >
//         <label>
//           <span className="screen-reader-text">Buscar por:</span>
//           <input
//             ref="text-input"
//             type="search"
//             className="search-field"
//             defaultValue={this.props.defaultValue}
//             placeholder={I18n.t("sideBar.search")}
//             name="s"
//           />
//         </label>
//         <button type="submit" className="search-submit">
//           <span className="screen-reader-text">Buscar</span>
//         </button>
//       </form>
//     );
//   }
//   _submitForm(e) {
//     e.preventDefault();
//     this.props.history.push(`/${I18n.getSelectedLanguage()}?s=${this.refs['text-input'].value}`);
//   }
// }

SearchBox.propTypes = {};

SearchBox.defaultProps = {
  defaultValue: "",
  fullWidth: false,
};

export default SearchBox;

//export default withRouter(SeachBox);
