/* @flow */
/*eslint-disable prefer-const */

import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { updateTranslation } from "../../actions";
import {
  updateIntroduction,
  updateIntroductionBlogOnly,
} from "../../actions/edit";
import EditButton from "../../components/edit-button";
import TranslateButton from "../improve-translation";
const EditIntroduction = React.lazy(() =>
  import("../../components/edit-introduction")
);

class PostDetailIntroduction extends Component {
  constructor(props: any) {
    super(props);
    this.introductionChanged = null;
    this.introductionBlogOnlyChanged = null;
  }
  render() {
    if (this.props.summary) {
      return this._renderSummary();
    }
    return (
      <React.Fragment>
        <EditButton
          tagName="div"
          active={!this.props.enableImproveTranslations && this.props.isAdmin}
          editComponent={() => (
            <Suspense fallback={<div>Loading...</div>}>
              <EditIntroduction
                introduction={this.props.introduction}
                onChange={(nextIntroduction) => {
                  this.introductionChanged = nextIntroduction;
                }}
              />
            </Suspense>
          )}
          onSavePress={() => {
            if (this.introductionChanged) {
              this.props.dispatch(
                updateIntroduction(
                  {
                    path: `/posts/${this.props.post.slug}`,
                    ...this.props.post,
                  },
                  this.introductionChanged
                )
              );
            }
          }}
          onDeletePress={() => {}}
          readComponent={renderHTML(this.props.introduction)}
          style={{ paddingBottom: "10px" }}
        ></EditButton>
        <TranslateButton
          active={this.props.enableImproveTranslations}
          text={this.props.introduction}
          onTranslateDone={this._onTranslateDone.bind(this)}
        />
        <EditButton
          tagName="div"
          active={!this.props.enableImproveTranslations && this.props.isAdmin}
          editComponent={
            <Suspense fallback={<div>Loading...</div>}>
              <EditIntroduction
                introduction={this.props.introductionBlogOnly || ""}
                onChange={(nextIntroductionBlogOnly) => {
                  this.introductionBlogOnlyChanged = nextIntroductionBlogOnly;
                }}
              />
            </Suspense>
          }
          onSavePress={() => {
            if (this.introductionBlogOnlyChanged) {
              this.props.dispatch(
                updateIntroductionBlogOnly(
                  {
                    path: `/posts/${this.props.post.slug}`,
                    ...this.props.post,
                  },
                  this.introductionBlogOnlyChanged
                )
              );
            }
          }}
          readComponent={
            this.props.introductionBlogOnly
              ? renderHTML(this.props.introductionBlogOnly)
              : ""
          }
          style={{ paddingBottom: "10px" }}
        ></EditButton>
        <TranslateButton
          active={this.props.enableImproveTranslations}
          text={this.props.introductionBlogOnly}
          onTranslateDone={this._onImprovedTranslateDone.bind(this)}
        />
      </React.Fragment>
    );
  }
  _onTranslateDone(text) {
    this.props.dispatch(
      updateTranslation({
        language: "en",
        slug: this.props.post.slug,
        key: "posts/" + this.props.post.slug + "/introduction",
        text,
        tsPath: "posts/" + this.props.post.slug + "/modified",
      })
    );
  }
  _onImprovedTranslateDone(text) {
    this.props.dispatch(
      updateTranslation({
        language: "en",
        slug: this.props.post.slug,
        key: "posts/" + this.props.post.slug + "/introductionBlogOnly",
        text,
        tsPath: "posts/" + this.props.post.slug + "/modified",
      })
    );
  }
  _renderSummary() {
    try {
      let rendered = renderHTML(this.props.introduction);
      return rendered.length > 0 ? rendered[0] : rendered;
    } catch (e) {}
    return null;
  }
}

PostDetailIntroduction.propTypes = {
  dispatch: PropTypes.func,
};

PostDetailIntroduction.defaultProps = {
  dispatch: () => {},
  isAdmin: false,
};

export default connect((state, props) => {
  return {
    introduction: props.post.introduction || "",
    introductionBlogOnly: props.post.introductionBlogOnly || "",
    isAdmin: state.user.admin,
    enableImproveTranslations: props.post.__vo && state.user.admin, //state.settings.enableImproveTranslations,
  };
})(PostDetailIntroduction);
