const map = {
	name: 'alarms',
	key: 'alarms',
	type: 'object',
	child: [{
		name: '*',
		key: '*',
		type: 'object',
		child: [{
			name: 'alarmDate',
			key: 'a'
		},{
			name: 'alarmEndDate',
			key: 'e'
		},{
			name: 'image',
			key: 'i'
		},{
			name: 'notificationText',
			key: 't'
		},{
			name: 'slug',
			key: 's'
		},{
			name: 'title',
			key: 'n'
		}]
	}]
};

export default map;