/* @flow */

import * as types from "../actions";
import produce from "immer";
import cart from "./cart";

const data = (state = {
	list: {}
}, action) => produce(state, draft => {
    let key;
    switch (action.type) {
    	case types.CLEAR_CART:
        case types.LOADED_PLANIFIED:
        case types.ADD_ELEMENT_TO_CART:
        case types.REMOVE_ELEMENT_FROM_CART:
        case types.TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY:
            key = action.cartId || 'default';
            draft.list[key] = cart(state.list[key],action);
            return;
    }
});

export default data;
