/* @flow */

import * as types from "../actions/extras";
import produce from "immer";

const data = (state: Object = {
	list: {},
    loaded: false,
}, action) => produce(state, draft => {
    switch (action.type) {
    	case types.LOADED_EXTRAS:
            Object.keys(action.data).forEach(key => {
                draft[key] = action.data[key];
            });
            draft.loaded = true;
            return;
    }
});

export default data;
