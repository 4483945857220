/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from 'react';
import _ from 'lodash';
import clsx from 'clsx';

class EditPreparationStep extends PureComponent {
  constructor(props: any) {
    super(props);
    this.step = _.cloneDeep(this.props.step);
    this.state = {
      isEditing: false,
      updateHook: 0
    };
  }
  render() {
    return (
      <React.Fragment>
        <input onChange={this._onChange.bind(this, ['title'])} type="text" defaultValue={this.step.title} placeholder="Introduce el título del paso"/>
        {this._renderText()}
        <input onChange={this._onChange.bind(this, ['timer'])} type="number" defaultValue={this.step.timer} placeholder="Tiempo de espera"/>
        {this._renderNotes()}
      </React.Fragment>
    );
  }
  _renderText() {
    if(this.step.textRegex) {
      return <textarea onChange={this._onChange.bind(this, ['textRegex'])} rows="4" cols="50" defaultValue={this.step.textRegex} placeholder="Introduce el texto del paso"></textarea>
    } else {
      return <textarea onChange={this._onChange.bind(this, ['text'])} rows="4" cols="50" defaultValue={this.step.text} placeholder="Introduce el texto del paso"></textarea>
    }
  }
  _renderNotes() {
    let notes = [];
    if(this.step.notes) {
      notes = this.step.notes.map((note, index) => {
        return (
          <div key={`${index} - ${note.text}`}>
            <textarea onChange={this._onChange.bind(this, ['notes', index, 'text'])} rows="4" cols="50" defaultValue={note.text} placeholder="Introduce el texto de la nota"></textarea>
            <button onClick={this._onRemoveNotePress.bind(this, index)} type="button">ELIMINAR NOTA</button>
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        {notes}
        <button onClick={this._onAddNotePress.bind(this)} type="button">AÑADIR NOTA</button>
      </React.Fragment>
    )

  }
  _onAddNotePress() {
    let step = _.cloneDeep(this.step);
    step.notes = step.notes || [];
    step.notes.push({
      text: ''
    });
    this._updateStep(step, true);
  }
  _onRemoveNotePress(index) {
    let step = _.cloneDeep(this.step);
    step.notes = step.notes || [];
    step.notes.splice(index, 1);
    this._updateStep(step, true);
  }
  _onChange(path, {currentTarget}) {
    let step = _.cloneDeep(this.step);
    step = _.set(step, path, currentTarget.value);
    this._updateStep(step);
  }
  _updateStep(step, forceRender = false) {
    this.step = step;
    this.props.onChange(step);
    if(forceRender) {
      this.setState({
        updateHook: (this.state.updateHook + 1)
      });
    }
  }
}

EditPreparationStep.propTypes = {

};

EditPreparationStep.defaultProps = {
  onChange: () => {},
};

export default EditPreparationStep;