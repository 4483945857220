/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import Consent from "../../components/consent";
import LoadingPortal from "../../containers/loading-portal";

class GlobalElements extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      navigator: null,
    };
  }
  componentDidMount() {
    this.setState({
      navigator: this.props.getNavigator(),
    });
  }
  render() {
    return (
      <React.Fragment>
        <LoadingPortal />
        {/* <Consent/> */}
      </React.Fragment>
    );
  }
}

GlobalElements.propTypes = {};

GlobalElements.defaultProps = {
  onRowPressed: () => {},
  onCancelPressed: () => {},
};

export default GlobalElements;
