import React from 'react'
import XDate from 'xdate'
import es from './locales/es'
import en from './locales/en'
/*
import ca from './locales/ca';
import it from './locales/it';
import pt from './locales/pt';
import fr from './locales/fr';
*/
import _ from 'lodash'
import {Text, I18n, getLanguages} from '../platform'

I18n.fallbacks = true

I18n.translations = {
  es,
  en,
  //ca,
  //it,
  //pt,
  //fr
}

const RegexIngredientReplacer = new RegExp('{(\\w+)}', 'g')
let deviceLanguage
let selectedLanguage = 'es'
let selectedAppLanguage = null;

const Translator = {
  t: (key, options) => {
    return I18n.t(key, options)
  },
  t2: (key, options = {}) => {
    let counter = 0
    options.linebreak = () => {
      counter++
      return <Text key={'linebreak' + counter}>{'\n'}</Text>
    }
    let t = I18n.t(key, options)
    return _.map(t.split(RegexIngredientReplacer), (v, i) => {
      return _.result(options, v) || <Text key={i}>{v}</Text>
    })
  },
  initialize: () => {
    return new Promise((resolve, reject) => {
      selectedLanguage = selectedAppLanguage || 'es';
      I18n.locale = selectedLanguage
      Translator.setXDate()
      resolve(selectedLanguage)
      /*
      getLanguages().then(languages => {
        languages.forEach((language) => {
          alert(language);
        });
      });
*/
      /*
			getLanguages().then(languages => {
			  console.log(languages);
			  let selected = null;
			  languages.forEach((language) => {
			  	if(!selected) {
			  		let splited = language.split('-');
			  		let lang = splited[0];
			  		let country = splited[1];
			  		if(_.keys(I18n.translations).indexOf(lang) === 0) {
			  			selected = {
			  				voice: language,
	        				language: lang,
	        				country: country || 'SP'
			  			};
			  		}
			  	}
			  });
			  console.log(selected);
			  selectedLanguage = selected ? selected.language : 'es';
			  I18n.locale = selectedLanguage;
			  Translator.setXDate();
			  resolve(selectedLanguage);
			}, () => {
			  selectedLanguage = 'es';
			  I18n.locale = selectedLanguage;
			  Translator.setXDate();
			  resolve(selectedLanguage);
			});
      */
    })
  },
  getLanguages: () => {
    return getLanguages()
  },
  isLanguageAvailable: language => {
    return _.keys(I18n.translations).indexOf(language) !== -1
  },
  setAppLanguage: (language) => {
		if(Translator.isLanguageAvailable(language)) {
			selectedAppLanguage = null;
			Translator.setLanguage(language);
			selectedAppLanguage = language;
			return true;
		} 
		return false;
	},
  setLanguage: language => {
    if(selectedAppLanguage) {
			console.log('language fixed');
			return;
		}
    if (Translator.isLanguageAvailable(language)) {
      I18n.locale = selectedLanguage = language
      Translator.setXDate()
    }
  },
  setXDate: () => {
    XDate.locales['default'] = {
      monthNames: I18n.t('calendar.months.names'),
      monthNamesShort: I18n.t('calendar.months.shorts'),
      dayNames: I18n.t('calendar.days.names'),
      dayNamesShort: I18n.t('calendar.days.shorts'),
    }

    XDate.defaultLocale = 'default'
  },
  getVoiceConfiguration: () => {
    switch (selectedLanguage) {
      case 'es':
        return {
          voice: 'es-ES',
          language: 'es',
          country: 'ES',
        }
      case 'en':
        return {
          voice: 'en-US',
          language: 'en',
          country: 'US',
        }
      /*
			case 'ca':
				return {
					voice: 'ca-ES',
		        	language: 'ca',
		        	country: 'ES'
				};
			case 'it':
				return {
					voice: 'it-IT',
		        	language: 'it',
		        	country: 'IT'
				};
			case 'pt':
				return {
					voice: 'pt-PT',
		        	language: 'pt',
		        	country: 'PT'
				};
			case 'fr':
				return {
					voice: 'fr-FR',
		        	language: 'fr',
		        	country: 'FR'
				};
      */
    }
  },
  getSelectedLanguage: () => {
    return selectedLanguage
  },
}

export default Translator
