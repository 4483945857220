/* @flow */
/*eslint-disable prefer-const */

import React from "react";
import Component from "../super";
import HomePage from "../../containers/home";

class HomeScreen extends Component {
  getComponent() {
    return HomePage;
  }
}

HomeScreen.propTypes = {};

HomeScreen.defaultProps = {};

export default HomeScreen;
