/* @flow */

import * as types from "../actions";
import produce from "immer";

const data = (state = [], action) => produce(state, draft => {
	switch (action.type) {
	    case types.RECEIVE_NUTRIENT_LIST:
            return action.data;
    }
});

export default data;