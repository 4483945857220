import { DOMParser, XMLSerializer } from 'xmldom';
import _ from 'lodash';
import { getIngredientInfo } from './ingredient-parser';
import { getStepInfo } from './preparation-step-parser';
import * as values from "../constants/values";

const Serializer = new XMLSerializer().serializeToString;

const parseMainImage = (node, digestion) => {
    let hasEnd = (digestion.mainImage && node.nodeName === 'p');
    let text = Serializer(node);
    if (!hasEnd) {
        if (node.nodeName === 'div' || node.nodeName === 'p' || node.nodeName === 'figure') {
            digestion.mainImage = node.getElementsByTagName('img')[0].getAttribute('src');
            digestion.mainImage = digestion.mainImage.split('?')[0]+'?w=640';
            if(node.nodeName === 'figure') {
                digestion.figures.push({
                    main: true,
                    path: digestion.mainImage,
                    caption: node.getElementsByTagName('figcaption')[0].textContent
                });
            }
        }
    }
    return {
        postDigestion: digestion,
        hasEnd: hasEnd
    };
}

const parseIntroduction = (node, digestion) => {
    let hasEnd = (node.nodeName === 'table');
    if (!hasEnd) {
        if (node.nodeName === 'p') {
            let text = Serializer(node);
            if(text.indexOf('blog-only') === -1) {
                digestion.introduction += text;
            } else {
                digestion.introductionBlogOnly = text;
            }
        }
    }
    return {
        postDigestion: digestion,
        hasEnd: hasEnd
    };
}

export const parseSummaryUnits = (text, units) => {
    let type,
        key,
        numberFound,
        regex,
        found,
        unitRegex;
    text = text.replace(/\u00a0/gi, ' ');
    _.each(units, (quantity, k) => {
        unitRegex = quantity.regex && quantity.regex.replace('$n',values.$N_REGEX);
        regex = new RegExp(unitRegex,'i');
        found = regex.exec(text);
        if (found && found.length > 0) {
            type = quantity;
            key = k;
            numberFound = parseInt(found[1],10)
        }
    })
    return {
        'value': numberFound || 0,
        'type': type,
        'text': text,
        'key': key || null
    }
}

const parseSummary = (node, digestion, firebaseData) => {
    let hasEnd = (node.textContent.indexOf('Ingredientes') !== -1),
        infoNode, time;
    if (!hasEnd) {
        if (node.nodeName === 'table') {
            infoNode = node.getElementsByTagName('tr')[1];
            infoNode = infoNode.getElementsByTagName('td');
            time = infoNode[1].textContent;

            let time_minutes = getMinutesFromText(time);

            time = time.replace(/minutos/, 'm').replace(/horas/, 'h').replace(/hora/, 'h').replace(/y/, '')
            let i = time.indexOf('(');
            if (i !== -1) {
                time = time.substring(0, time.indexOf('(')).trim();
            }

            digestion.summary = {
                'difficult': infoNode[0].textContent,
                'time': time,
                'time_minutes': time_minutes,
                'time_minutes_cooking': time_minutes,
                'normal_time': infoNode[1].textContent,
                'units': parseSummaryUnits(infoNode[2].textContent.trim(), firebaseData.units)
            }
        } else if (node.textContent.indexOf('*') !== -1) {
            let minutes = getMinutesFromText(node.textContent);
            digestion.summary.time_minutes += minutes;
            digestion.summary.time_annotation = node.textContent;
        }
    }
    return {
        postDigestion: digestion,
        hasEnd: hasEnd
    };
}

const getMinutesFromText = (time) => {
    time = time.replace(/\s+/g, " ");
    let time_minutes = 0;
    let minutesRegex = new RegExp('(\\d+) minuto(?:s)?','ig');
    let found = minutesRegex.exec(time);
    while(found) {
        time_minutes += parseInt(found[1],10);
        found = minutesRegex.exec(time);
    }

    let hoursRegex = new RegExp('(\\d+) hora(?:s)?','ig');
    found = hoursRegex.exec(time);
    while(found) {
        time_minutes += (parseInt(found[1],10) * 60);
        found = hoursRegex.exec(time);
    }
    return time_minutes;
}

const parseIngredients = (node, digestion, firebaseData) => {
    let hasEnd = (node.textContent.indexOf('Preparación') !== -1),
        infoNode, text, info, nbspRegex = new RegExp(String.fromCharCode(160), "g");

    if (!hasEnd) {
        if (node.nodeName === 'ul') {
            infoNode = node.getElementsByTagName('li');
            digestion.ingredients[digestion.ingredients.length - 1].list = (_.compact(_.map(infoNode, (li) => {
                text = Serializer(li).replace(nbspRegex, ' ').toLowerCase();
                if(text.indexOf('not-appear') !== -1 ) {
                  return null;
                }else if (text.indexOf('<a') === 0) {
                    info = {
                        link: text
                    }
                } else {
                    info = getIngredientInfo(text, digestion, firebaseData);
                }

                return {
                    selected: false,
                    text: text,
                    ...info
                }
            })));
        } else if (node.nodeName === 'p') {
            let regex = (new RegExp('Ingredientes[^:]', 'g'));
            let match = node.textContent.match(regex);
            let name;
            if (match && match.length > 0) {
                name = node.textContent.substring(13, node.textContent.length - 1);
                name = name.charAt(0).toUpperCase() + name.slice(1)
            }
            digestion.ingredients.push({
                'name': name || 'default',
                'isolated': !!Serializer(node).match(/receta-\d+/gi),
                'list': []
            })
        }
    }
    return {
        postDigestion: digestion,
        hasEnd: hasEnd
    };
}

const parsePreparation = (node, digestion) => {
    let hasEnd = false,
        infoNode;
    if (!hasEnd) {
        if (node.nodeName === 'ol') {
            infoNode = node.getElementsByTagName('li');
            digestion.preparation = _.map(infoNode, (li, i) => {
                return getStepInfo(li, digestion, i);
            });
            hasEnd = true;
        }
    }
    return {
        postDigestion: digestion,
        hasEnd: hasEnd
    };
}

const parseSecundaryImage = (node, digestion) => {
    let hasEnd = (node.textContent.indexOf('Notas') !== -1);
    if (!hasEnd) {
        if (node.nodeName === 'div' || node.nodeName === 'figure') {
            let image = node.getElementsByTagName('img')[0].getAttribute('src')
            image = image.split('?')[0]+'?w=640';
            digestion.secundaryImage.push(image);
            digestion.figures.push({
                path: image,
                caption: node.getElementsByTagName('figcaption')[0].textContent
            });
        }
    }
    return {
        postDigestion: digestion,
        hasEnd: hasEnd
    };
}

const parseNotes = (node, digestion) => {
    let infoNode, hasEnd = false, msgTypeRegex, msgType;
    if (node.nodeName === 'ul') {
        infoNode = node.getElementsByTagName('li');
        msgTypeRegex = (new RegExp('type-[a-z]+', 'g'));
        digestion.notes = _.map(infoNode, (li) => {
            msgType = li.getAttribute('class').match(msgTypeRegex);
            msgType = msgType ? msgType[0].substring(5) : 'advice';
            li = Serializer(li);
            li = li.substring(li.indexOf('>')+1,li.length-5);
            return {
                    'type': 'text',
                    'msgType': msgType, //advice, alternative, link, recipe
                    'text': li
                }
        });
        hasEnd = true;
    }
    if(hasEnd){
        digestion.ingredients = digestion.ingredients.map((ingredientBlock) => {
            ingredientBlock.list = ingredientBlock.list.map((ingredient) => {
                ingredient.notes = ingredient.notes.map((noteIndex) => {
                    return digestion.notes[noteIndex] || noteIndex;
                });
                return ingredient;
            })
            return ingredientBlock;
        });

        digestion.preparation = digestion.preparation.map((step) => {
            step.notes = step.notes.map((noteIndex) => {
                return digestion.notes[noteIndex] || noteIndex;
            });
            return step;
        });
    }
    return {
        postDigestion: digestion,
        hasEnd: hasEnd
    };
}

export const calculateNutrients = (ingredientsBlocks, step, nutrientList) => {
    let result = {};
    result.all = {};
    ingredientsBlocks.forEach((ingredientsBlock) => {
        result[ingredientsBlock.name] = {};
        result = addNutrientsToIngredientsBlock(result, nutrientList, ingredientsBlock, step);
        result[ingredientsBlock.name].summary = getNutrientsSummary(result[ingredientsBlock.name]);
    });
    result.all.summary = getNutrientsSummary(result.all);
    return result;
}

const addNutrientsToIngredientsBlock = (result, nutrients, ingredientsBlock, step) => {
    nutrients.forEach((nutrient) => {
        result[ingredientsBlock.name][nutrient.usdaName] = ingredientsBlock.list.map( (ingredient) => {
            if(ingredient.nutrients && ingredient.nutrients[nutrient.usdaName]){
                return ingredient.gramsValue*ingredient.nutrients[nutrient.usdaName]/100*step*ingredient.nutrientCoeficient;
            }
            return 0;
        });
        result[ingredientsBlock.name][nutrient.usdaName] = _.sum(result[ingredientsBlock.name][nutrient.usdaName]);
        result.all[nutrient.usdaName] = result.all[nutrient.usdaName] || 0;
        result.all[nutrient.usdaName] += result[ingredientsBlock.name][nutrient.usdaName]
        if(nutrient.children) {
            result = addNutrientsToIngredientsBlock(result, nutrient.children, ingredientsBlock, step);
        }
    });
    return result;
}

const getNutrientsSummary = (ingredients) => {
    let summary = {
        p : ingredients['Protein'],
        c : ingredients['Carbohydrate, by difference'],
        f : ingredients['Total lipid (fat)'],
    }
    summary.total = summary.p+summary.c+summary.f;
    if(summary.total === 0){
        return {
            p : 0,
            c : 0,
            f : 0,
            cal : 0
        }
    }
    return {
        p : summary.p/summary.total,
        c : summary.c/summary.total,
        f : summary.f/summary.total,
        cal: (summary.p*4 + summary.c*4 + summary.f*9)
    }
}

const removeScriptsFromPostContent = (content) => {
    if(!content) {
        return;
    }
    content = content.replace(/&nbsp;/gi, '')
    let startIndex,
        endIndex;
    while(content.indexOf('<script') !== -1) {
        startIndex = content.indexOf('<script');
        endIndex = content.indexOf('</script>') + 9;
        content = content.substring(0, startIndex) + ' ' + content.substring(endIndex, content.length);
    }
    return content;
}

export const PostParser = (post, firebaseData) => {
    console.log(post.title);
    post.content = removeScriptsFromPostContent(post.content);
    let doc = new DOMParser().parseFromString('<xml>' + post.content + '</xml>', 'text/xml'),
        parsers = [parseMainImage, parseIntroduction, parseSummary, parseIngredients, parsePreparation, parseSecundaryImage, parseNotes],
        postDigestion = {
            'title': post.title.toLowerCase(),
            'slug': '*wp*'+post.slug,
            'date': post.date,
            'modified': post.modified,
            'fromWP': true,
            'mainImage': null,
            'introduction': '',
            'introductionBlogOnly': '',
            'summary': {},
            'figures': [],
            'ingredients': [],
            'preparation': [],
            'secundaryImage': [],
            'notes': [],
            'categories': _.map(post.terms.category, (v, name) => {
                return name
            }),
            'tags': _.map(post.terms.post_tag, (v, name) => {
                return name
            }),
            'share': {
                title: post.title,
                message: post.title+' por @Vegamecum',
                url: post.URL,
                subject: ''
            },
            nutrients: {}
        },
        data,
        currentParser = 0,
        node,
        i = 0;
    while (doc.getElementsByTagName('xml')[0].childNodes.length > i) {
        if (currentParser < parsers.length) {
            node = doc.getElementsByTagName('xml')[0].childNodes[i];
            data = parsers[currentParser](node, postDigestion, firebaseData);
            postDigestion = data.postDigestion;
            if (data.hasEnd) {
                currentParser++;
            } else {
                i++;
            }
        } else {
            i++;
        }
    };
    console.log(postDigestion);
    postDigestion.nutrients = calculateNutrients(postDigestion.ingredients, postDigestion.summary.units.type.step || 1, firebaseData.nutrientList);
    postDigestion.title = postDigestion.title.charAt(0).toUpperCase() + postDigestion.title.slice(1);
    return {
        ...postDigestion,
        //...post
    }
}
