/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import I18n from '../../i18n';

class RelatedPostsList extends Component {
  render() {
    let previous = this._renderPrevious();
    let next = this._renderNext();
    if(!previous && !next) {
      return null;
    }
    return <nav className="navigation post-navigation" role="navigation">
              <h2 className="screen-reader-text">Navegación de entradas</h2>
              <div className="nav-links">
                {previous}
                {next}
              </div>
            </nav>
  }
  _renderPrevious() {
    if(!this.props.previous) {
      return null;
    }
    return <div className="nav-previous">
              <Link to={this.props.previous.link} rel="prev">
                <span className="meta-nav" aria-hidden="true">{I18n.t('detail.related.previous')}</span>
                <span className="screen-reader-text">{I18n.t('detail.related.previous')}</span>
                <span className="post-title">{this.props.previous.name}</span>
              </Link>
            </div>
  }
  _renderNext() {
    if(!this.props.next) {
      return null;
    }
    return <div className="nav-next">
              <Link to={this.props.next.link} rel="next">
                <span className="meta-nav" aria-hidden="true">{I18n.t('detail.related.next')}</span>
                <span className="screen-reader-text">{I18n.t('detail.related.next')}</span>
                <span className="post-title">{this.props.next.name}</span>
              </Link>
            </div>
  }
}

RelatedPostsList.propTypes = {
  dispatch: PropTypes.func,
  items: PropTypes.array
};

RelatedPostsList.defaultProps = {
  dispatch: () => {},
  items: []
};

export default RelatedPostsList;
