import unit from './unit';

const map = {
	name: 'cart',
	key: 'cart',
	type: 'object',
	child: [{
		name: '*',
		key: '*',
		type: 'object',
		child: [{
			name: 'gramsValue',
			key: 'g'
		},{
			name: 'name',
			key: 'n'
		},{
			name: 'recipe',
			key: 'r',
			type: 'object',
			child: [{
				name: 'gramsValue',
				key: 'g'
			},{
				name: 'image',
				key: 'm'
			},{
				name: 'index',
				key: 'i'
			},{
				name: 'slug',
				key: 's'
			},{
				name: 'title',
				key: 't'
			},{
				name: 'unitValueName',
				key: 'u'
			}]
		},{
			name: 'unit',
			key: 'u',
			type: 'object',
			child: unit.child
		},{
			name: 'units',
			key: 's',
			type: 'object',
			child: [{
				name: '*',
				key: '*',
				type: 'object',
				child: unit.child
			}]
		}]
	}]
};

export default map;