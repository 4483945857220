const map = {
	name: 'unit',
	key: 'unit',
	type: 'object',
	child: [{
		name: 'cart',
		key: 'c'
	},{
		name: 'grams',
		key: 'g'
	},{
		name: 'name',
		key: 'n'
	},{
		name: 'regex',
		key: 'r'
	},{
		name: 'scaleLess',
		key: 's'
	}]
};

export default map;