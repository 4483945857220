const Quotes = [
{
  "text": "Bienvenido a Vegamecum",
  "time": 2000
}, {
  "origin" : "Hipocrates",
  "type": "quote",
  "text" : "Que la comida sea tu medicina"
}, {
  "type": "health",
  "text" : "Los productos vegetales son libres de colesterol; los únicos que lo contienen son los productos de origen animal"
}, {
  "type": "environment",
  "text" : "La carne y los productos lácteos son responsables del 70% del consumo de agua potable global, del uso del 38% del total de la tierra y del 19% de las emisiones de gases de efecto invernadero"
}, {
  "type": "health",
  "text" : "Los garbanzos son muy bajos en grasa y sodio y altos en proteína, hierro, vitaminas, fibra y antioxidantes"
}, {
  "type": "ethical",
  "text" : "En las granjas industriales, la mayoría de los cerdos tienen neumonía a causa de la irritación que les producen en los pulmones el amoníaco y demás gases emitidos por los purines"
}, {
  "type": "health",
  "text": "El 80% de los antibióticos vendidos en Estados Unidos son destinados al ganado"
}, {
  "type": "environment",
  "text": "1 hamburguesa requiere de 3 Kg. de pienso y más de 2000 litros de agua"
}, {
  "type": "health",
  "text" : "Argentina y Uruguay tienen los mayores índices de consumo de carne, así como de cáncer de mama y colorrectal"
}]

export default Quotes;