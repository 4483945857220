/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from "react";
import _ from 'lodash';
import clsx from "clsx";

class EditButton extends PureComponent {
  constructor(props: any) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }
  render() {
    let Wrapper;
    let wrapperProps;
    if (this.props.tagName) {
      Wrapper = this.props.tagName;
      wrapperProps = {
        style: this.props.style,
        className: this.props.className
      }
    } else {
      Wrapper = React.Fragment;
    }

    if (!this.props.active) {
      let DisableWrapper = Wrapper;
      if(this.props.disableTagName) {
        DisableWrapper = this.props.disableTagName;
        wrapperProps = {};
      }
      return (
        <DisableWrapper {...wrapperProps}>
          {this.props.readComponent}
        </DisableWrapper>
      );
    }
    return (
      <Wrapper
        style={this.props.style}
        className={clsx([this.props.className, "edit-button"])}
      >
        {this._renderContent()}
      </Wrapper>
    );
  }
  _renderContent() {
    if (this.state.isEditing) {
      let editComponent = _.isFunction(this.props.editComponent) ? this.props.editComponent() : this.props.editComponent;
      return (
        <React.Fragment>
          {editComponent}
          <div className="edit-buttons-container">
            <button onClick={this._onSavePress.bind(this)} type="button">
              SAVE
            </button>
            <button onClick={this._onCancelPress.bind(this)} type="button">
              CANCEL
            </button>
            <div className="edit-delete-button">
              <button onClick={this._onDeletePress.bind(this)} type="button">
                DELETE
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className="edit-overlay"
            onClick={this._onEditRequest.bind(this)}
          ></div>
          {this.props.readComponent}
        </React.Fragment>
      );
    }
  }
  _onEditRequest() {
    this.setState({
      isEditing: true,
    });
  }
  _onCancelPress() {
    this.setState({
      isEditing: false,
    });
  }
  _onSavePress() {
    this.setState({
      isEditing: false,
    });
    this.props.onSavePress();
  }
  _onDeletePress() {
    this.setState({
      isEditing: false,
    });
    this.props.onDeletePress();
  }
}

EditButton.propTypes = {};

EditButton.defaultProps = {
  active: false,
  tagName: null,
  editComponent: null,
  readComponent: null,
  onSavePress: () => {},
  onDeletePress: () => {},
};

export default EditButton;
