/* @flow */

import * as types from "../actions";
import _ from 'lodash';
import produce from "immer";
import {AsyncStorage} from '../platform';

const data = (state = {
	quotes: [],
	active: null,
	index: 0,
}, action) => produce(state, draft => {
    let data;
    switch (action.type) {
        case types.RECEIVE_QUOTES:
            draft.quotes = action.data;
            return;
        case types.UPDATE_QUOTE_INDEX:
        	let index;
        	if(_.isNumber(action.data)) {
        		index = action.data;
        	} else {
        		index = (state.index < state.quotes.length - 1) ? state.index + 1 : 0;	
        	}
        	data = Object.assign({}, state, {
                index,
                active: _.isArray(state.quotes) ? (state.quotes[index] || state.quotes[0]) : null
            });
            saveOnAsyncStorage(data);
            return data;
    }
});

const saveOnAsyncStorage = (data) => {
    data.index = (data.index < data.quotes.length - 1) ? data.index + 1 : 0;
	AsyncStorage.setItem('VEGAN_QUOTES', JSON.stringify(data));
}


export default data;
