/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from "react";
import _ from "lodash";

class EditImage extends PureComponent {
  constructor(props: any) {
    super(props);
    this.imageProps = {
      path: this.props.path,
      caption: this.props.caption,
    };
    this.state = {
      updateHook: 0,
    };
  }
  render() {
    return (
      <React.Fragment>
        <img
          className="wp-image-9150 size-large"
          title={this.imageProps.caption}
          src={this.imageProps.path}
          alt={this.imageProps.caption}
          width="840"
          height="643"
        />
        <input type="file" onChange={this._onUploadFileChange}/>
        <input
          type="text"
          defaultValue={this.imageProps.path}
          placeholder="image url"
          onChange={this._onChange.bind(this, ["path"])}
          onBlur={this._forceRender}
        />
        <input
          type="text"
          defaultValue={this.imageProps.caption}
          placeholder="image caption"
          onChange={this._onChange.bind(this, ["caption"])}
        />
      </React.Fragment>
    );
  }
  _onUploadFileChange = (e) => {
    this.props.onUploadFile(e.target.files[0]);
  }
  _onChange(path, { currentTarget }, forceRender = false) {
    let imageProps = _.set(this.imageProps, path, currentTarget.value);
    this.props.onChange(imageProps);
    if (forceRender) {
      this._forceRender();
    }
  }
  _forceRender = () => {
    this.setState({
      updateHook: this.state.updateHook + 1,
    });
  }
}

EditImage.propTypes = {};

EditImage.defaultProps = {
  onChange: () => {},
};

export default EditImage;
