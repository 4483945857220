/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import Image from "../../containers/post-image";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from "@material-ui/core";
import { KeyboardArrowUp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    padding: "20px",
    zIndex: 999,
    width: "100%",
    color: theme.palette.text.secondary,
  },
}));

function Consent(props) {
  const classes = useStyles();
  return (
    <Box component={"div"} className={classes.container}>
      <Container>
        <FormGroup>
          <FormControlLabel
            fullWidth
            control={<Switch size="small" />}
            label="Small"
            labelPlacement="start"
          />
          <FormControlLabel
            fullWidth
            control={<Switch size="small" />}
            label="Normal"
            labelPlacement="start"
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<KeyboardArrowUp />}
          >
            Send
          </Button>
        </FormGroup>
      </Container>
    </Box>
  );
}

Consent.propTypes = {};

Consent.defaultProps = {};

export default Consent;
