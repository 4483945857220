/* @flow */

import * as types from "../actions";
import produce from "immer";
import posts from "./posts";

const data = (state = {
    loading: false,
    posts: [],
    nextPage: null
}, action) => produce(state, draft => {
    switch (action.type) {
        case types.CLEAR_POST_LIST:
            draft.posts = [];
            return;
        case types.REQUEST_POST_LIST:
            draft.loading = true;
            return;
        case types.RECEIVE_POST_LIST:
        let list = posts(state.posts,action);
            let nextPage;
            if(action.data.nextPage) {
                nextPage = action.data.nextPage[0];
                if(nextPage.next) {
                    nextPage = produce(nextPage, (nextState) => {
                        nextState.next = (nextPage.next === 'auto') ? list[list.length - 1].priorityKey + 1 : nextPage.next
                    });
                }
                nextPage = [nextPage];
            }
            draft.loading = false;
            draft.posts = list;
            draft.nextPage = nextPage;
            draft.filters = action.data.filters;
            draft.pager = action.data.pager;
            draft.nextPage = action.data.nextPage;
            return;
        case types.REMOVE_POST_FROM_LIST:
            draft.posts = posts(state.posts,action);
            return;
    }
});

export default data;