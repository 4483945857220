/* @flow */
/*eslint-disable prefer-const */

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getFilterLink } from "../../utils/link-maker";
import _ from "lodash";

const useContainerStyles = makeStyles((theme) => ({
  root: (props) => ({
    transition: theme.transitions.create(["height"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: props.open ? theme.spacing(66) : 0,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: (props) => ({
      height: props.open ? theme.spacing(40) : 0,
    }),
  }),
}));

const useItemStyles = makeStyles((theme) => ({
  item: {
    position: "relative",
    overflow: "hidden",
    width: theme.spacing(26),
    height: theme.spacing(26),
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      margin: theme.spacing(1),
    },
  },
  itemTitle: {
    zIndex: 1,
    backgroundImage:
      "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0.7) 100%)",
  },
  image: (props, visible) => ({
    transform: [
      "scale(2) translate(25%,25%)",
      "scale(2) translate(-25%,25%)",
      "scale(2) translate(-25%,-25%)",
      "scale(2) translate(25%,-25%)",
    ][props.position],
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    position: "absolute",
    width: "100%",
    height: "100%",
  }),
}));

function Item(props) {
  const [visible, setVisible] = useState(false);
  const classes = useItemStyles(props);
  useEffect(() => {
    if (props.open && !visible) {
      setVisible(true);
    }
  }, [props.open]);
  return (
    <Link
      component={RouterLink}
      to={getFilterLink(props.name)}
      title={props.name}
    >
      <Box
        flexDirection="row"
        className={classes.item}
        alignItems="flex-end"
        display="flex"
      >
        {props.pictures && (
          <img
            className={classes.image}
            title={props.name}
            data-src={props.pictures[0]}
            src={
              visible
                ? props.pictures[0]
                : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
            }
            alt={props.name}
            width="840"
            height="840"
          />
        )}
        <Box
          className={classes.itemTitle}
          fontWeight={600}
          textAlign="center"
          color="text.secondary"
          flex={1}
          pt={5}
        >
          {props.name}
        </Box>
      </Box>
    </Link>
  );
}

function Filters(props) {
  const classes = useContainerStyles(props);
  const [imagePositions, setImagePositions] = useState({});

  useEffect(() => {
    if (props.open) {
      let timeout = setTimeout(() => {
        let index = Math.floor(Math.random() * props.filters.length);
        let position = imagePositions[index] || 0;
        position++;
        position = position >= 4 ? 0 : position;
        let newImagePositions = {
          ...imagePositions,
          [index]: position,
        };
        setImagePositions(newImagePositions);
      }, 2000);
      return (params) => {
        clearTimeout(timeout);
      };
    }
  }, [imagePositions, props.open]);
  return (
    <Box className={classes.root}>
      <Box bgcolor="secondary.main">
        <Container>
          <Box
            maxWidth={"100%"}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
          >
            {_.compact(props.filters.map((item, index) => (
              item.available !== false ? <Item
                key={item.name}
                position={
                  imagePositions[index] !== undefined
                    ? imagePositions[index]
                    : 0
                }
                open={props.open}
                {...item}
              /> : null
            )))}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

Filters.propTypes = {};

Filters.defaultProps = {};

export default Filters;
