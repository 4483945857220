/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import ContentLoader from 'react-content-loader'

class PostSkeleton extends Component {
  constructor(props: any) {
    super(props);
    this._initializeElements();
  }
  render() {
    return <div className="entry-summary">
              <ContentLoader width={this.width} height={this.height}>
              {this.svgElements.map((svgProps, index) => (
                <rect key={`key-${svgProps.y}`} {...svgProps} />  
              ))}
              </ContentLoader>
          </div>
  }
  _initializeElements() {
    let elements = [{
      width: this._getRandomNumberBetween(500, 750),
      height: 49,
      marginBottom: 42
    },{
      width: 840,
      height: 690.38
    }, {
      width: this._getRandomNumberBetween(100, 500),
      height: 21,
      marginTop: 7,
      marginBottom: 28
    }, ...this._getParagraphs()];
    let factor = 1;
    let svgProps;
    let y = 0;
    let svgElements = elements.map((element) => {
      element = {
        width: 0,
        height: 0,
        marginBottom: 0,
        marginTop: 0,
        ...element
      };
      Object.keys(element).forEach((key) => {
        element[key] = Math.floor(element[key] * factor);
      });
      y += element.marginTop;
      svgProps = {
        x: 0,
        y: y,
        rx: 4,
        ry: 4,
        width: element.width,
        height: element.height
      };
      y += (element.height + element.marginBottom);
      return svgProps;
    });
    this.width = 840;
    this.height = y;
    this.svgElements = svgElements;
  }
  _getParagraphs() {
    let response = [];
    for (let index = 0; index < this.props.totalParagraphs; index++) {
        response = [...response, ...this._getParagraphBlock()];
    }
    return response;
  }
  _getParagraphBlock() {
    let response = [];
    let totalLines = this._getRandomNumberBetween(2, 6);
    let line;
    for (let index = 0; index < totalLines; index++) {
        line = {
            width: 840,
            height: 25,
            marginBottom: 10
        }
        if(index >= totalLines - 1) {
            line.width = this._getRandomNumberBetween(100, 800);
            line.marginBottom = 25;
        }
        response.push(line);
    }
    return response;
  }
  _getRandomNumberBetween(min, max) {
    max = max - min;
    let value = Math.random() * max;
    return min + value;
  }
}

PostSkeleton.propTypes = {
  
};

PostSkeleton.defaultProps = {
    totalParagraphs: 1
};

export default PostSkeleton;