/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import GlobalElements from "../../components/global-elements";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Menu from "../../components/menu";
import FirebaseAnalytics from "../../components/firebase-analytics";
import I18n from "../../i18n";
import SmartBanner from "react-smartbanner";
import { Route, Switch, Redirect } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#23023b",
    },
    secondary: {
      main: "rgba(236, 236, 236, 1)",
    },
    text: {
      primary: "rgba(35, 2, 59, 1)",
      secondary: "rgba(236, 236, 236, 1)",
    },
  },
});

class Scene extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <Switch>
            <Route
              path="/:language(es|en)"
              render={(props) => this._renderContent(props)}
            />
            <Route
              path="*"
              exact
              render={(props) => (
                <Redirect
                  to={`/es${props.match.url}${props.location.search}`}
                />
              )}
            />
          </Switch>
        </div>
      </ThemeProvider>
    );
  }
  _renderContent(props) {
    return (
      <React.Fragment key={props.match.params.language}>
        <SmartBanner title={"Vegamecum"} />
        <GlobalElements
          getNavigator={() => {
            return this.navigator;
          }}
        />
        <Header />
        <Switch>
          <Route
            path="/:language/home"
            exact
            render={(props) => this._renderHome(props)}
          ></Route>
          <Route path="*">
            <div className="site-inner container-style-definition">
              <a className="skip-link screen-reader-text" href="#content">
                Ir al contenido
              </a>
              <div id="content" className="site-content">
                <section id="primary" className="content-area">
                  <Switch>
                    <Route
                      path="/:language/sign-in"
                      exact
                      render={(props) => (
                        <this.props.screens.signIn
                          key={this._getKeyForProps(props)}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/:language/posts/:postSlug"
                      exact
                      render={(props) => (
                        <this.props.screens.detail
                          key={this._getKeyForProps(props)}
                          postPath={`/posts/${props.match.params.postSlug}`}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/:language/drafts"
                      exact
                      render={(props) => (
                        <this.props.screens.searcher
                          key={"drafts"}
                          listKey={"drafts"}
                          isDraft={true}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/:language/drafts/:userId/:postSlug"
                      exact
                      render={(props) => (
                        <this.props.screens.detail
                          key={this._getKeyForProps(props)}
                          postPath={`/users/${props.match.params.userId}/drafts/${props.match.params.postSlug}`}
                          isDraft={true}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/:language/filter/:filter/page/:page"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="/:language/filter/:filter"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="/:language/tag/:tag/page/:page"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="/:language/tag/:tag"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="/:language/category/:category/page/:page"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="/:language/category/:category"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="/:language/page/:page"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="/:language/:postPublishYear/:postPublishMonth/:postPublishDay/:postSlug/amp"
                      exact
                      render={(props) => (
                        <this.props.screens.detail
                          key={this._getKeyForProps(props)}
                          postPath={`/posts/${props.match.params.postSlug}`}
                          isAMP={true}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/:language/:postPublishYear/:postPublishMonth/:postPublishDay/:postSlug"
                      exact
                      render={(props) => (
                        <this.props.screens.detail
                          key={this._getKeyForProps(props)}
                          postPath={`/posts/${props.match.params.postSlug}`}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path="/:language"
                      exact
                      render={(props) => this._renderSearcher(props)}
                    />
                    <Route
                      path="*"
                      exact
                      render={(props) => (
                        <this.props.screens.notFound
                          key={this._getKeyForProps(props)}
                          {...props}
                        />
                      )}
                    />
                  </Switch>
                </section>
                <Menu />
              </div>
              <Footer />
            </div>
          </Route>
        </Switch>
        <FirebaseAnalytics />
      </React.Fragment>
    );
  }
  _renderSearcher(props) {
    if (I18n.setAppLanguage(props.match.params.language)) {
      return (
        <this.props.screens.searcher
          key={this._getKeyForProps(props)}
          {...props}
        />
      );
    } else {
      return (
        <Redirect
          from={props.location.pathname}
          to={props.location.pathname.replace(
            props.match.params.language,
            "es"
          )}
          exact
        />
      );
    }
  }
  _renderHome(props) {
    return (
      <this.props.screens.home key={this._getKeyForProps(props)} {...props} />
    );
  }
  _getKeyForProps(props) {
    I18n.setAppLanguage(props.match.params.language);
    return props.location.pathname + props.location.search;
  }
}

export default Scene;
