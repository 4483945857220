/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as colors from "../../constants/colors";
import EditButton from "../edit-button";
import EditImage from "../edit-image";

let imageCounter = 1000;
class Image extends Component {
  constructor(props: any) {
    super(props);
    this.id = imageCounter++;
  }
  _renderImage = () => {
    return (
      <figure
        id={`attachment_${this.id}`}
        aria-describedby={`caption-attachment-${this.id}"`}
        style={{ width: "840px" }}
        className="wp-caption alignnone"
      >
        <img
          className={`wp-image-${this.id} size-large`}
          title={this.props.caption}
          src={this.props.path}
          alt={this.props.caption}
          width="840"
          height="643"
        />
        <figcaption id={`caption-attachment-${this.id}`} className="wp-caption-text">
          {this.props.caption}
        </figcaption>
      </figure>
    );
  };
  render() {
    let figureChanged;
    let fileToUpload;
    let active = !this.props.enableImproveTranslations && this.props.isAdmin;
    if (!active && !this.props.path) {
      return null;
    }
    return (
      <EditButton
        tagName="div"
        active={active}
        editComponent={
          <EditImage
            path={this.props.path}
            caption={this.props.caption}
            onChange={(fig) => {
              figureChanged = fig;
            }}
            onUploadFile={(file) => {
              fileToUpload = file;
            }}
          />
        }
        readComponent={this._renderImage()}
        onSavePress={async () => {
          if (fileToUpload) {
            let { response, status } = await this.props.uploadImageFile(
              fileToUpload,
              {
                addWaterMark: true,
                width: "1280",
              }
            );
            figureChanged = figureChanged || {
              caption: this.props.caption,
            };
            figureChanged.path = response[0].url;
          }
          if (figureChanged) {
            if (this.props.mainImage) {
              figureChanged.main = true;
            }
            this.props.updatePostFigure(
              this.props.post,
              this.props.index || 0,
              figureChanged
            );
          }
        }}
      />
    );
  }
}

Image.propTypes = {};

Image.defaultProps = {};

export default Image;
