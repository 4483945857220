/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
//import PageContainer from "../../containers/page-container";
//import PageAnalytics from "../../components/page-analytics";

class BasicScreen extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    window.scroll({top: this._getOffset(), left: 0, behavior: 'smooth' }) 
  }
  componentDidUpdate() {
    this._scrollToContent();
  }
  render() {
    const Component = this.getComponent();
    return <Component
              {...this.props}
              match={this.props.match}
              history={this.props.history}
              location={this.props.location}
              {...this.props.match.params}
            />
  }
  _scrollToContent() {
    let offset = this._getOffset();
    if(offset !== undefined) {
      window.scroll({top: this._getOffset(), left: 0, behavior: 'smooth' }) 
    }
  }
  _getOffset() {
    // let titleEl = document.querySelector('#content');
    // if(titleEl) {
    //   return titleEl.getBoundingClientRect().top + window.scrollY - 21;
    // }
    // return 0;
    return 0;
  }
}

BasicScreen.propTypes = {};

BasicScreen.defaultProps = {};

export default BasicScreen;
