
import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { firebase} from "../../platform"

class FirebaseAnalytics extends Component {
    componentDidMount() {
        this._sendTrackEvent();
    }
    componentDidUpdate() {
        this._sendTrackEvent();
    }
    render() {
        return null;
    }
    _sendTrackEvent() {
        const analytics = firebase && firebase.analytics;
        if (typeof analytics === "function") {
            const page_path = this.props.location.pathname + this.props.location.search;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }
}



export default withRouter(FirebaseAnalytics);