const map = {
	name: 'searched',
	key: 'searched',
	type: 'object',
	child: [{
		name: '*',
		key: '*',
		type: 'object',
		child: [{
			name: 'text',
			key: 't'
		},{
			name: 'time',
			key: 'i'
		},{
			name: 'slug',
			key: 's'
		},{
			name: 'type',
			key: 'y'
		}]
	}]
};

export default map;
