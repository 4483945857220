/* @flow */

export const TEXT_COLOR_WHITE = "rgba(255, 255, 255, 1)";

export const TEXT_COLOR = "rgba(222, 249, 159, 1)";
export const BACKGROUND_COLOR_BASE_RGB = "35, 2, 59";
export const TEXT_COLOR_DARK = "rgba(35, 2, 59, 1)";
//export const BACKGROUND_COLOR_BASE_RGB = "239, 255, 223";
export const BACKGROUND_COLOR = "rgb("+BACKGROUND_COLOR_BASE_RGB+")";

export const BACKGROUND_COLOR_LIGHTER_BASE_RGB = '222,214,229';
export const BACKGROUND_COLOR_LIGHTER =  "rgb("+BACKGROUND_COLOR_LIGHTER_BASE_RGB+")";


/*Global*/
export const BODY_BACKGROUND_COLOR = "rgba(236, 236, 236, 1)";
export const BODY_BACKGROUND_COLOR_TRANSPARENT = "rgba(236, 236, 236, 0.8)";
export const OVERFLOW = 'rgba(0,0,0,0.5)';
export const RIPPLE_COLOR = 'rgba(35, 2, 59, 0.2)';
export const SEPARATOR_COLOR = "rgba("+BACKGROUND_COLOR_BASE_RGB+",0.2)";


/*Navbar*/
//export const NAVBAR_COLOR = "rgba(245, 245, 245, 1)";
export const NAVBAR_COLOR = BACKGROUND_COLOR;
export const NAVBAR_COLOR_TRANSLUCID = "rgba(255, 255, 255, 0.6)";
export const NAVBAR_DECORATION_COLOR = "rgba(245, 245, 245, 1)";
export const NAVBAR_TEXT_COLOR = "rgba(245, 245, 245, 1)";

/*Donate*/
export const DONATE_BACKGROUND_COLOR = "rgba(255, 255, 255, 0.8)";
export const DONATE_BUTTON_BACKGROUND_COLOR = NAVBAR_DECORATION_COLOR;
export const DONATE_BUTTON_COLOR = TEXT_COLOR_DARK;


/*Home*/
export const CATEGORY_COLOR = NAVBAR_DECORATION_COLOR;
export const CATEGORY_TEXT_COLOR = TEXT_COLOR_DARK;
export const CATEGORY_DECORATION_COLOR = BACKGROUND_COLOR;

/*Search*/
export const SEARCH_COLOR = NAVBAR_DECORATION_COLOR;
export const SEARCH_INPUT_COLOR = NAVBAR_DECORATION_COLOR;
export const SEARCH_INPUT_BORDER_COLOR = NAVBAR_COLOR;
export const SEARCH_INPUT_TEXT_COLOR = NAVBAR_COLOR;

/*Filter*/
export const FILTER_BACKGROUND_COLOR = NAVBAR_DECORATION_COLOR;
export const FILTER_COLOR = "rgba(230, 230, 230, 1.0)";
export const FILTER_DISABLED_COLOR = "rgba(230, 230, 230, 0.2)";

/*Detail*/
export const STICKY_BACKGROUND = NAVBAR_DECORATION_COLOR;
export const STICKY_BORDER_COLOR = NAVBAR_COLOR;
export const DETAIL_BACKGROUND = BODY_BACKGROUND_COLOR;
export const DETAIL_TEXT_BACKGROUND = NAVBAR_DECORATION_COLOR;
export const DETAIL_SELECTOR_BACKGROUND = NAVBAR_DECORATION_COLOR;
export const DETAIL_SELECTOR_HEADER = NAVBAR_DECORATION_COLOR;
export const DETAIL_SELECTOR_BORDER_COLOR = BACKGROUND_COLOR;
export const DETAIL_SELECTOR_TEXT = BACKGROUND_COLOR;
export const DETAIL_SELECTOR_TAB = NAVBAR_DECORATION_COLOR;
export const DETAIL_SELECTOR_TAB_SELECTED = BACKGROUND_COLOR;
export const DETAIL_INGREDIENT_ICON_UNSELECTED = "rgba(35, 2, 59, 0.2)";
export const DETAIL_STEP_SELECTED = "rgba(245, 245, 245, 0.6)";
export const DECORATION_BORDER_COLOR = "rgba("+BACKGROUND_COLOR_BASE_RGB+",0.5)";


/*Nutrition*/
export const NUTRITION_CHART_BORDER = BACKGROUND_COLOR;
export const NUTRITION_CHART_BUBBLE = "#eadbf9";
export const NUTRITION_CHART_PROTEIN = "rgb(95, 62, 119)";
export const NUTRITION_CHART_CARBS = "rgb(155, 122, 179)";
export const NUTRITION_CHART_FATS = "rgb(65, 32, 89)";
export const NUTRITION_BAR_BACKGROUND = "rgba(255, 255, 255, 1)";
export const NUTRITION_BAR_COLOR = TEXT_COLOR_DARK;
export const NUTRITION_COMPONENT_VALUE = BACKGROUND_COLOR;
export const NUTRITION_COMPONENT_NAME = BACKGROUND_COLOR;

/*Preparation*/
export const PREPARATION_STEP_COLOR = TEXT_COLOR_WHITE;
export const PREPARATION_STEP_BACKGROUND = NAVBAR_DECORATION_COLOR;
export const PREPARATION_ALARM = TEXT_COLOR_DARK;
export const PREPARATION_STEP_GALLERY_COLOR = "rgba(255, 255, 255, 0.2)";

/*Editor*/
export const EDITOR_HEADER_BACKGROUND_COLOR = BODY_BACKGROUND_COLOR;
export const EDITOR_HEADER_COLOR = NAVBAR_COLOR;
export const EDITOR_BLOCK_COLOR = NAVBAR_DECORATION_COLOR;

/*Alarm*/
export const ALARM_BACKGROUND_COLOR = 'rgba(0,0,0,0.8)';

/*Notifications*/
export const NOTIFICATION_DATE_COLOR = TEXT_COLOR_DARK;
export const NOTIFICATION_BACKGROUND_COLOR = BODY_BACKGROUND_COLOR;
export const NOTIFICATION_ITEM_COLOR = NAVBAR_DECORATION_COLOR;

/*About*/
export const ABOUT_BACKGROUND_COLOR = NAVBAR_DECORATION_COLOR;

/*Planification*/
export const PLANIFICATION_TEXT = TEXT_COLOR_DARK;

/*Agenda*/
export const AGENDA_DAY_BACKGROUND = NAVBAR_DECORATION_COLOR
export const AGENDA_SELECTED_TEXT_COLOR = TEXT_COLOR_DARK;
export const AGENDA_TEXT_COLOR = "rgba(35, 2, 59, 0.7)";
export const AGENDA_BACKGROUND_COLOR = BODY_BACKGROUND_COLOR;
export const AGENDA_FOREGROUND_COLOR = NAVBAR_DECORATION_COLOR;
export const AGENDA_SEPARATOR_COLOR = AGENDA_TEXT_COLOR;

/*Popover*/
export const POPOVER_MODAL_COLOR_BACKGROUND = ALARM_BACKGROUND_COLOR;
export const POPOVER_COLOR_BACKGROUND = "rgba(255, 255, 255, 1)";

/*Downbar*/
export const DOWNBAR_COLOR_BACKGROUND = "rgba(255, 255, 255, 1)";

/*More Options*/
export const MORE_OPTIONS_TEXT = TEXT_COLOR_DARK;

/*Playlist*/
export const PLAYLIST_TEXT_COLOR = TEXT_COLOR_DARK;
export const PLAYLIST_COLOR_TRANSLUCID = "rgba(255, 255, 255, 0.9)";
export const PLAYLIST_BORDER_TOP = "rgba(0, 0, 0, 0.2)";

/*Toast*/
export const TOAST_COLOR_TRANSLUCID = "rgba(255, 255, 255, 0.9)";

/*Toast*/
export const TRANSLATE_ORIGINAL_BACKGROUND = BODY_BACKGROUND_COLOR;

/*Cart*/
export const CART_BACKGROUND_COLOR = NAVBAR_DECORATION_COLOR;

/*Recipe finished*/
export const RECIPE_FINISHED_GOLD = "rgb(255,215,0)";

/*Filter editor*/
export const FILTER_EDITOR_INGREDIENTS_TITLE = "rgba(230, 230, 230, 1.0)";
export const FILTER_EDITOR_INGREDIENTS_ROW = "rgba(230, 230, 230, 0.5)";
export const FILTER_BORDER_COLOR = SEPARATOR_COLOR;

/*Filter editor*/
export const NO_IMAGE_ICON = PLAYLIST_COLOR_TRANSLUCID;
export const NO_IMAGE_BACKGROUND = BODY_BACKGROUND_COLOR;

/*Diet calendar picker*/
export const DIET_CALENDAR_MESSAGE_BACKGROUND = BODY_BACKGROUND_COLOR_TRANSPARENT;
export const DIET_CALENDAR_MESSAGE_TEXT = TEXT_COLOR_DARK;

export const getElevatedShadow = (elevation) => {
  return {
    shadowColor: 'black',
    shadowOpacity: 0.0015 * elevation + 0.18,
    shadowRadius: 0.54 * elevation,
    shadowOffset: {
      height: 0.6 * elevation,
    },
    elevation: elevation
  }
}

export const CONTAINER_SHADOW = getElevatedShadow(2);


export const getBackgroundColorWithOpacity = (opacity): Number => {
    return "rgba("+BACKGROUND_COLOR_BASE_RGB+","+opacity+")";
};

export const getBackgroundColorLighterWithOpacity = (opacity): Number => {
    return "rgba("+BACKGROUND_COLOR_LIGHTER_BASE_RGB+","+opacity+")";
};

export const changeTheme = (key) => {
  
}