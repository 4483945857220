import { connect } from "react-redux";
import SearchBox from "../../components/search-box";
//import { updatePostFigure, uploadImageFile } from "../../actions/edit";
import {
  getPostsSuggestions,
  getIngredientsSuggestionsList,
} from '../../selectors/searcher';
import {
  setSearchingText
} from '../../actions';
import { bindActionCreators } from "redux";

function mapStateToProps(state, props) {
  return {
    searchingText: state.search.searchingText || '',
    lastSearched: props.suggestLast === false ? [] : state.search.lastSearched,
    searchSuggestionsPosts: getPostsSuggestions(state, props),
    searchSuggestionsIngredients: getIngredientsSuggestionsList(state, props),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({setSearchingText}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
