/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import SearchBox from "../../containers/search-box";
import I18n from "../../i18n";

class NotFound extends Component {
  render() {
    return (
      <section className="no-results not-found">
        <header className="page-header">
          <h1 className="page-title">
            {this.props.title || I18n.t("notFound.page.title")}
          </h1>
        </header>
        <div className="page-content">
          <p>{this.props.text || I18n.t("notFound.page.subtitle")}</p>
          <SearchBox fullWidth defaultValue={this.props.searchValue} />
        </div>
      </section>
    );
  }
}

NotFound.propTypes = {};

NotFound.defaultProps = {
  error: true,
  title: null,
  text: null,
  searchValue: "",
};

export default NotFound;
