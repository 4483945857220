/* @flow */
/*eslint-disable prefer-const */

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateTranslation } from "../../actions";
import {
  removeIngredientBlock,
  updateIngredientBlock,
  updateIngredientBlockName,
} from "../../actions/edit";
import EditButton from "../../components/edit-button";
import EditIngredientBlock from "../../components/edit-ingredients";
import I18n from "../../i18n";
import { getLinkForPathAndSearch } from "../../utils/link-maker";
import TranslateButton from "../improve-translation";
import { Link as RouterLink } from "react-router-dom";

class PostDetailIngredients extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        {this.props.ingredients.map(this._renderBlock.bind(this))}
        {!this.props.enableImproveTranslations && this.props.isAdmin
          ? this._renderBlock({ list: [] }, this.props.ingredients.length)
          : null}
      </React.Fragment>
    );
  }
  _renderBlock(block, blockIndex) {
    return (
      <EditButton
        key={`block-${blockIndex}`}
        tagName={"div"}
        active={!this.props.enableImproveTranslations && this.props.isAdmin}
        editComponent={
          <EditIngredientBlock
            post={this.props.post}
            units={this.props.units}
            ingredients={this.props.allIngredients}
            ingredientBlock={block}
            onChange={(ingredients) => {
              this.ingredientsChanged = ingredients;
            }}
          />
        }
        onSavePress={() => {
          if (this.ingredientsChanged) {
            this.props.dispatch(
              updateIngredientBlock(
                this.props.post,
                blockIndex,
                this.ingredientsChanged.list
              )
            );
            this.props.dispatch(
              updateIngredientBlockName(
                this.props.post,
                blockIndex,
                this.ingredientsChanged.name
              )
            );
          }
        }}
        onDeletePress={() => {
          this.props.dispatch(
            removeIngredientBlock(this.props.post, blockIndex)
          );
        }}
        readComponent={
          <React.Fragment key={block.name}>
            {this._renderTitle(block.name)}
            {this._renderIngredients(block.list, blockIndex)}
          </React.Fragment>
        }
        style={{ paddingBottom: "10px" }}
      ></EditButton>
    );
  }
  _renderTitle(title) {
    title =
      title === "default" ? I18n.t("detail.sections.ingredients.title") : title;
    return (
      <p>
        <strong>{title}</strong>
      </p>
    );
  }
  _renderIngredients(list, blockIndex) {
    return (
      <ul>
        {list.map((ingredient, i) => (
          <React.Fragment key={`${blockIndex}-${i}`}>
            {this._renderIngredient(blockIndex, ingredient, i)}
          </React.Fragment>
        ))}
      </ul>
    );
  }
  _renderIngredient(blockIndex, ingredient, i) {
    let ingredientText;
    if (ingredient.recipe) {
      let recipeNote = _.find(ingredient.notes, {
        type: "recipe",
      });
      if (recipeNote) {
        ingredientText = (
          <RouterLink
            to={getLinkForPathAndSearch({
              path: `/posts/${recipeNote.slug}`,
            })}
          >
            {ingredient.processedText}
          </RouterLink>
        );
      }
    } else {
      ingredientText = ingredient.processedText;
    }
    return (
      <li>
        {ingredientText}
        <TranslateButton
          paths={[
            "name",
            "plural",
            "unit.name",
            "unit.plural",
            "unit.cart.name",
            "unit.cart.plural",
            "unit.cart.text",
            "moreInfo",
            /notes\..*\.text/,
          ]}
          active={this.props.enableImproveTranslations}
          text={ingredient}
          onTranslateDone={this._onTranslateDone.bind(this, blockIndex, i)}
        />
      </li>
    );
  }
  _onTranslateDone(blockIndex, ingredientIndex, obj) {
    this.props.dispatch(
      updateTranslation({
        language: "en",
        slug: this.props.post.slug,
        key: `posts/${this.props.post.slug}/ingredients/${blockIndex}/list/${ingredientIndex}`,
        text: obj,
        tsPath: "posts/" + this.props.post.slug + "/modified",
      })
    );
  }
}

PostDetailIngredients.propTypes = {
  dispatch: PropTypes.func,
};

PostDetailIngredients.defaultProps = {
  dispatch: () => {},
  isAdmin: false,
};

export default connect((state, props) => {
  return {
    isAdmin: state.user.admin,
    allIngredients: state.ingredients.list,
    ingredients: props.post.ingredients,
    units: props.post.summary.units,
    enableImproveTranslations: props.post.__vo && state.user.admin, //state.settings.enableImproveTranslations,
  };
})(PostDetailIngredients);
