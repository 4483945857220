import { connect } from "react-redux";
import Filters from "../../components/filters";
import {} from "../../actions/edit";
import { bindActionCreators } from "redux";

function mapStateToProps(state, props) {
  return {
    filters: state.filters.list,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
