/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import qs from "qs";
import * as slugify from "slugify";
import Posts from "../posts";
import I18n from "../../i18n";
import _ from "lodash";
import { withRouter } from "react-router-dom";

class SearcherContent extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      navigationParams: this._getNavigationParams(),
      postFilters: this._getFilterParams(),
      searchText: this._getSearchText(),
      isSearchingRecipe: this._isSearchingRecipe(),
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.filters !== prevProps.filters ||
      this.props.categories !== prevProps.categories
    ) {
      this.setState({
        postFilters: this._getFilterParams(),
        searchText: this._getSearchText(),
        isSearchingRecipe: this._isSearchingRecipe(),
      });
    }
  }
  render() {
    let isLoading = !this.props.initialized;
    if (isLoading) {
      return null;
    }
    return (
      <Posts
        loadingSkeletonNumber={this.state.isSearchingRecipe ? 1 : 3}
        navigateOnLoad={this.state.isSearchingRecipe}
        navigationParams={this.state.navigationParams}
        postFilters={this.state.postFilters}
        searchText={this.state.searchText}
        listKey={
          this.props.listKey ||
          this.props.location.pathname + this.props.location.search
        }
      />
    );
  }
  _getSearchText() {
    let value;
    if (this.props.match.params.tag) {
      value = this._upperCaseFirstLetter(this.props.match.params.tag);
      return {
        searchType: "tag",
        text: I18n.t("searcher.tags", {
          searchValue: value,
        }),
        value,
      };
    }
    if (this.props.match.params.category) {
      let selectedCategory = this._getSelectedCategory();
      let value;
      if (selectedCategory) {
        value = I18n.t(
          `detail.related.categoryList.${selectedCategory.name.toLowerCase()}`,
          {
            defaultValue: selectedCategory.name,
          }
        );
      } else {
        value = this.props.match.params.category;
      }
      value = this._upperCaseFirstLetter(value);
      return {
        searchType: "category",
        text: I18n.t("searcher.category", {
          searchValue: value,
        }),
        value,
      };
    }
    if (this.props.match.params.filter) {
      let selectedFilter = this._getSelectedFilter();
      let value;
      if (selectedFilter) {
        value = selectedFilter.name;
      } else {
        value = this.props.match.params.filter;
      }
      value = this._upperCaseFirstLetter(value);
      return {
        searchType: "filter",
        text: I18n.t("searcher.filter", {
          searchValue: value,
        }),
        value,
      };
    }
    const searchObject = this._getSearchObject();
    if (searchObject && searchObject.type !== "recipe") {
      value = this._upperCaseFirstLetter(searchObject.params);
      return {
        searchType: "search",
        text: I18n.t("searcher.text", {
          searchValue: value,
        }),
        value: this._upperCaseFirstLetter(searchObject.params),
      };
    }
  }
  _getNavigationParams() {
    let search = "";
    let path = this.props.match.url;
    let pageIndex = path.indexOf("/page/");
    if (pageIndex !== -1) {
      path = path.substring(0, pageIndex);
    }
    let searchValue = this._getSearchValue();
    if (searchValue) {
      search = `s=${searchValue}`;
    }
    return {
      path,
      search,
    };
  }
  _getFilterParams() {
    let searchParam = this._getSearchParams();
    if (this.props.match.path === "/:language/drafts") {
      return [
        "drafts",
        {
          ref: `/users/${this.props.userId}/drafts`,
          limit: 100,
        },
      ];
    }
    if (searchParam) {
      return [
        "search",
        {
          searchParam,
          next: this._getPageParams(),
        },
      ];
    }
  }
  _getSearchParams() {
    let params = [];
    if (this.props.match.params.tag) {
      params.push(`tags:"${this.props.match.params.tag}"`);
    }
    if (this.props.match.params.category) {
      let selectedCategory = this._getSelectedCategory();
      console.log("selectedCategory", selectedCategory);
      if (selectedCategory) {
        params.push(`categories:"${selectedCategory.name.toLowerCase()}"`);
      } else {
        return null;
      }
    }
    if (this.props.match.params.filter) {
      let selectedFilter = this._getSelectedFilter();
      if (selectedFilter) {
        params.push(selectedFilter.query);
      } else {
        return null;
      }
    }
    let searchObject = this._getSearchObject();
    if (searchObject) {
      if (searchObject.type === "recipe") {
        params.push(`(title:"${searchObject.params}")`);
      } else if (searchObject.type === "ingredient") {
        params.push(`(ingredients.list.name:"${searchObject.params}")`);
      } else {
        params.push(
          `(ingredients.list.name:${searchObject.params}*) OR (title:${searchObject.params}*)`
        );
      }
    }
    if (params.length === 0) {
      params.push("title:*");
    }
    params.push("orderBy date:desc");
    return params.join(" ");
  }
  _getSearchValue() {
    let object = this._getSearchObject();
    return object && object.params;
  }
  _getSearchObject() {
    let params = this._getSearchParamsFromQueryString();
    if (params) {
      let searchParams = params.split(":");
      let type = "all";
      if (searchParams.length === 2) {
        type = searchParams[0];
        params = searchParams[1];
      }
      return {
        type,
        params,
      };
    }
  }
  _isSearchingRecipe() {
    let searchObject = this._getSearchObject();
    return searchObject && searchObject.type === "recipe";
  }
  _getSearchParamsFromQueryString() {
    const values = qs.parse(this.props.history.location.search, {
      ignoreQueryPrefix: true,
    });
    return values ? values.s : null;
  }
  _getPageParams() {
    let page = this.props.match.params.page || 1;
    page = page - 1;
    let limit = 10;
    return {
      from: page * limit,
      limit,
    };
  }
  _upperCaseFirstLetter(str) {
    str = str.replace(/-/g, " ");
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  _getSelectedFilter() {
    return _.find(this.props.filters, (filter) => {
      return (
        slugify(filter.name, {
          replacement: "-",
          remove: null,
          lower: true,
        }) === this.props.match.params.filter
      );
    });
  }
  _getSelectedCategory() {
    return _.find(this.props.categories, (category) => {
      return (
        slugify(category.name, {
          replacement: "-",
          remove: null,
          lower: true,
        }) === this.props.match.params.category
      );
    });
  }
}

SearcherContent.propTypes = {};

SearcherContent.defaultProps = {
  dispatch: () => {},
};

export default withRouter(
  connect((state) => ({
    initialized: state.app.initialized,
    filters: state.filters.list,
    categories: state.categories.list,
    userId: state.user.uid,
  }))(SearcherContent)
);
