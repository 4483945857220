/* @flow */

import * as types from "../actions";
import produce from "immer";
import * as values from "../constants/values";
import {setFontSizeFactor} from "../constants/styles";
import {changeTheme} from "../constants/colors";
import {FCM, Orientation} from '../platform';
import I18n from '../i18n';

const data = (state:Object = {
	language: null,
    enableImproveTranslations: false,
    groupByRecipe: true,
    disableSoundTouchEffect: false,
    disableSoundAlarm: false,
    disableNotificationsNewRecipe: false,
    disableNotificationsAlarm: false,
    enableScaleLess: false,
    enableRotation: true,
    fontSize: 1,
    enableVoiceControl: false,
    theme: 'vegamecum.light'
}, action) => produce(state, draft => {
    switch (action.type) {
    	case types.SETTINGS:
            if(action.data.disableNotificationsNewRecipe === true) {
                FCM.unsubscribeFromTopic(values.TOPIC_NEW_RECIPE);
            } else {
                FCM.subscribeToTopic(values.TOPIC_NEW_RECIPE);
            }
            if(action.data.enableRotation === false) {
                Orientation.lockToPortrait();
            } else {
                Orientation.unlockAllOrientations();
            }
            if(action.data.language) {
                I18n.setLanguage(action.data.language);
            }
            if(action.data.fontSize) {
                setFontSizeFactor(action.data.fontSize);
            }
            if(action.data.theme) {
                changeTheme(action.data.theme);
            }
            return Object.assign({}, state, action.data);
    }
});

export default data;
