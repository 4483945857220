/* @flow */
/*eslint-disable prefer-const */

import React from 'react';
import Component from "../super";
import DetailPage from "../../containers/detail";


class Detail extends Component {
  getComponent() {
  	return DetailPage;
  }
}

Detail.propTypes = {};

Detail.defaultProps = {};

export default Detail;