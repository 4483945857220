import _ from 'lodash';
import * as values from "../constants/values";
import * as NumberBeautifier from "./number-beautifier";

export const getIngredientInfo = (text, recipe, firebaseData) => {
    let ingredient = getIngredient(text, firebaseData) || {};
    ingredient.value = ingredient.value / recipe.summary.units.value;
    ingredient.nutrientCoeficient = getIngredientNutrientCoeficient(text);
    ingredient.notes = ingredient.notes || getIngredientNote(text);
    return normalizeIngredient(ingredient, recipe);
}

export const getProperlyUnitAndValue = (ingredient, unit, number, isCart) => {
    if(isCart && unit && unit.cart && unit.cart.unit) {
      if(ingredient.units && ingredient.units[unit.cart.unit]) {
        return getProperlyUnitAndValue(ingredient, ingredient.units[unit.cart.unit], number, isCart);  
      }
    }
    let value = (ingredient.gramsValue/unit.grams) * number;
    if(_.isNaN(value)) {
      value = 0;
    }
    let gt = unit.gt;
    let lt = unit.lt;
    if(gt && value > gt.value) {
      let gtUnit = gt.unit;
      if(ingredient.units && ingredient.units[gtUnit]) {
        return getProperlyUnitAndValue(ingredient, ingredient.units[gtUnit], number, isCart);  
      }
    } else if(lt && value < lt.value) {
      let ltUnit = lt.unit;
      if(ingredient.units && ingredient.units[ltUnit]) {
        return getProperlyUnitAndValue(ingredient, ingredient.units[ltUnit], number, isCart);  
      }
    }
    return {
      ingredientUnit: unit,
      ingredientValue: value,
    }
  }


export const getProcessedIngredientText = (ingredient, units, scaleLess, isCart = false) => {
    let unit = null;
    if(scaleLess) {
        unit = getScalelessUnit(ingredient, units);
    }
    return getIngredientText(ingredient, units, unit, isCart);
}

export const getIngredientText = (ingredient, units, unit, isCart) => {
    if(!ingredient) {
        return '';
    }
    if(_.isUndefined(ingredient.gramsValue)){
      return ingredient.text;
    }
    unit = unit || ingredient.unit;
    let {ingredientUnit, ingredientValue} = getProperlyUnitAndValue(ingredient, unit, units.value, isCart),
        ingredientValueText = '';
    
    if(ingredientValue !== Infinity){
      ingredientValue = ingredientValue.valueOf();
      ingredientValueText = NumberBeautifier.fractionalize(ingredientValue);
    }

    if(ingredientUnit) {
      if(isCart && ingredientUnit.cart && !ingredientUnit.cart.unit) {
        if(ingredientValue > 1 && ingredientUnit.cart.plural) {
          ingredientValueText = ingredientUnit.cart.plural
                                  .replace('$name', ingredient.name)
                                  .replace('$plural', ingredient.plural)
                                  .replace('$n',ingredientValueText.trim());    
        } else if(ingredientUnit.cart.name) {
          ingredientValueText = ingredientUnit.cart.name
                                  .replace('$name', ingredient.name)
                                  .replace('$plural', ingredient.plural)
                                  .replace('$n',ingredientValueText.trim());    
        }
      } else if(ingredientValue > 1 && ingredientUnit.plural) {
        ingredientValueText = ingredientUnit.plural
                                .replace('$name', ingredient.name)
                                .replace('$plural', ingredient.plural)
                                .replace('$n',ingredientValueText.trim());    
      } else if(ingredientUnit.name) {
        ingredientValueText = ingredientUnit.name
                                .replace('$name', ingredient.name)
                                .replace('$n',ingredientValueText.trim());  
      } else if(ingredient.name){
        ingredientValueText = ingredient.name;
      }
    }
    
    if(ingredient.moreInfo){
      ingredientValueText += ' '+ingredient.moreInfo;
    }

    return ingredientValueText.trim();
  }

const getBestCandidate = (candidates) => {
    let validIndex = -1,
        i = 0,
        lastCandidateLength = 0;
    candidates.forEach(function(candidate) {
        if (candidate.textFound.length > lastCandidateLength) {
            validIndex = i;
            lastCandidateLength = candidate.textFound.length;
        }
        i++;
    })
    return (validIndex === -1) ? null : Object.assign({}, candidates[validIndex].obj,{
        value: parseIngredientValue(candidates[validIndex].value), 
        unit: candidates[validIndex].unit,
        moreInfo: getMoreInfoFromText(candidates[validIndex].text),
    });
}

const getMoreInfoFromText = (text) => {
    let moreInfoRegex = new RegExp(/\(.*\)/g);
    let moreInfo = moreInfoRegex.exec(text);
    return (moreInfo && moreInfo.length > 0) ? moreInfo[0] : null;
}

const getIngredient = (text, firebaseData) => {
    let candidates = [],
        regex, found, unitRegex;
    _.each(firebaseData.ingredients, (ingredient, ingredientName) => {
        ingredient.name = ingredient.name || ingredientName;
        _.each(ingredient.units, (unit) => {
            unitRegex = unit.regex && unit.regex.replace('$n',values.$N_REGEX).replace('$name', ingredient.name);
            if(unit.name) {
                unit.name = unit.name.replace('$name', ingredient.name);    
            }
            regex = new RegExp(unitRegex,'i');
            found = regex.exec(text);
            if (found && found.length > 0) {
                candidates.push({
                    obj: ingredient,
                    value: found.splice(1),
                    unit: unit,
                    textFound: found[0],
                    text: text
                });
            }
        })
    });
    return getBestCandidate(candidates);
}

const parseIngredientValue = (values) => {
    let retValue = 0;
    _.each(values, (value) => {
        if(value){
            let v = value.split('/');
            try{
                value = parseFloat(v[0], 10);
                if (v.length === 2) {
                    value = value / parseFloat(v[1], 10);
                }
                retValue += value;        
            }catch(e){
                console.log('error parsing value:'+value);
            }
        }
    })
    return retValue;
}

const getIngredientNote = (text) => {
    let regex = (new RegExp('nota-\\d+', 'g')),
        match = text.match(regex);
    return match ? match.map((found) => {
        return parseInt(found.substring(5),10)-1;
    }) : [];
}

const getIngredientNutrientCoeficient = (text) => {
    let regex = (new RegExp('coef-\\d+', 'g')),
        match = text.match(regex);
    return (match && match.length > 0) ? (parseInt(match[0].substring(5),10)/100) : 1;
}

const normalizeIngredient = (ingredient: Object, recipe: Object) => {
    if (ingredient.unit) {
        ingredient.gramsValue = ingredient.value * ingredient.unit.grams;
    } else if (ingredient.grams) {
        ingredient.gramsValue = ingredient.value * ingredient.grams.unit;
    }
    return ingredient;
}

export const getScalelessUnit = (ingredient: Object, units: Object) => {
    if(!ingredient.unit) {
      return;
    }
    let equivalence = _.find(ingredient.unit.scaleLess, (equivalence) => {
      if(!ingredient || !ingredient.gramsValue) {
        return false;
      }
      let gramsValue = ingredient.gramsValue * units.value;
      if(gramsValue <= equivalence.gt) {
        return false;
      }
      if(gramsValue > (equivalence.lt || Infinity)) {
        return false;
      }
      let unitName = equivalence.unit;
      if(ingredient.units[unitName]) {
        return true;
      }
      return false;  
    });

    if(equivalence) {
      return ingredient.units[equivalence.unit];  
    }

  }
