/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import AdSense from "react-adsense";
import Image from "../image";
import PostDetailIntroduction from "../../containers/post-detail/introduction";
import PostDetailFooter from "../../containers/post-detail/footer";
import { getPostLink } from "../../utils/link-maker";
import { Link } from "react-router-dom";
import I18n from "../../i18n";

class PostArticleSummary extends Component {
  render() {
    return (
      <article
        key={this.props.slug}
        id="post-14012"
        className="post type-post status-publish format-standard hentry"
      >
        <header className="entry-header">
          <h2 className="entry-title">
            <Link to={getPostLink(this.props)} rel="bookmark">
              {this.props.title}
            </Link>
          </h2>
        </header>
        <div className="entry-content">
          {this._renderBeforeAd()}
          {this._renderImage()}
          <PostDetailIntroduction post={this.props} summary={true} />
          <Link to={getPostLink(this.props)} className="more-link">
            {I18n.t("detail.continueReading")}
            <span className="screen-reader-text">
              {" "}
              {"«" + this.props.title + "»"}
            </span>
          </Link>
        </div>
        <PostDetailFooter post={this.props} />
        {this._renderAfterAd()}
      </article>
    );
  }
  _renderBeforeAd() {
    if (this.props.hasBeforeAd) {
      return (
        <div
          key={`detail-before-ad-${this.props.slug}`}
          className="ads ads_before"
        >
          <AdSense.Google
            style={{ display: "block" }}
            responsive="true"
            client="ca-pub-3496513159625922"
            slot="9806512890"
            format="auto"
          />
        </div>
      );
    }
  }
  _renderAfterAd() {
    if (this.props.hasAfterAd) {
      return (
        <div
          key={`detail-after-ad-${this.props.slug}`}
          className="ads ads_after"
        >
          <AdSense.Google
            style={{ display: "block" }}
            responsive="true"
            client="ca-pub-3496513159625922"
            slot="3480777693"
            format="auto"
          />
        </div>
      );
    }
  }
  _renderImage() {
    if (this.props.figures && this.props.figures.length > 0) {
      let figure = this.props.figures.find((figure) => {
        return figure.main;
      });
      if (figure && figure.path) {
        return (
          <Link to={getPostLink(this.props)} className="more-link">
            <Image path={figure.path} caption={figure.caption} />
          </Link>
        );
      }
    }
  }
}

PostArticleSummary.propTypes = {};

PostArticleSummary.defaultProps = {
  hasAfterAd: false,
  hasBeforeAd: false,
};

export default PostArticleSummary;
