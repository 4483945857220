/* @flow */
/*eslint-disable prefer-const */

import _ from "lodash";
import React, { PureComponent } from "react";
import I18n from "../../i18n";
import { getIngredientInfo } from "../../utils/ingredient-parser";
import EditNotes from "../edit-notes";

class EditIngredients extends PureComponent {
  constructor(props: any) {
    super(props);
    this.ingredientBlock = _.cloneDeep(this.props.ingredientBlock);
    this.state = {
      updateHook: 0,
    };
  }
  render() {
    return (
      <React.Fragment>
        {this._renderTitle(this.ingredientBlock.name)}
        {this._renderIngredients(this.ingredientBlock.list)}
        <button onClick={this._addIngredient} type="button">
          ADD INGREDIENT
        </button>
      </React.Fragment>
    );
  }
  _renderTitle(title) {
    title =
      title === "default" ? I18n.t("detail.sections.ingredients.title") : title;
    return (
      <p>
        <input
          type="text"
          onChange={this._onChange.bind(this, ["name"])}
          defaultValue={title}
          placeholder="Nombre del bloque de ingredientes"
        />
      </p>
    );
  }
  _renderIngredients(list) {
    return <ul>{list.map(this._renderIngredient.bind(this))}</ul>;
  }
  _renderIngredient(ingredient, i) {
    return (
      <li key={`${i}-${this.ingredientBlock.list.length}`}>
        <form
          style={{ margin: "10px 0px", padding: "10px", border: "1px dashed" }}
        >
          {this._renderIngredientsInput(ingredient, i)}
          <div>
            <label
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              Coeficiente para nutrición:
              <input
                style={{ width: "auto" }}
                type="number"
                onChange={this._onChange.bind(this, [
                  "list",
                  i,
                  "nutrientCoeficient",
                ])}
                defaultValue={ingredient.nutrientCoeficient}
                placeholder="Coeficiente"
              />
            </label>
          </div>
          {this._renderIngredientNotes(ingredient, i)}
          <button
            onClick={() => {
              this._removeIngredient(ingredient, i);
            }}
            type="button"
          >
            REMOVE INGREDIENT
          </button>
        </form>
      </li>
    );
  }
  _renderIngredientNotes(ingredient, index) {
    return (
      <EditNotes
        notes={ingredient.notes}
        onChange={(notes) => {
          this._onChange(
            ["list", index, "notes"],
            {
              currentTarget: {
                value: notes,
              },
            },
            false
          );
        }}
      />
    );
  }
  _renderIngredientsInput(ingredientSelected, index) {
    return (
      <div>
        <input
          style={
            ingredientSelected.name
              ? { borderColor: "green" }
              : { borderColor: "red" }
          }
          type="text"
          defaultValue={
            ingredientSelected.processedText || ingredientSelected.text
          }
          onChange={({ currentTarget }) => {
            let info = getIngredientInfo(currentTarget.value, this.props.post, {
              ingredients: _.cloneDeep(this.props.ingredients),
            });
            if (!info.name) {
              info = {
                text: currentTarget.value,
              };
            }
            info.text = currentTarget.value;
            info.nutrientCoeficient = _.get(
              this.ingredientBlock,
              ["list", index, "nutrientCoeficient"],
              1
            );
            info.notes = info.notes || [];
            info.notes = [
              ...info.notes,
              ..._.get(this.ingredientBlock, ["list", index, "notes"], []),
            ];
            let ingredientBlock = _.cloneDeep(this.ingredientBlock);
            ingredientBlock = _.set(ingredientBlock, ["list", index], info);
            this._updateIngredientBlock(ingredientBlock, true);
          }}
        />
      </div>
    );
  }
  _renderIngredientsSelector(ingredientSelected, index) {
    let ingredient;
    return (
      <div>
        <label
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          Ingrediente:
          <select
            value={ingredientSelected.name}
            onChange={({ currentTarget }) => {
              let foundKey = Object.keys(this.props.ingredients).find(
                (ingredientKey) => {
                  ingredient = this.props.ingredients[ingredientKey];
                  return ingredient.name === currentTarget.value;
                }
              );
              let newIngredient = { ...this.props.ingredients[foundKey] };
              newIngredient.unit = newIngredient.units["none"];
              this._onChange(
                ["list", index],
                {
                  currentTarget: {
                    value: newIngredient,
                  },
                },
                true
              );
            }}
          >
            {Object.keys(this.props.ingredients).map((key) => {
              ingredient = this.props.ingredients[key];
              return (
                <option value={ingredient.name} key={key}>
                  {ingredient.name}
                </option>
              );
            })}
          </select>
        </label>
      </div>
    );
  }
  _renderUnitsSelector(ingredientSelected, index) {
    let unit;
    return (
      <div>
        <label
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          Unidad:
          <select
            value={_.get(ingredientSelected, ["unit", "name"])}
            onChange={({ currentTarget }) => {
              let foundKey = Object.keys(ingredientSelected.units).find(
                (unitKey) => {
                  unit = ingredientSelected.units[unitKey];
                  return unit.name === currentTarget.value;
                }
              );
              this._onChange(
                ["list", index, "unit"],
                {
                  currentTarget: {
                    value: ingredientSelected.units[foundKey],
                  },
                },
                true
              );
            }}
          >
            {Object.keys(ingredientSelected.units || []).map((key) => {
              unit = ingredientSelected.units[key];
              return (
                <option value={unit.name} key={key}>
                  {unit.name}
                </option>
              );
            })}
          </select>
        </label>
      </div>
    );
  }
  _addIngredient = () => {
    let ingredientBlock = _.cloneDeep(this.ingredientBlock);
    ingredientBlock.list.push({});
    this._updateIngredientBlock(ingredientBlock, true);
  };
  _removeIngredient = (ingredient, i) => {
    let ingredientBlock = _.cloneDeep(this.ingredientBlock);
    ingredientBlock.list.splice(i, 1);
    this._updateIngredientBlock(ingredientBlock, true);
  };
  _onChange(path, { currentTarget }, forceRender = false) {
    let ingredientBlock = _.cloneDeep(this.ingredientBlock);
    ingredientBlock = _.set(ingredientBlock, path, currentTarget.value);
    this._updateIngredientBlock(ingredientBlock, forceRender);
  }
  _updateIngredientBlock(ingredientBlock, forceRender = false) {
    this.ingredientBlock = ingredientBlock;
    console.log(this.ingredientBlock);
    this.props.onChange(ingredientBlock);
    if (forceRender) {
      console.log("cambio");
      this.setState({
        updateHook: this.state.updateHook + 1,
      });
    }
  }
}

EditIngredients.propTypes = {};

EditIngredients.defaultProps = {
  onChange: () => {},
};

export default EditIngredients;
