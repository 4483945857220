/* @flow */

import React, { Component } from "react";
import { Provider } from "react-redux";
import configureStore from "../../store/configure-store";
import Scene from "../scene";
import i18n from "../../i18n";
import { BrowserRouter as Router } from "react-router-dom";
import loadable from "@loadable/component";

import DetailScreen from "../../screens/detail";
import SearcherScreen from "../../screens/searcher";
import HomeScreen from "../../screens/home";
import NotFoundScreen from "../../screens/not-found";
const SignInScreen = loadable(() => import("../../screens/sign-in"));

const preloadedState = window.__PRELOADED_STATE__;

try {
  i18n.setAppLanguage(window.__PRELOADED_STATE__.settings.language);
} catch (e) {}

delete window.__PRELOADED_STATE__;

const store = configureStore(preloadedState);

class Root extends Component {
  componentDidMount() {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Scene
            screens={{
              detail: DetailScreen,
              searcher: SearcherScreen,
              home: HomeScreen,
              notFound: NotFoundScreen,
              signIn: SignInScreen,
            }}
          />
        </Router>
      </Provider>
    );
  }
}

export default Root;
