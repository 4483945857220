/* @flow */
/*eslint-disable prefer-const */

import Filter from '../../containers/filter';
import Categories from '../../containers/categories';
import React, { Component } from 'react';
import LastRecipesList from '../../containers/last-recipes-list';

class Menu extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return <aside id="secondary" className="sidebar widget-area" role="complementary">
            <Categories/>
            {/* <Filter/> */}
            <LastRecipesList/>
          </aside>
  }
}

Menu.propTypes = {
  
};

Menu.defaultProps = {
};

export default Menu;