import _ from 'lodash';
import I18n from "../i18n";

const fractions = [{
  value: 0,
  text: null
},{
  value: 1/2,
  text:'1/2'
}, {
  value: 1/3,
  text: '1/3'
}, {
  value: 2/3,
  text: '2/3'
}, {
  value: 1/4,
  text: '1/4'
}, {
  value: 3/4,
  text: '3/4'
}, {
  value: 1/8,
  text: '1/8'
}];

export const fractionalize = _.memoize((value) => {
    let valueText = '',
        valueFractionText = [],
        valueInt,
        restValue;
    if(value !== Infinity){
      value = value.valueOf();
      value = Math.round(value * 100000) / 100000;
      if(value < 10){
        valueInt = Math.floor(value);
        restValue = value - valueInt;
        var previousValue, nextValue;
        let fraction = _.reduce(fractions, function(previous, next){
          previousValue = Math.abs(previous.value - restValue);
          nextValue = Math.abs(next.value - restValue);
          return (nextValue < previousValue) ? next : previous;
        },fractions[0]);

        if(valueInt > 0){
          valueFractionText.push(valueInt);
        }
        if(fraction.text){
          valueFractionText.push(fraction.text);
        }
        if(valueFractionText.length === 1) {
          valueText = String(valueFractionText[0]);
        } else if(valueFractionText.length > 1) {
          valueText = I18n.t('ingredient.fraction', {
            whole: valueFractionText[0],
            fraction: valueFractionText[1]
          });
        } else {
          valueText = '';
        }
      }else{
        valueText = Math.round(value.valueOf())+'';
      }
    }

    return valueText.trim();
  });