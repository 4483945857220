/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from "react";
import _ from "lodash";

class EditTagsAndCategories extends PureComponent {
  constructor(props: any) {
    super(props);
    this.data = _.cloneDeep(this.props.post);
    this.state = {
      updateHook: 0,
    };
  }
  render() {
    return (
      <React.Fragment>
        <input
          type="text"
          onChange={this._onChange.bind(this, ['categories'])}
          defaultValue={this.props.post.categories.join(",")}
          placeholder="Comma separated list of categories"
        />
        <input
          type="text"
          onChange={this._onChange.bind(this, ['tags'])}
          defaultValue={this.props.post.tags.join(",")}
          placeholder="Comma separated list of tags"
        />
      </React.Fragment>
    );
  }
  _onChange = (path,{ currentTarget }, forceRender = false) => {
    let data = _.cloneDeep(this.data);
    let tags = _.map(currentTarget.value.split(","), (tag) => tag.trim());
    _.set(data, path, tags)
    this._updateTags(data, forceRender);
  };
  _updateTags(data, forceRender = false) {
    this.data = data;
    this.props.onChange(data);
    if (forceRender) {
      this.setState({
        updateHook: this.state.updateHook + 1,
      });
    }
  }
}

EditTagsAndCategories.propTypes = {};

EditTagsAndCategories.defaultProps = {
  onChange: () => {},
};

export default EditTagsAndCategories;
