/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { showPopOver, navigate } from "../../actions";
import { updateSummary } from "../../actions/edit";
import I18n from "../../i18n";
import { connect } from "react-redux";
import EditButton from "../../components/edit-button";
import EditSummary from "../../components/edit-summary";

import { Text, View } from "../../platform";

class PostDetailIntroduction extends Component {
  constructor(props: any) {
    super(props);
    this.summaryChanged = null;
  }
  render() {
    return (
      <EditButton
        tagName="div"
        active={this.props.isAdmin}
        editComponent={
          <EditSummary
            allUnits={this.props.allUnits}
            post={this.props.post}
            onChange={(nextSummary) => {
              this.summaryChanged = nextSummary;
            }}
          />
        }
        onSavePress={() => {
          if (this.summaryChanged) {
            this.props.dispatch(
              updateSummary(
                {
                  path: `/posts/${this.props.post.slug}`,
                  ...this.props.post,
                },
                this.summaryChanged
              )
            );
          }
        }}
        onDeletePress={() => {}}
        readComponent={this._renderSummary()}
        style={{ paddingBottom: "10px" }}
      ></EditButton>
    );
  }
  _renderSummary() {
    return (
      <React.Fragment>
        <table>
          <tbody>
            <tr>
              <td>
                {I18n.t("detail.sections.preparation.summary.difficultRate")}
              </td>
              <td>
                {I18n.t("detail.sections.preparation.summary.preparationTime")}
              </td>
              <td>{I18n.t("detail.sections.preparation.summary.portion")}</td>
            </tr>
            <tr>
              <td>{this.props.post.summary.difficult}</td>
              <td>
                {this.formatTime(this.props.post.summary.time_minutes_cooking)}
              </td>
              <td>{this.props.post.unitName}</td>
            </tr>
          </tbody>
        </table>
        {this._renderTimeAnnotation()}
      </React.Fragment>
    );
  }
  _renderTimeAnnotation() {
    if (this.props.post.summary.time_annotation) {
      return <p>{this.props.post.summary.time_annotation}</p>;
    }
  }
  formatTime(time) {
    let timeArray = [],
      hours,
      minutes;
    hours = Math.floor(time / 60);
    time = new Date(time * 60 * 1000);
    minutes = time.getUTCMinutes();
    if (hours) {
      timeArray.push(hours + " h.");
    }
    if (minutes) {
      timeArray.push(minutes + " m.");
    }
    return timeArray.join(" y ");
  }
}

PostDetailIntroduction.propTypes = {
  dispatch: PropTypes.func,
};

PostDetailIntroduction.defaultProps = {
  dispatch: () => {},
  isAdmin: false,
};

export default connect((state, props) => {
  return {
    allUnits: state.firebase.units,
    isAdmin: state.user.admin,
    introduction: props.post.introduction,
    introductionVO: props.post.__vo ? props.post.__vo.introduction : null,
    orientation: state.screen.orientation,
    screenSize: state.screen.size,
    enableImproveTranslations:
      props.post.__vo && state.settings.enableImproveTranslations,
  };
})(PostDetailIntroduction);
