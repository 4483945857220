/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import I18n from "../../i18n";
import * as colors from "../../constants/colors";
import _ from "lodash";

class PostDetailNotes extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    let notes = [];
    _.each(this.props.post.ingredients, (ingredientBlock) => {
      _.each(ingredientBlock.list, (ingredient) => {
        _.each(ingredient.notes, (note) => {
          notes.push(note);
        });
      });
    });
    _.each(this.props.post.preparation, (step) => {
      _.each(step.notes, (note) => {
        notes.push(note);
      });
    });
    if (notes.length === 0) {
      return null;
    }
    return (
      <React.Fragment>
        {this._renderTitle()}
        {this._renderList(notes)}
      </React.Fragment>
    );
  }
  _renderTitle() {
    return (
      <p>
        <strong>{I18n.t("detail.sections.notes.title")}</strong>
      </p>
    );
  }
  _renderList(list) {
    return <ul>{_.compact(list.map(this._renderItem.bind(this)))}</ul>;
  }
  _renderItem(item, i) {
    if (item.msgType !== 'advice' || !item.text) {
      return;
    }
    return <li key={i}>{renderHTML(item.text)}</li>;
  }
}

PostDetailNotes.propTypes = {
  dispatch: PropTypes.func,
};

PostDetailNotes.defaultProps = {
  dispatch: () => {},
};

export default connect((state, props) => {
  return {};
})(PostDetailNotes);
