/* @flow */
/*eslint-disable prefer-const */

import React, { Component, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { getLinkForPathAndSearch } from "../../utils/link-maker";
import I18n from "../../i18n";
import SearchBox from "../../containers/search-box";
import Filters from "../../containers/filters";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: 76,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      paddingTop: "7px",
    },
  },
  filters: {
    marginRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.appBar - 1,
    color: "#fff",
  },
  logoContainer: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    minWidth: "40px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "5px",
    }
  },
  logoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
}));

function Header() {
  const location = useLocation();
  const classes = useStyles();
  let [filtersOpen, setFiltersOpen] = useState(false);
  useEffect(() => {
    setFiltersOpen(false);
  }, [location]);
  return (
    <div
      className={`${classes.root} container-style-definition material-style`}
    >
      <AppBar position="fixed">
        <Toolbar>
          <Box className={classes.logoContainer}>
            <Link
              component={RouterLink}
              to={getLinkForPathAndSearch({
                path: "",
              })}
              className={classes.logoWrapper}
              title={"Vegamecum"}
            >
              <img
                width={56}
                height={56}
                src="/logo_vegamecum_normal.svg"
                className={classes.logo}
                alt="Vegamecum"
              />
              <Typography
                color="secondary"
                className={classes.title}
                variant="h6"
                noWrap
              >
                Vegamecum
              </Typography>
            </Link>
          </Box>
          <Button
            onClick={() => setFiltersOpen(!filtersOpen)}
            color="secondary"
            size="medium"
            endIcon={
              filtersOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
            }
          >
            {I18n.t("sideBar.filters")}
          </Button>
          <SearchBox />
        </Toolbar>
        <Filters open={filtersOpen} />
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={filtersOpen}
        onClick={() => setFiltersOpen(!filtersOpen)}
      >
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
    </div>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
