/* @flow */

import * as types from "../actions";
import produce from "immer";

const data = (state = {
	list: [],
	autoplay: false,
    beautifiers: [],
}, action) => produce(state, draft => {
    switch (action.type) {
    	case types.RECEIVE_PLAYLIST:
            draft.list = action.data;
            return;
        case types.SET_PLAYLIST_AUTOPLAY:
            draft.autoplay = action.data;
            return;    
        case types.RECEIVE_TEXT_SPEECH_BEAUTIFIERS:
            draft.beautifiers = action.data;
            return;
    }
});

export default data;
