import { connect } from "react-redux";
import Image from "../../components/image";
import { updatePostFigure, uploadImageFile } from "../../actions/edit";
import { bindActionCreators } from "redux";

function mapStateToProps(state, props) {
  return {
    isAdmin: state.user.admin,
    enableImproveTranslations: props.post.__vo && state.user.admin, //state.settings.enableImproveTranslations,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updatePostFigure, uploadImageFile }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Image);
