/* @flow */

import * as globalTypes from "../actions";
import * as types from "../actions/current-recipes";
import { combineReducers } from "redux";
import _ from 'lodash';
import produce from "immer";

const all = (state: Object = {}, action) => produce(state, draft => {
    switch (action.type) {
        case globalTypes.RECEIVE_WORKING_POST:
            let key = action.data.slug;
            let post = action.data.post;
            if(post && post.slug) {
                draft[key] = action.data.post;
            } else {
                delete draft[key];
            }
            return;
    }
});

const list = (state: Object = [], action) => produce(state, draft => {
    switch (action.type) {
        case globalTypes.RECEIVE_WORKING_POST:
            let key = action.data.slug;
            let post = action.data.post;
            let index = state.findIndex((p) => {
                return p.slug === key;
            });
            if(post && post.slug) {
                if(index === -1) {
                    index = state.findIndex((p) => {
                        return post.time > p.time;
                    });
                    if(index === -1) {
                        draft.push(post);
                    } else {
                        draft.splice(index, 0, post);
                    }
                }
            } else if (index !== -1){
                draft.splice(index, 1);
            }
            return;
    }
});

const show = (state = false, action) => produce(state, draft => {
    switch (action.type) {
        case types.SHOW:
            return true;
        case types.HIDE:
            return false;
    }
});

export default combineReducers({
    show,
    all,
    list
})