import _ from "lodash";
import { XMLSerializer } from "xmldom";
const Serializer = new XMLSerializer().serializeToString;

export const getJSONLD = (recipe, pageProps) => {
  let time = {};
  if (recipe.summary.time_minutes_cooking === recipe.summary.time_minutes) {
    time.totalTime = time.cookTime = time.prepTime = minuteToPT(
      recipe.summary.time_minutes
    );
  } else {
    time.totalTime = minuteToPT(recipe.summary.time_minutes);
    time.cookTime = minuteToPT(recipe.summary.time_minutes_cooking);
    time.prepTime = minuteToPT(
      recipe.summary.time_minutes - recipe.summary.time_minutes_cooking
    );
  }
  let ingredients = [];
  _.each(recipe.ingredients, (ingredientBlock) => {
    _.each(ingredientBlock.list, (ingredient) => {
      ingredients.push(ingredient.processedText);
    });
  });

  let preparation = _.map(recipe.preparation, (step) => {
    return {
      "@type": "HowToStep",
      name: step.title,
      text: step.text,
    };
  });

  let mainImage = null;
  let mainCaption = null;
  if (recipe.figures) {
    let mainImage = recipe.figures.find((figure) => {
      return figure.main;
    });
    if (mainImage) {
      mainImage = mainImage.path;
      mainCaption = mainImage.caption;
    }
  }
  if (!mainImage) {
    mainImage = recipe.mainImage;
  }

  let description = recipe.introduction;
  let allNutrients = _.get(recipe, "nutrients.all", {});
  let data = {
    "@context": "http://schema.org/",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://vegamecum.com/#organization",
        name: "Vegamecum",
        url: "https://vegamecum.com/",
        sameAs: [],
        logo: {
          "@type": "ImageObject",
          "@id": "https://vegamecum.com/#logo",
          inLanguage: pageProps.language,
          url: "https://cdn.vegamecum.com/image/width=540/logo.png",
          width: 540,
          height: 420,
          caption: "Vegamecum",
        },
        image: { "@id": "https://vegamecum.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://vegamecum.com/#website",
        url: "https://vegamecum.com/",
        name: "Vegamecum",
        description: "Recetas veganas",
        publisher: { "@id": "https://vegamecum.com/#organization" },
        potentialAction: [
          {
            "@type": "SearchAction",
            target: `https://vegamecum.com/${pageProps.language}/?s={search_term_string}`,
            "query-input": "required name=search_term_string",
          },
        ],
        inLanguage: pageProps.language,
      },
      {
        "@type": "ImageObject",
        "@id": `${pageProps.link}#primaryimage`,
        inLanguage: pageProps.language,
        url: mainImage,
        caption: mainCaption,
      },
      {
        "@type": "WebPage",
        "@id": `${pageProps.link}#webpage`,
        url: `${pageProps.link}`,
        name: pageProps.title,
        isPartOf: { "@id": "https://vegamecum.com/#website" },
        primaryImageOfPage: {
          "@id": `${pageProps.link}#primaryimage`,
        },
        datePublished: recipe.date,
        description: pageProps.description,
        inLanguage: pageProps.language,
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [`${pageProps.link}`],
          },
        ],
      },
      {
        "@type": "Recipe",
        mainEntityOfPage: pageProps.link,
        name: recipe.title,
        image: mainImage,
        author: {
          "@type": "Person",
          name: "Vegamecum",
        },
        datePublished: recipe.date,
        description,
        /*
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5.0",
      reviewCount: "0",
    },
    */
        ...time,
        recipeYield: recipe.summary.units.type.plural.replace(
          "$n",
          recipe.summary.units.value
        ),
        nutrition: {
          "@type": "NutritionInformation",
          servingSize: recipe.summary.units.type.name.replace("$n", 1),
          calories: Math.round(allNutrients["Energy"]) + " kcal.",
          carbohydrateContent:
            allNutrients["Carbohydrate, by difference"] + " gr.",
          cholesterolContent: allNutrients["Cholesterol"] + " gr.",
          fatContent: allNutrients["Total lipid (fat)"] + " gr.",
          fiberContent: allNutrients["Fiber, total dietary"] + " gr.",
          proteinContent: allNutrients["Protein"] + " gr.",
          saturatedFatContent:
            allNutrients["Fatty acids, total saturated"] + " gr.",
          sodiumContent: allNutrients["Sodium, Na"] + " gr.",
          sugarContent: allNutrients["Sugars, total"] + " gr.",
          transFatContent: "0 gr.",
          unsaturatedFatContent:
            allNutrients["Fatty acids, total polyunsaturated"] +
            allNutrients["Fatty acids, total monounsaturated"] +
            " gr.",
        },
        recipeIngredient: ingredients,
        recipeInstructions: preparation,
        keywords: recipe.tags,
        recipeCategory: recipe.categories,
        publisher: { "@id": "https://vegamecum.com/#organization" },
      },
    ],
  };
  return data;
};

const minuteToPT = (min) => {
  let hours = Math.floor(min / 60),
    minutes = min % 60,
    result = "PT";
  if (hours > 0) {
    result += hours + "H";
  }
  result += minutes + "M";
  return result;
};
