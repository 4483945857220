/* @flow */

import * as types from "../actions";
import { getFlattenIngredients } from '../utils/cart';
import _ from 'lodash';
import produce from "immer";

const data = (state = {
	list: [],
	items: [],
	recipe: {},
    isEmpty: true,
    showSelected: false,
	planifiedDate: null
}, action) => produce(state, draft => {
	let index;
    switch (action.type) {
        case types.LOADED_PLANIFIED:
			let date = action.data ? new Date(action.data.time) : null;
			draft.planifiedDate = date;
			draft.recipe = draft.recipe || {};
			draft.recipe.planifiedDate = date;
			return;
        case types.TOGGLE_CART_ELEMENTS_SELECTED_VISIBILITY:
			draft.showSelected = !state.showSelected;
			return;
    	case types.ADD_ELEMENT_TO_CART:
		case types.REMOVE_ELEMENT_FROM_CART:
			index = state.list.findIndex((item) => {
				return item.key === action.data.key;
			});

            if(action.type === types.ADD_ELEMENT_TO_CART) {
	            if(index === -1) {
	            	draft.list.push(action.data);	
	            } else {
	            	draft.list.splice(index, 1, action.data);
	            } 
        	} else {
        		if(index !== -1) {
        			draft.list.splice(index, 1);
        		}
			}
			draft.items = getFlattenIngredients(draft.list);
			draft.isEmpty = draft.items.length === 0;
			draft.recipe = _.get(draft.list, '0.recipe') || {};
			draft.recipe = produce(draft.recipe, (recipeDraft) => {
				recipeDraft.planifiedDate = state.planifiedDate;
			});
    }
});

export default data;
