export default {
  "about": {
    "aboutUs": "About us",
    "account": "Account",
    "adWithUs": "Advertise",
    "adWithUsText": "Advertise with us",
    "contactUs": "Contact us",
    "findUsOnNet": "You will find us on most popular social media sites",
    "followUs": "Follow us",
    "licenses": {
      "icons": {
        "description": "The icons {icons} have been designed by Freepik for Flaticons",
        "title": "Freepik icons"
      },
      "sound": {
        "keys": "The sound effect of keys has been made by Mark DiAngelo and can be acquired at {link}",
        "success": "The sound effect of \"recipe finished\" has been made by Mike Koenig and can be acquired in {link}",
        "title": "Sound effects"
      },
      "title": "Licenses"
    },
    "privacy": {
      "title": "Privacy Policy",
      "url": "https://vegamecum.com/app/privacy/"
    },
    "rate": "Rate us",
    "rateOnPlatform": "Rate us on {{platform}}",
    "rateUs": "Do you like the app? Please rate us",
    "subtitle": "vegan recipes",
    "suggestionMail": "Do you have any suggestions or want to tell us something? You can contact us by email",
    "supportText": "Help Vegamecum move forward",
    "supportUs": "Support us",
    "terms": "Legal terms",
    "termsOfUs": {
      "ads": {
        "description": "Advertising shown in the application comes from third parties. Although Vegamecum puts all available measures for your selection, Vegamecum is not responsible for the content shown by these companies.",
        "title": "Advertising"
      },
      "advise": "PLEASE READ THIS DOCUMENT CAREFULLY BEFORE USING THE APPLICATION. BY USING THE APPLICATION, YOU ARE ACCEPTING THE TERMS AND CONDITIONS THAT ARE SHOWN BELOW. THESE CONDITIONS ARE SUBJECT TO CHANGE. YOU AGREE TO REVISE PERIODICALLY TO BE CURRENTLY MODIFIED.",
      "conditions": {
        "description": "The nutrition facts shown in this application are based on an approximate calculation based on the data collected in the USDA (United States Department of Agriculture) and the nutritional tables provided by the producers themselves. {Linebreak} The information shown is merely informative and in no case it does replace the medical advice. If you have questions about your diet, consult a nutritionist.",
        "title": "Terms of use"
      },
      "content": {
        "description": "Vegamecum is the owner of the {logo} logo, the application and all the content displayed on it. {linebreak} All the content shown in the application (both recipes and photographs) is under the Creative Common by-sa license.",
        "title": "The content"
      },
      "thanks": "Thank you for using the Vegamecum app!",
      "title": "Terms of use"
    },
    "title": "About this App"
  },
  "alarm": {
    "cancel": "Cancel",
    "return": "Return"
  },
  "alarms": {
    "empty": "No scheduled alarms",
    "title": "Alarms"
  },
  "calendar": {
    "add": "Add",
    "days": {
      "names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "shorts": [
        "Sun.",
        "Mon.",
        "Tue.",
        "Wed.",
        "Thu.",
        "Fri.",
        "Sat."
      ]
    },
    "months": {
      "names": [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "shorts": [
        "Jan.",
        "Feb.",
        "Mar.",
        "Apr.",
        "May",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dec."
      ]
    },
    "planification": {
      "cancel": "CANCEL",
      "date": "{{day}} {{number}} {{month}}",
      "delete": "REMOVE",
      "end": "End",
      "eventName": "Event name",
      "format": {
        "hour": "{{hour}} hours",
        "join": " and ",
        "minute": "{{minute}} min.",
        "whole": "{{hour}} and {{minute}}"
      },
      "save": "SAVE",
      "select": {
        "duration": "Adjust the duration",
        "end": "Select start time",
        "start": "Select end time"
      },
      "start": "Start",
      "title": "Do you want to add an event to your calendar?",
      "totalTime": "Total time"
    },
    "planificationTitle": "Scheduling",
    "title": "My calendar"
  },
  "cartlist": {
    "add": "Add item",
    "complete": "Full list",
    "elementsMarked": "{{count}} items purchased",
    "empty": {
      "description": "Touch on the recipe's ingredients to add them or add them manually",
      "title": "Your shopping list is empty"
    },
    "general": "General Purchase",
    "planification": {
      "date": "{{day}} {{number}} {{month}}",
      "notPlanified": "Not scheduled"
    },
    "selectOptions": {
      "deleteAll": "Remove all items",
      "deleteMarked": "Remove marked items",
      "markAll": "Mark all items",
      "title": "More options",
      "unMarkAll": "Unmark all items"
    },
    "share": {
      "title": "Shopping list"
    },
    "title": "My shopping list"
  },
  "chat": {
    "bannedMessage": "Sorry, you can't write comments. To resolve this issue, send an email to vegamecum@gmail.com.",
    "deletedMessage": "Message deleted",
    "emptyMessageList": "This recipe has no comments yet",
    "loadEarlier": "Load more",
    "loginMessage": "Login to comment",
    "loginSmallMessage": "Login",
    "moreOptions": {
      "banUser": "Ban user {{id}}",
      "quoteMessage": "Quote message",
      "removeMessage": "Delete message",
      "removeMessageAndReplies": "Delete message and replies",
      "title": "More options"
    },
    "placeholder": "Enter a text ...",
    "reply": "Reply",
    "sendText": "Send",
    "title": "Comments"
  },
  "commentsEditor": {
    "description": "Share photos and tips about this recipe",
    "pictures": {
      "title": "Recipe photos"
    },
    "save": "Send",
    "text": {
      "placeholder": "Enter a text ...",
      "title": "Comment"
    },
    "textReply": {
      "placeholder": "Enter your reply ...",
      "title": "Reply"
    },
    "title": "New comment",
    "titleReply": "Replying to {{name}}"
  },
  "config": {
    "account": {
      "close": "Sign off",
      "config": "Set up account",
      "identify": "Sign in",
      "signInText": "Login with your user account or create a new one",
      "title": "My account",
      "userAccount": "User account"
    },
    "calendar": {
      "sync": {
        "description": "Enabling this feature, all the scheduled recipes will be added to the device calendar",
        "mobile": "Synchronize with the mobile calendar",
        "tablet": "Synchronize with the tablet calendar"
      },
      "title": "Calendar"
    },
    "language": {
      "improveTranslations": "Improve translations",
      "improveTranslationsAdvice": "Help us to improve the translations. By activating this functionality you can propose alternative texts when the {icon} icon appears.",
      "title": "Language",
      "ui": "Application language"
    },
    "link": {
      "description": "Enter your email and password to identify yourself",
      "destroy": {
        "confirm": {
          "button": "REMOVE",
          "text": "Are you sure you want to delete your account?",
          "textWithPassword": "Are you sure you want to delete your account? Enter your password to delete it",
          "title": "Delete account"
        },
        "description": "From here you can delete your personal Vegamecum account. Once deleted, you will not be able to recover your data.",
        "title": "Remove"
      },
      "email": "Email",
      "error": {
        "accept": "Accept",
        "email-already-in-use": "The email entered is already registered",
        "facebook-login-error": "There was an error in the registration with Facebook",
        "invalid-data": "Enter your email and password",
        "invalid-email": "Invalid email",
        "invalid-modify-data": "Enter your current password to update your data",
        "invalid-register-data": "Make sure all fields are filled",
        "user-not-found": "Incorrect email or password",
        "weak-password": "The password must have a minimum of 6 characters",
        "wrong-email-password": "Incorrect email or password",
        "wrong-password": "The password entered is incorrect"
      },
      "login": {
        "successfully": "Hello again, {{name}}!",
        "welcome": "Welcome {{name}}!"
      },
      "modified": {
        "successfully": "Your data has been updated successfully"
      },
      "newPassword": "New Password",
      "oldPassword": "Current password",
      "password": "Password",
      "register": {
        "acceptConditionsDescription": "<p> By identifying yourself, you accept our terms of use. Check our <a href=\"private\">privacy notice</a> . </p>",
        "description": "Still not registered? By registering, you can share your account among different devices and keep your favorites, calendar and all your preferences",
        "done": "Done!",
        "facebook": "Or sign in with your Facebook account",
        "instructions": "Fill in the following fields to register",
        "title": "Sign up"
      },
      "signIn": "Get in",
      "title": "User account",
      "update": "Update",
      "updateFields": "Update your data by editing the following fields",
      "userName": "Username"
    },
    "notification": {
      "alarm": "Scheduled recipe alarm",
      "recipe": "New recipe available",
      "title": "Notifications"
    },
    "sound": {
      "alarms": "Alarms enabled",
      "fx": "Effects enabled",
      "title": "Sound"
    },
    "title": "Configuration",
    "visualization": {
      "fontSize": {
        "description": "Adjust the font size to see the recipe in the most comfortable way",
        "title": "Font size"
      },
      "rotation": "Rotation enabled",
      "scale": {
        "description": "Enabling this feature, the ingredients will be shown in units that allow you to do the recipes without a scale",
        "title": "Without scale (beta)"
      },
      "theme": {
        "list": {
          "dark": "dark",
          "light": "white (by default)"
        },
        "title": "Graphic theme"
      },
      "title": "Display options"
    }
  },
  "configureLanguage": {
    "continue": "CONTINUE",
    "improveTranslationsAdvice": "Vegamecum recipes are mostly translated automatically from Spanish, so they may contain some errors. Activate the reviewer mode to be able to improve the translations.",
    "language": "Language",
    "previous": "PREVIOUS",
    "reviewerMode": "Reviewer mode",
    "selectLanguage": "Select the language in which you want to see the content of Vegamecum",
    "thanks": "Thanks for joining the group of reviewers! You can improve the translation by clicking on the icon {icon} that will appear next to the texts"
  },
  "currentRecipes": {
    "add": "Add",
    "empty": "You have no recipe in your history",
    "title": "Direct access"
  },
  "detail": {
    "ads": {
      "close": "Now you can turn off advertising throughout the app! <br/> <a href=\\\"https://vegamecum.com/app/extras\\\">Silence Advertising</a>",
      "label": "Advertising",
      "text": "Advertising helps us cover a minimum part of the operating costs. You can help Vegamecum go ahead by making a donation <a href=\"https://vegamecum.com/app/donate\">Link</a>"
    },
    "comments": {
      "shareWithOthers": "Add comment",
      "showMoreComments": "See more",
      "title": "Comments"
    },
    "continueReading": "Continue reading",
    "engagement": {
      "share": "Do you join us on social networks? You will find us on Facebook, Twitter, Instagram and Pinterest!",
      "shareTitle": "Share this:",
      "text": "If you have any questions, you can leave it to us in the comments. And if you dare and want to show your creations to the world, do not hesitate to label us, we will love to see them through the networks!"
    },
    "error": "Could not load recipe Please try again later",
    "finished": {
      "close": "Close",
      "congratulation": "Congratulations!!",
      "share": "Share",
      "text": "You have completed the recipe"
    },
    "loading": "Loading",
    "loadingStatus": {
      "0": "Looking for a recipe",
      "1": "Let's go for your recipe",
      "2": "Let's go for your recipe",
      "3": "Let's go for your recipe",
      "4": "We are going to review the texts",
      "5": "We are finishing",
      "6": "Recipe loaded",
      "-999": "Unknown status",
      "-1": "Looking for a recipe"
    },
    "menu": {
      "delete": "Remove",
      "delete-shortcut": "Remove quick access",
      "deleteConfirm": {
        "button": "REMOVE",
        "text": "Are you sure you want to delete your recipe?",
        "title": "Delete recipe"
      },
      "diet": "Add to my weekly meal plan",
      "disableVO": "Translate recipe",
      "edit": "Edit Recipe",
      "enableVO": "See O.V.",
      "listen": "Listen the recipe",
      "planify": "Schedule the recipe",
      "share": "Share",
      "shortcut": "Create Shortcut"
    },
    "related": {
      "categories": "Categories",
      "category": "In {{category}}",
      "categoryList": {
        "básicos": "Basic",
        "carnes vegetales": "Vegetable meats",
        "cocina italiana": "Italian cuisine",
        "dulces y postres": "Sweets and Desserts",
        "ensalada": "Salad",
        "entrantes": "Starters",
        "fiestas": "Parties",
        "otros": "Others",
        "patés y untables": "Pate and spreads",
        "plato único": "Single plate",
        "primeros": "First",
        "quesos vegetales": "Vegan cheese",
        "salsas": "Sauces",
        "segundos": "Seconds",
        "sin gluten": "Gluten free"
      },
      "emptyCategory": "No more recipes found in this category",
      "emptyTag": "No more recipes have been found with this same label",
      "list": "Related",
      "next": "Following",
      "previous": "Previous",
      "sameCategory": "Others in the category: {name}",
      "sameTag": "Others with tags: {name}",
      "tags": "Tags"
    },
    "sections": {
      "comments": {
        "title": "Comments"
      },
      "ingredients": {
        "empty": "Without unit",
        "emptyList": "No ingredients",
        "extra": {
          "allergens": {
            "gluten": "Gluten",
            "nuts": "Nuts",
            "soy": "Soy",
            "title": "Identify possible allergens when the following icons appear"
          },
          "cartlist": "Tap on the ingredients to add or remove them from the shopping list",
          "notes": "Touch {logo} to see notes or tricks",
          "servings": "Adjust the quantities with the {removeIcon} and {addIcon} buttons"
        },
        "recipeSelected": {
          "addToCart": "Add to shopping list",
          "doByYourself": "Do it yourself",
          "title": "For this ingredient you have two options"
        },
        "title": "Ingredients"
      },
      "introduction": {
        "edit": "Edit Description",
        "empty": "Without description",
        "emptyEdit": "Add recipe description",
        "title": "Introduction"
      },
      "notes": {
        "title": "Notes"
      },
      "nutritional": {
        "amount": "Nutrition facts {{text}}",
        "extra": {
          "CDR": "The Daily Value Percentages are based on a 2000 calorie diet"
        },
        "noInfo": "No nutritional information",
        "summaryButton": {
          "carbo": "Carbohydrates",
          "enery": "Energy",
          "fats": "Fat",
          "noInfo": "No information",
          "proteins": "Proteins"
        },
        "title": "Nutrition facts"
      },
      "preparation": {
        "emptyList": "No preparation",
        "extra": {
          "notes": "Touch {logo} to see notes or tricks",
          "steps": "Tap on the steps to mark them as done"
        },
        "summary": {
          "difficult": "difficulty level",
          "difficultRate": "Difficulty level",
          "portion": "Rations",
          "preparationTime": "Preparation time",
          "totalTime": "Total time"
        },
        "title": "Instructions"
      }
    }
  },
  "diet": {
    "cart": {
      "action": "Finish",
      "calendar": {
        "action": "Next",
        "clue": "Select the days for which you want to buy",
        "title": "Purchase calendar"
      },
      "clue": "Select the ingredients you want to include in your shopping list",
      "empty": {
        "title": "There are no ingredients to add to the shopping list"
      },
      "name": "Shopping list for my weekly meal plan",
      "overwriteConflict": {
        "add": "Just add",
        "overwrite": "Replace",
        "text": "You have items on your shopping list, do you want to replace them with the ingredients selected for your weekly meal plan?",
        "title": "Add or Overwrite"
      },
      "success": "Your shopping list has been updated successfully",
      "title": "Weekly meal plan shopping list"
    },
    "custom": {
      "title": "Ingredients"
    },
    "days": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    "empty": "This weekly meal plan is no longer available",
    "emptyDay": "You have not added any dish yet {linebreak} for this day",
    "entry": {
      "added": "The dish has been added successfully",
      "changed": "The dish has been updated successfully",
      "day": {
        "description": "Select the days of the week for which you want to plan the dish",
        "title": "Day of the week"
      },
      "description": "Select the day of the week, the meal of the day and the exact dish you want to include in your personalized weekly meal plan",
      "descriptionEdit": "Modify or replace the meal you want to include in your personalized weekly meal plan",
      "edit": "Edit dish",
      "error": {
        "accept": "Accept",
        "not-day-selected": "You have not selected any day yet",
        "not-meal-selected": "You have not selected any meal yet",
        "not-recipe-selected": "You have not entered any dish yet"
      },
      "meal": {
        "description": "Select the meal of the day for which you want to plan the dish",
        "title": "Meal of the day"
      },
      "recipe": {
        "amount": "Quantity",
        "create": {
          "ingredient": {
            "add": "Add ingredient",
            "save": "SAVE",
            "title": "Ingredient name"
          },
          "input": {
            "title": "Name of the dish"
          },
          "more": {
            "clean": "Remove ingredients",
            "delete": "Remove",
            "rename": "Rename dish"
          },
          "title": "Add manually"
        },
        "description": "Select the Vegamecum recipe that you want to add to your menu or add a dish and its ingredients manually",
        "ingredients": "Ingredients",
        "internet": {
          "input": {
            "cancel": "CANCEL",
            "save": "GO"
          },
          "next": "Following",
          "prev": "Previous",
          "title": "Search the Internet",
          "url": "Enter URL"
        },
        "nutrition": "Nutrition facts",
        "selectedRecipe": "This is your selected recipe",
        "title": "Dish",
        "vegamecum": {
          "headerIngredients": "Ingredients",
          "headerRecipes": "Recipes",
          "placeholder": "Enter the name",
          "title": "Search in Vegamecum"
        }
      },
      "title": "Add dish"
    },
    "import": {
      "button": "Save",
      "confirm": {
        "button": "SAVE"
      },
      "successfully": "The weekly meal plan has been saved successfully",
      "text": "Are you sure you want to save this weekly meal plan? If you save it, it will replace the one you have already created.",
      "title": "Save weekly meal plan"
    },
    "meals": [
      "Breakfast",
      "Brunch",
      "Lunch",
      "Snack",
      "Dinner"
    ],
    "menu": {
      "edit": "Edit dish",
      "remove": "Remove dish"
    },
    "more": {
      "addToCalendar": "Add to 'My calendar'",
      "addToCart": "Add to 'My shopping list'",
      "remove": "Empty weekly meal plan",
      "share": "Share"
    },
    "nutritionalSummary": {
      "title": "Nutrition facts"
    },
    "planified": {
      "calendar": {
        "action": "Create list",
        "clue": "Select the days for which you want to design your shopping list",
        "title": "Plan purchase"
      },
      "empty": {
        "title": "Your shopping list is empty"
      },
      "success": "It has been planned correctly"
    },
    "remove": {
      "successfully": "Your weekly meal plan has been emptied successfully"
    },
    "share": {
      "content": "You can find my weekly meal plan at https://vegamecum.com/app/share/diet/{{key}}",
      "title": "Weekly meal plan"
    },
    "title": "My weekly meal plan"
  },
  "donate": {
    "accept": "I would like to collaborate",
    "cancel": "I can't collaborate now",
    "detail": {
      "button": "Donate via PayPal",
      "link": "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=J9PGWKBAZ8WSJ&source=url",
      "text": "<h1>Thank you very much for supporting Vegamecum!</h1><p>With your support we can continue sharing for free a way to cook healthy and sustainable and making available to everyone the technology that allows them to make a change that protects animals, the environment and their health in the most easy and comfortable way.</p><p>The Vegamecum app  is developed and maintained by its creators, with the only economic aid that advertising provides, which is, unfortunately, insufficient to cover server costs, domain, development, raw material, human resources and the various operating expenses derived from activity.</p><p>Only with your help we can keep the Vegamecum app active, develop technical improvements and innovate in features and usability so user experience is as complete, positive and beneficial as possible.</p><p>Any help, however small, is very welcome. If you want to support us, you can send us a donation via PayPal by touching the donate button.</p>"
    },
    "text": "Help Vegamecum move forward"
  },
  "editRecipe": {
    "delete": "Remove",
    "imagePicker": {
      "fromCamera": "Take picture",
      "fromLibrary": "Choose from my library",
      "notYetUploaded": {
        "continue": "Stop upload",
        "title": "There are images that have not been uploaded yet.",
        "wait": "Wait for them to get on"
      },
      "title": "Choose an image"
    },
    "ingredients": {
      "add": {
        "title": "Add ingredient list"
      },
      "block": {
        "placeholder": "Nameless",
        "title": "Group Name"
      },
      "description": "Add, modify or replace the ingredients you want to include in your recipe and, if you want to create more than one group of ingredients, give the group a name",
      "list": {
        "title": "Ingredients"
      },
      "title": "Edit Ingredients"
    },
    "introduction": {
      "categories": {
        "description": "Add here the category or categories to which the recipe belongs",
        "empty": "You have not added any categories yet",
        "input": "Category Name",
        "title": "Categories"
      },
      "categoriesAndFilters": {
        "add": "Add",
        "description": "Indicate to which category the recipe belongs and add the labels you want to make it easier to find",
        "title": "Edit Category"
      },
      "description": "Describe the main characteristics of your recipe, such as taste, texture or origin",
      "tags": {
        "description": "Add here the labels that best define your recipe",
        "empty": "You haven't added any tags yet",
        "input": "Tag name",
        "title": "Tags"
      },
      "text": {
        "placeholder": "Recipe Description",
        "title": "Recipe Description"
      },
      "title": "Edit Description"
    },
    "menu": {
      "delete": "Delete recipe",
      "readMode": "Open in read mode"
    },
    "preparationStep": {
      "add": {
        "newTitle": " step {{number}}",
        "title": "Add step"
      },
      "block": {
        "placeholder": "All the ingredients",
        "title": "Group name"
      },
      "description": "Add, modify or replace the necessary steps to make your recipe. You can also add timer, additional notes and even photos to visually show step by step",
      "list": {
        "title": "List of ingredients"
      },
      "name": {
        "title": "Step name"
      },
      "note": {
        "placeholder": "Additional Note (optional)",
        "title": "Additional note"
      },
      "pictures": {
        "description": "pictures description",
        "title": "Step photo"
      },
      "text": {
        "placeholder": "Step description",
        "title": "Step description"
      },
      "timer": {
        "switch": "Add timer",
        "text": "Weather",
        "title": "Timer"
      },
      "title": "Edit step"
    },
    "recipeNumber": {
      "description": "Indicate the resulting amount of your recipe in the unit that best suits the type of dish",
      "editor": {
        "number": {
          "text": "Quantity"
        },
        "title": "Quantity",
        "unit": {
          "options": {
            "gr": "Grams",
            "l": "Liters",
            "persona": "People",
            "porción": "Portions",
            "ración": "Rations",
            "unidad": "Units"
          },
          "text": "Unity"
        }
      },
      "title": "Edit portions"
    },
    "recipeSummary": {
      "description": "Indicate the difficulty level of your recipe and the average preparation time",
      "difficult": {
        "options": {
          "average": "Normal",
          "easy": "Easy",
          "hard": "Hard",
          "veryEasy": "Very easy"
        },
        "text": "Difficulty level",
        "title": "Difficulty level"
      },
      "time": {
        "cooking": "Preparation time",
        "extra": {
          "motive": "Reason",
          "switch": "Extra time",
          "text": "Extra time"
        },
        "title": "Weather",
        "total": {
          "text": "Total time"
        }
      },
      "title": "Edit Summary"
    },
    "save": "Save",
    "title": {
      "description": "Name your recipe and add the photos you want to show",
      "name": {
        "placeholder": "Recipe Name",
        "title": "Recipe Name"
      },
      "pictures": {
        "title": "Recipe photos"
      },
      "title": "Edit Name"
    }
  },
  "extras": {
    "description": "Here you can get all the extras that will allow you to expand your experience in Vegamecum.",
    "purchaseButtonText": "{{price}} / {{months}} months",
    "purchaseButtonYearText": "{{price}} / year",
    "restoreButtonText": "Restore purchases",
    "rewardButtonText": "Free trial",
    "title": "Additional features"
  },
  "favorites": {
    "empty": "You do not have any favorite recipe yet {linebreak} Press the icon {icon} that you'll find on the top of the recipe to add it",
    "search": {
      "headerIngredients": "Ingredients",
      "headerRecipes": "Recipes",
      "placeholder": "Enter the name"
    },
    "title": "My favourites"
  },
  "filter": {
    "add": "+ Create filter",
    "create": {
      "categories": {
        "allergens": {
          "description": "Do you have any food allergy or intolerance? Mark the allergens you want to avoid",
          "gluten": "Gluten",
          "nuts": "Nuts",
          "soy": "Soy",
          "title": "Allergens"
        },
        "category": {
          "description": "Choose the categories that interest you. If you do not choose any category, all of them will be shown",
          "title": "Category"
        },
        "difficult": {
          "description": "Scroll the bar to adjust the maximum difficulty of the recipes",
          "levels": {
            "0": "Only the easiest",
            "33": "Easy and medium difficulty",
            "66": "All but the most difficult",
            "100": "All recipes",
            "max": "All",
            "min": "Very easy"
          },
          "title": "Difficulty"
        },
        "ingredients": {
          "add": "Add",
          "description": "Find recipes based on the ingredients you want them to contain. You can also indicate those you want to avoid",
          "excluded": {
            "description": "Add here the ingredients you want to avoid in the recipes",
            "title": "Excluded"
          },
          "included": {
            "description": "Add here the ingredients you want the recipes to contain",
            "title": "Included"
          },
          "title": "Ingredients"
        },
        "time": {
          "description": "Scroll the bar to adjust the maximum time for recipe preparation",
          "levels": {
            "0": "<5 min.",
            "10": "<10 min.",
            "20": "<15 min.",
            "30": "<20 min.",
            "40": "<30 min.",
            "50": "<45 min.",
            "60": "<1 h.",
            "70": "<1 h. and 30 min.",
            "80": "<2 h.",
            "90": "<2 h. and 30 min.",
            "100": "All recipes",
            "max": "All",
            "min": "<5 min."
          },
          "title": "Preparation time"
        }
      },
      "description": "Activate the following filters to find your perfect recipe",
      "save": {
        "button": "Save",
        "defaultName": "My filter",
        "title": "Filter name"
      },
      "title": "Create your filter"
    },
    "custom": "CUSTOM FILTER",
    "delete": "REMOVE",
    "edit": {
      "button": "EDIT",
      "title": "Edit your filter"
    },
    "new": "NEW FILTER",
    "title": "What do you feel like eating?"
  },
  "ingredient": {
    "fraction": "{{whole}} and {{fraction}}"
  },
  "ingredientPicker": {
    "add": "ADD",
    "amount": "quantity",
    "cancel": "CANCEL",
    "delete": "REMOVE",
    "name": "name",
    "save": "SAVE",
    "unit": "unit",
    "unitSelector": "Select unit",
    "withoutUnit": "without unit"
  },
  "input": {
    "cancel": "CANCEL",
    "save": "SAVE"
  },
  "loading": {
    "title": "Touch to start"
  },
  "myRecipes": {
    "add": "Create new",
    "empty": "You don't have any recipe created yet",
    "title": "My recipes"
  },
  "newRecipeEditor": {
    "title": "Create new recipe"
  },
  "notFound": {
    "page": {
      "subtitle": "It seems that there is nothing in that location, do you want to try searching?",
      "title": "Wow This page is missing."
    },
    "searcher": {
      "subtitle": "We're sorry, but nothing fits the search terms. Try again with some different keywords.",
      "title": "Nothing found"
    }
  },
  "notifications": {
    "alamFinished": "Now you can pick up your recipe!",
    "alarmPlanified": "The alarm will sound at {{date}}",
    "cartlistUpdate": "Your shopping list has been updated",
    "filterCreated": "Your filter has been created successfully",
    "filterDeleted": "Your filter has been successfully removed",
    "filterUpdated": "Your filter has been updated successfully",
    "noNetwork": "You do not have internet connection",
    "recipePlanified": "The recipe has been scheduled successfully",
    "translationProposal": "It has been sent successfully"
  },
  "nutrition": {
    "energy": "{{value}} kcal."
  },
  "playlist": {
    "controls": {
      "alarm": {
        "regex": "activate alarm",
        "text": "Activate alarm"
      },
      "finish": {
        "regex": "finish",
        "text": "Finish"
      },
      "next": {
        "regex": "next",
        "text": "Next step"
      },
      "prev": {
        "regex": "previous",
        "text": "Previous step"
      },
      "repeat": {
        "regex": "repeat",
        "text": "Repeat step"
      }
    },
    "menu": {
      "close": "Close",
      "disableVoiceControl": "Disable voice control",
      "enableVoiceControl": "Enable voice control",
      "openRecipe": "Go to the recipe"
    },
    "tip": "A Vegamecum advice."
  },
  "popover": {
    "moreOptions": "More options"
  },
  "portal": {
    "bigTitle": "Latest recipes",
    "menu": "Menu",
    "results": "Results",
    "title": "Recipes"
  },
  "releaseNotes": {
    "close": "Close",
    "next": "Next",
    "previous": "Previous"
  },
  "rewardedVideo": {
    "accept": "Watch now",
    "active": "Now you can enjoy all the extra features for 60 minutes!",
    "cancel": "No, thanks",
    "cancelForever": "Don't show again",
    "remainingTime": " {{number}} minutes left",
    "text": "Enable all the extra features for 60 minutes by watching this short advertising video"
  },
  "searcher": {
    "boldText": "Results for {text}",
    "category": "Category: {{searchValue}}",
    "contains": "Search recipes with",
    "empty": "No results found",
    "filter": "Filter: {{searchValue}}",
    "last": "Latest searches",
    "results": "Results",
    "suggested": "Suggestions",
    "tags": "Tag: {{searchValue}}",
    "text": "Results for {{searchValue}}",
    "title": "Search by recipe or ingredient"
  },
  "sideBar": {
    "categories": "Categories",
    "filters": "Filters",
    "last": "Latest recipes",
    "search": "Search for ..."
  },
  "slowLoading": {
    "cancel": "CANCEL",
    "description": "It seems that it is taking a long time to load, do you want to send us a report so that we can investigate what is happening?",
    "done": "Message received! We start looking at what may be happening. You can also contact us by mail to vegamecum@gmail.com to tell us your case.",
    "ok": "OK",
    "send": "SUBMIT",
    "title": "Slow loading?"
  },
  "translate": {
    "original": {
      "title": "Original text"
    },
    "save": "Save",
    "title": "Improve translation",
    "translated": {
      "placeholder": "Enter your alternative translation proposal",
      "seeOriginal": "See original text",
      "title": "Alternative translation"
    }
  }
};