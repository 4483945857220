/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { fetchData } from "../../actions";
import RelatedPostsList from '../../components/related-posts-list';
import { getPostLink } from '../../utils/link-maker';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import i18n from '../../i18n';

const RELATED_RECIPES_LIST_KEY = 'related-recipes-list-';

class RelatedPosts extends Component {
  componentDidMount() {
    let category = '*';
    if(this.props.categories && this.props.categories.length > 0) {
      category = this.props.categories[0]
    }
    this.props.dispatch(fetchData(['search', {
        searchParam: `categories:"${category}" orderBy favs:desc`,
        next: {
          from: 0,
          limit: 4
        } 
      }], RELATED_RECIPES_LIST_KEY + this.props.slug, true));  
  }
  render() {
    return <RelatedPostsList items={this._getItems()}/>
  }
  _getItems() {
    let items = [];
    this.props.posts.forEach(post => {
      if(items.length >=3 || post.slug === this.props.slug) {
        return;
      }
      items.push({
        name: post.title,
        link: getPostLink(post),
        date: moment(post.date).locale(i18n.getSelectedLanguage()).format('LL'),
        category: post.categories ? i18n.t('detail.related.category', {
          category: post.categories[0]
        }) : ''
      });
    });
    return items;
  }
}

RelatedPosts.propTypes = {
  dispatch: PropTypes.func,
};

RelatedPosts.defaultProps = {
  dispatch: () => {},
};

export default connect((state, props) => {
  let postsContainer = state.posts.lists[RELATED_RECIPES_LIST_KEY + props.slug] || {
    'error': false,
    'loading': true,
    'posts': [],
  };
  return {
    isError: postsContainer.error,
    isFetching: postsContainer.loading,
    posts: postsContainer.posts || []
  };
})(RelatedPosts);
