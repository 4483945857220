/* @flow */

import * as types from "../actions";
import produce from "immer";

const MONTH_IN_MS = 2592000000;

const data = (state = {
	loginStatus: {},
	info: {
        donate: {
            active: false,
            accept: false,
            date: 0
        }
    },
	isAdmin: false
}, action) => produce(state, draft => {
	switch (action.type) {
		case types.SET_USER:
            draft.displayName = action.data.displayName;
            draft.email = action.data.email;
            draft.providerData = action.data.providerData;
            draft.admin = action.data.admin;
            draft.uid = action.data.uid;
            return;
	    case types.RECEIVE_USER_INFO:
            let info = Object.assign({}, state.info, action.data);
			//info.donate.active = new Date().getTime() -  info.donate.date > MONTH_IN_MS; TODO:SUPPORT DISABLED 
            //info.donate.active = false;
            draft.info = info;
            return;
	    case types.SET_LOGIN_STATUS:
            draft.loginStatus = action.data;
            return;
    }
});

export default data;
