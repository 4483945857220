import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
//import tileData from "./tileData";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 */
const tileData = [
  {
    img:
      "https://storage.googleapis.com/vegamecum-c057a.appspot.com/uploads%2F2020%2F7%2F19%2F16%3A58%3A52_5.l.jpg?GoogleAccessId=firebase-adminsdk-36ju5%40vegamecum-c057a.iam.gserviceaccount.com&Expires=16730323200&Signature=jYKsE2pm71F8%2FFe3oadv2tlFPotik%2BwrWyzqtAavaiJipCIMJiBbszQthrGN9HRxHjOEX9zbafOwnwN08Y4QTAqVb9PYLzU9BMbLsKw5a2rl7XlFks5K4PZihWdgAo2QmZ223Xau%2Btx0cPkMR6NNo3XPu4EMZtztMDii8E%2BrGTq7MBiL%2FY8WLWXteiquhND4KASOAupVW7FKSEsQRENfH8kCsO1wPrWJWYFw283LbZQXwCk32mEbF3bo8kuxzU2B4ys0nIaTBgY%2FXeyskBMYLVpGq84ZtSZYlwikVqAdPPLKIBmXmRf4z1uPXySMyeji8b1PkRm6iVdIWqg2noQvjw%3D%3D",
    title: "Receta XXXX",
    author: "author",
  },
  {
    img:
      "https://storage.googleapis.com/vegamecum-c057a.appspot.com/uploads%2F2020%2F7%2F19%2F16%3A58%3A52_5.l.jpg?GoogleAccessId=firebase-adminsdk-36ju5%40vegamecum-c057a.iam.gserviceaccount.com&Expires=16730323200&Signature=jYKsE2pm71F8%2FFe3oadv2tlFPotik%2BwrWyzqtAavaiJipCIMJiBbszQthrGN9HRxHjOEX9zbafOwnwN08Y4QTAqVb9PYLzU9BMbLsKw5a2rl7XlFks5K4PZihWdgAo2QmZ223Xau%2Btx0cPkMR6NNo3XPu4EMZtztMDii8E%2BrGTq7MBiL%2FY8WLWXteiquhND4KASOAupVW7FKSEsQRENfH8kCsO1wPrWJWYFw283LbZQXwCk32mEbF3bo8kuxzU2B4ys0nIaTBgY%2FXeyskBMYLVpGq84ZtSZYlwikVqAdPPLKIBmXmRf4z1uPXySMyeji8b1PkRm6iVdIWqg2noQvjw%3D%3D",
    title: "Image",
    author: "author",
  },
  {
    img:
      "https://storage.googleapis.com/vegamecum-c057a.appspot.com/uploads%2F2020%2F7%2F19%2F16%3A58%3A52_5.l.jpg?GoogleAccessId=firebase-adminsdk-36ju5%40vegamecum-c057a.iam.gserviceaccount.com&Expires=16730323200&Signature=jYKsE2pm71F8%2FFe3oadv2tlFPotik%2BwrWyzqtAavaiJipCIMJiBbszQthrGN9HRxHjOEX9zbafOwnwN08Y4QTAqVb9PYLzU9BMbLsKw5a2rl7XlFks5K4PZihWdgAo2QmZ223Xau%2Btx0cPkMR6NNo3XPu4EMZtztMDii8E%2BrGTq7MBiL%2FY8WLWXteiquhND4KASOAupVW7FKSEsQRENfH8kCsO1wPrWJWYFw283LbZQXwCk32mEbF3bo8kuxzU2B4ys0nIaTBgY%2FXeyskBMYLVpGq84ZtSZYlwikVqAdPPLKIBmXmRf4z1uPXySMyeji8b1PkRm6iVdIWqg2noQvjw%3D%3D",
    title: "Image",
    author: "author",
  },
];

export default function SingleLineGridList(props) {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {props.title}
      </Typography>
      <GridList className={classes.gridList} cols={2.5}>
        {tileData.map((tile) => (
          <GridListTile key={tile.img}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar
              title={tile.title}
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
              actionIcon={
                <IconButton aria-label={`star ${tile.title}`}>
                  <StarBorderIcon className={classes.title} />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </section>
  );
}
