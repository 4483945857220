/* @flow */
/*eslint-disable prefer-const */

import Component from "../super";
import NotFoundPage from "../../containers/not-found";


class NotFound extends Component {
  getComponent() {
  	return NotFoundPage;
  }
}

NotFound.propTypes = {};

NotFound.defaultProps = {};

export default NotFound;