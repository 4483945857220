/* @flow */
/*eslint-disable prefer-const */

import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { updateTranslation } from "../../actions";
import {
    updateTitle
} from "../../actions/edit";
import EditButton from "../../components/edit-button";
import TranslateButton from "../improve-translation";
const EditTitle = React.lazy(() => import("../../components/edit-title"));

class PostDetailTitle extends Component {
  constructor(props: any) {
    super(props);
    this.titleChanged = null;
  }
  render() {
    return (
      <EditButton
        tagName="div"
        disableTagName={React.Fragment}
        active={!this.props.enableImproveTranslations && this.props.isAdmin}
        editComponent={() => (
          <Suspense fallback={<div>Loading...</div>}>
            <EditTitle
              title={this.props.title}
              onChange={(title) => {
                this.titleChanged = title;
              }}
            />
          </Suspense>
        )}
        onSavePress={() => {
          if (this.titleChanged) {
            this.props.dispatch(
              updateTitle(this.props.post, this.titleChanged.title)
            );
          }
        }}
        onDeletePress={() => {}}
        readComponent={
          <header className="entry-header">
            <h1 className="entry-title">{this.props.title}</h1>
            <TranslateButton
              active={this.props.enableImproveTranslations}
              text={this.props.title}
              onTranslateDone={this._onTranslateDone.bind(this)}
            />
          </header>
        }
        style={{ paddingBottom: "10px" }}
      ></EditButton>
    );
  }
  _onTranslateDone(text) {
    this.props.dispatch(
      updateTranslation({
        language: "en",
        slug: this.props.post.slug,
        key: "posts/" + this.props.post.slug + "/title",
        text,
        tsPath: "posts/" + this.props.post.slug + "/modified",
      })
    );
  }
  _renderSummary() {
    try {
      let rendered = renderHTML(this.props.introduction);
      return rendered.length > 0 ? rendered[0] : rendered;
    } catch (e) {}
    return null;
  }
}

PostDetailTitle.propTypes = {
  dispatch: PropTypes.func,
};

PostDetailTitle.defaultProps = {
  dispatch: () => {},
  isAdmin: false,
};

export default connect((state, props) => {
  return {
    title: props.post.title || "",
    isAdmin: state.user.admin,
    enableImproveTranslations: props.post.__vo && state.user.admin, //state.settings.enableImproveTranslations,
  };
})(PostDetailTitle);
