import {createSelector} from 'reselect'
import _ from 'lodash'
import produce from 'immer'

const getSuggestedPostsList = (state, props) => props.suggestPostsList
const hasSuggestedIngredients = (state, props) => props.suggestIngredients !== false
const hasSuggestedPosts = (state, props) => props.suggestPosts !== false
const getFilterOptions = (state, props) => props.filterOptions

const getIngredientsList = (state, props) => state.search.allSuggestions.ingredients
const getPortalPostsSuggestions = (state, props) => state.search.allSuggestions.lists.portal
const getSearchingText = (state, props) => state.search.searchingText


export const getIngredientsSuggestions = createSelector(
  [hasSuggestedIngredients, getSearchingText, getIngredientsList],
  (isEnabled, text, list) => {
    let suggestions = isEnabled ? filterSuggestions(text, list) : {
      list: [],
      matched: null
    };
    return suggestions;
  }
)

export const getIngredientsSuggestionsList = createSelector(
  [getIngredientsSuggestions],
  (suggestions) => {
    return suggestions ? suggestions.list : [];
  }
)

export const getIngredientsSuggestionsMatched = createSelector(
  [getIngredientsSuggestions],
  (suggestions) => {
    return suggestions ? suggestions.matched : null;
  }
)

export const getPostsSuggestions = createSelector(
  [hasSuggestedPosts, getSearchingText, getFilterOptions, getSuggestedPostsList, getPortalPostsSuggestions],
  (isEnabled, text, filterOptions, customPostsList, defaultPostsList) => {
    let list = customPostsList || defaultPostsList;
    let suggestions = isEnabled ? filterSuggestions(text, list, filterOptions).list : [];
    
    return suggestions;
  }
)

const filterSuggestions = (text, list, options = {field: 'text'}) => {
  try {
    if (text) {
      let regex = new RegExp('(\\s|^)' + getAccentInsensitive(text), 'gi')
      let matched
      let found
      list = _.compact(
        _.map(list, suggestion => {
          if (suggestion[options.field] === text) {
            found = suggestion
            return null
          }
          matched = suggestion[options.field].match(regex)
          if (matched) {
            return produce(suggestion, nextState => {
              nextState.highlighted =
                '<div>' +
                suggestion[options.field].replace(
                  regex,
                  '<strong>' + matched[0] + '</strong>'
                ) +
                '</div>'
            })
          }
        })
      )
      if (found) {
        list.unshift(
          produce(found, nextState => {
            nextState.highlighted = `<div><strong>${found.text}</strong></div>`
          })
        )
      }
      return {
        matched: found,
        list,
      }
    }
  } catch (e) {
    console.log(e);
  }
  return {
    matched: null,
    list: [],
  }
}

const ACCENT_MAP = {
  a: '(?:a|á)',
  á: '(?:a|á)',
  ê: '(?:e|é|ê)',
  é: '(?:e|é|ê)',
  e: '(?:e|é|ê)',
  í: '(?:i|í)',
  i: '(?:i|í)',
  ó: '(?:o|ó)',
  o: '(?:o|ó)',
  ú: '(?:u|ú)',
  u: '(?:u|ú)',
}

const getAccentInsensitive = s => {
  if (!s) {
    return ''
  }
  var ret = ''
  for (var i = 0; i < s.length; i++) {
    ret += ACCENT_MAP[s.charAt(i)] || s.charAt(i)
  }
  return ret
}