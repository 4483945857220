/* @flow */

import * as types from "../actions";
import produce from "immer";

const data = (state = false, action) => produce(state, draft => {
	switch (action.type) {
	    case types.SHOW_MENU:
            return true;
        case types.HIDE_MENU:
            return false;
    }
});

export default data;