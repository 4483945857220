/* @flow */

import React, { Component } from 'react';

class Image extends Component {
  render() {
    return (
      <img style={this.props.style} src={this.props.source.uri}/>
    );
  }
}

export default Image;
