/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { fetchInitialData, fetchData, activeApp } from "../../actions";

import {
  View,
  Text
} from '../../platform';

class LoadingPortal extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      quoteIndex: 0,
      quoteActive: false,
      active: false,
      quote: {},
      disable: props.isAppActive
    };
  }
  componentDidMount() {
    this.props.dispatch(fetchInitialData({
      reset: false
    }));
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.initialized !== this.props.initialized) {
      //this.props.dispatch(fetchData(['search', {ref: '/lists/portal'}], 'portal', true));    
      setTimeout(() => {
        this.setState({
          showButton: this.props.initialized
        })
      })
    }
  }
  render() {
    if (this.state.disable) {
      return false;
    }

    let action, text, origin;
    if (!this.props.initialized) {
      action = (
        <div className="splash-screen">
          <div className="splash-screen-logo"></div>
        </div>
      );
    }
    return <div style={{ position: 'absolute' }}>
      
    </div>
  }
}

LoadingPortal.propTypes = {
  //dispatch: React.PropTypes.func,
  //initialized: React.PropTypes.bool,
  //isAppActive: React.PropTypes.bool,
};

LoadingPortal.defaultProps = {
  dispatch: () => { },
  initialized: false,
  isAppActive: false,

};

export default connect((state) => {
  return {
    initialized: state.app.initialized,
    isAppActive: state.app.active,
    quotes: state.quotes,
    screenSize: state.screen.size
  }
})(LoadingPortal);