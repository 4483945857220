/* @flow */
/*eslint-disable prefer-const */

import React, { PureComponent } from 'react';
import _ from 'lodash';
import clsx from 'clsx';

class EditNotes extends PureComponent {
  constructor(props: any) {
    super(props);
    this.notes = _.cloneDeep(this.props.notes);
    this.state = {
      updateHook: 0,
    };
  }
  render() {
    let notes = [];
    if(this.notes) {
      notes = this.notes.map((note, index) => {
        return (
          <div key={`${index} - ${note.text}`}>
            <textarea onChange={this._onChange.bind(this, [index])} rows="4" cols="50" defaultValue={note.text} placeholder="Introduce el texto de la nota"></textarea>
            <button onClick={this._onRemoveNotePress.bind(this, index)} type="button">ELIMINAR NOTA</button>
          </div>
        );
      });
    }
    return (
      <React.Fragment>
        {notes}
        <button onClick={this._onAddNotePress.bind(this)} type="button">AÑADIR NOTA</button>
      </React.Fragment>
    )

  }
  _onAddNotePress() {
    let notes = this.notes || [];
    notes.push({
      text: '',
      type: 'text',
      msgType: 'advice'
    });
    this._updateNotes(notes, true);
  }
  _onRemoveNotePress(index) {
    let notes = this.notes || [];
    notes.splice(index, 1);
    this._updateNotes(notes, true);
  }
  _onChange(path, {currentTarget}) {
    let notes = _.cloneDeep(this.notes);
    let note = _.get(notes, path) || {};
    note.text = currentTarget.value;
    note.type = note.type || 'text';
    note.msgType = note.msgType || 'advice';
    notes = _.set(notes, path, note);
    this._updateNotes(notes);
  }
  _updateNotes(notes, forceRender = false) {
    this.notes = notes;
    this.props.onChange(notes);
    if(forceRender) {
      this.setState({
        updateHook: (this.state.updateHook + 1)
      });
    }
  }
}

EditNotes.propTypes = {

};

EditNotes.defaultProps = {
  onChange: () => {},
};

export default EditNotes;