const map = {
	name: 'nutrients',
	key: 'nutrients',
	type: 'object',
	child: [{
	  "name": "Water",
	  "key": "w"
	},
	{
	  "name": "Energy",
	  "key": "e"
	},
	{
	  "name": "Protein",
	  "key": "p"
	},
	{
	  "name": "Fatty acids, total saturated",
	  "key": "fas"
	},
	{
	  "name": "Fatty acids, total monounsaturated",
	  "key": "fam"
	},
	{
	  "name": "Fatty acids, total polyunsaturated",
	  "key": "fap"
	},
	{
	  "name": "Cholesterol",
	  "key": "cho"
	},
	{
	  "name": "Total lipid (fat)",
	  "key": "fat"
	},
	{
	  "name": "Fiber, total dietary",
	  "key": "fib"
	},
	{
	  "name": "Sugars, total",
	  "key": "s"
	},
	{
	  "name": "Carbohydrate, by difference",
	  "key": "c"
	},
	{
	  "name": "Calcium, Ca",
	  "key": "ca"
	},
	{
	  "name": "Iron, Fe",
	  "key": "fe"
	},
	{
	  "name": "Magnesium, Mg",
	  "key": "mg"
	},
	{
	  "name": "Phosphorus, P",
	  "key": "pho"
	},
	{
	  "name": "Potassium, K",
	  "key": "k"
	},
	{
	  "name": "Sodium, Na",
	  "key": "na"
	},
	{
	  "name": "Zinc, Zn",
	  "key": "zn"
	},
	{
	  "name": "Vitamin C, total ascorbic acid",
	  "key": "vc"
	},
	{
	  "name": "Thiamin",
	  "key": "thi"
	},
	{
	  "name": "Riboflavin",
	  "key": "ri"
	},
	{
	  "name": "Niacin",
	  "key": "ni"
	},
	{
	  "name": "Vitamin B-6",
	  "key": "vb6"
	},
	{
	  "name": "Folate, DFE",
	  "key": "dfe"
	},
	{
	  "name": "Vitamin B-12",
	  "key": "vb12"
	},
	{
	  "name": "Vitamin A, RAE",
	  "key": "va"
	},
	{
	  "name": "Vitamin E (alpha-tocopherol)",
	  "key": "ve"
	},
	{
	  "name": "Vitamin D (D2 + D3)",
	  "key": "vd"
	},
	{
	  "name": "Vitamin K (phylloquinone)",
	  "key": "vk"
	}]
};

export default map;