import nutrients from './nutrients';

const map = {
	name: 'posts',
	key: 'posts',
	type: 'object',
	child: [{
		name: '*',
		key: '*',
		type: 'object',
		child: [{
			name: 'nutrients',
			key: 'n',
			type: 'object',
			child: [{
				name: 'all',
				key: 'all',
				type: 'object',
				child: nutrients.child
			}]
		}, {
			name: 'summary',
			key: 'summary',
			type: 'object',
			child: [{
				name: 'units',
				key: 'units',
				type: 'object',
				child: [{
					name: 'type',
					key: 'type',
					type: 'object',
					child: [{
						name: 'portion',
						key: 'p',
						type: 'object',
						child: [{
							name: 'name',
							key: 'n'
						}, {
							name: 'plural',
							key: 'p'
						}, {
							name: 'step',
							key: 's'
						}]
					}]
				}]
			}]
		}]
	}]
};

export default map;