/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import ThreeDRotation from '@material-ui/icons/ThreeDRotation';

class Pager extends Component {
  render() {
    return (
      <nav className="navigation pagination" role="navigation">
        <h2 className="screen-reader-text">Navegación de entradas</h2>
        <div className="nav-links">
          {this._renderPreviousButton()}
          {this._renderFirst()}
          {this._renderPreviousDots()}
          {this._renderPrevious()}
          {this._renderCurrent()}
          {this._renderFollowing()}
          {this._renderNextDots()}
          {this._renderLast()}
          {this._renderNextButton()}
        </div>
      </nav>
    );
  }
  _renderFirst() {
    if (this.props.pager.current !== 1) {
      return this._renderPageNumber(1);
    }
  }
  _renderLast() {
    if (this.props.pager.current !== this.props.pager.total) {
      return this._renderPageNumber(this.props.pager.total);
    }
  }
  _renderCurrent() {
    return (
      <span aria-current="page" className="page-numbers current">
        <span className="meta-nav screen-reader-text">Página </span>
        {this.props.pager.current}
      </span>
    );
  }
  _renderPrevious() {
    let number = this.props.pager.current - 1;
    if (number > 1) {
      return this._renderPageNumber(number);
    }
  }
  _renderFollowing() {
    let number = this.props.pager.current + 1;
    if (number < this.props.pager.total) {
      return this._renderPageNumber(number);
    }
  }
  _renderPreviousDots() {
    if (this.props.pager.current > 3) {
      return this._renderDots();
    }
  }
  _renderNextDots() {
    if (this.props.pager.current < this.props.pager.total - 2) {
      return this._renderDots();
    }
  }
  _renderDots() {
    return <span className="page-numbers dots">…</span>;
  }
  _renderPageNumber(number) {
    return (
      <Link to={this._getLinkForPageNumber(number)} className="page-numbers">
        <span className="meta-nav screen-reader-text">Página </span>
        {number}
      </Link>
    );
  }
  _renderPreviousButton() {
    let number = this.props.pager.current - 1;
    if (number > 0) {
      return (
        <Link
          to={this._getLinkForPageNumber(number)}
          className="prev page-numbers"
        >
          <ThreeDRotation/>
          Página anterior
        </Link>
      );
    }
  }
  _renderNextButton() {
    let number = this.props.pager.current + 1;
    if (number <= this.props.pager.total) {
      return (
        <Link
          to={this._getLinkForPageNumber(number)}
          className="next page-numbers"
        >
          Próxima página
        </Link>
      );
    }
  }
  _getLinkForPageNumber(number) {
    let pathname = this.props.navigationParams.path;
    if (number > 1) {
      pathname = `${pathname}/page/${number}`;
    }
    pathname = "/" + _.compact(pathname.split("/")).join("/");
    return {
      pathname,
      search: this.props.navigationParams.search,
    };
  }
}

Pager.propTypes = {};

Pager.defaultProps = {
  prefix: "",
  suffix: "",
};

export default Pager;
