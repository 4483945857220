/* @flow */

import * as types from "../actions";
import * as values from "../constants/values";
import produce from "immer";
import {DeviceInfo, Dimensions} from '../platform';

let window = Dimensions.get('window');

const data = (state = {
	orientation: (window.width > window.height) ? 'landscape' : 'portrait',
    isTablet: DeviceInfo.isTablet(),
	size: {
		width: window.width,
		height: window.height,
        padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        }
    },
    header: {
        height: values.NAVBAR_HEIGHT
    },
    scrollDirection: 'up'
}, action) => produce(state, draft => {
    switch (action.type) {
    	case types.CHANGE_ORIENTATION:
            draft.orientation = action.data;
            return;
		case types.CHANGE_SIZE:
            draft.size = Object.assign({}, state.size, action.data);
            return;
        case types.CHANGE_HEADER_SIZE:
            draft.header = action.data;
            return;
    }
});

export default data;