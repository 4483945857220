/* @flow */
/*eslint-disable prefer-const */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateTranslation } from "../../actions";
import * as colors from "../../constants/colors";
import I18n from "../../i18n";
import _ from "lodash";
import Image from "../../components/image";
import {
  removePreparationStep,
  updatePreparationStep,
} from "../../actions/edit";
import EditButton from "../../components/edit-button";
import EditPreparationStep from "../../components/edit-preparation-step";
import TranslateButton from "../improve-translation";

class PostDetailPreparation extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    if (!this.props.preparation) {
      return null;
    }
    return (
      <React.Fragment>
        {this._renderTitle()}
        {this._renderSteps(this.props.preparation)}
      </React.Fragment>
    );
  }
  _renderTitle() {
    return (
      <p>
        <strong>{I18n.t("detail.sections.preparation.title")}</strong>
      </p>
    );
  }
  _renderSteps(list) {
    return (
      <ol>
        {list.map(this._renderStep.bind(this))}
        {this.props.enableEdit && this._renderStep({}, list.length)}
      </ol>
    );
  }
  _renderStep(step, index) {
    let images = this._renderStepImage(step);
    let stepChanged = null;
    return (
      <EditButton
        tagName="li"
        active={this.props.enableEdit}
        key={`key-${index}`}
        editComponent={
          <EditPreparationStep
            step={step}
            onChange={(nextStep) => {
              stepChanged = nextStep;
            }}
          />
        }
        onSavePress={() => {
          if (stepChanged) {
            let data = {
              index,
              text: stepChanged.text,
              textRegex: stepChanged.textRegex || null,
              title: stepChanged.title,
              timer: stepChanged.timer || null,
              images: stepChanged.images || null,
              notes: stepChanged.notes
                ? stepChanged.notes.map((note) => ({
                    msgType: "advice",
                    text: note.text,
                    type: "text",
                  }))
                : null,
            };
            this.props.dispatch(updatePreparationStep(this.props.post, data));
          }
        }}
        onDeletePress={() => {
          this.props.dispatch(removePreparationStep(this.props.post, index));
        }}
        readComponent={
          <React.Fragment>
            {step.processedText}
            {images}
            <TranslateButton
              paths={["text", "title", /notes\.*.\.text/]}
              active={this.props.enableImproveTranslations}
              text={step}
              onTranslateDone={this._onTranslateDone.bind(this, step)}
            />
          </React.Fragment>
        }
        style={{ paddingBottom: "10px" }}
      ></EditButton>
    );
  }
  _renderStepImage(step) {
    if (step.figures) {
      return (
        <div>
          {step.figures.map((figure) => {
            return (
              <Image
                key={figure.path}
                path={figure.path}
                caption={figure.caption}
              />
            );
          })}
        </div>
      );
    }
  }
  _onTranslateDone(step, text) {
    this.props.dispatch(
      updateTranslation({
        language: "en",
        slug: this.props.post.slug,
        key: `posts/${this.props.post.slug}/preparation/${step.index}`,
        text,
        tsPath: "posts/" + this.props.post.slug + "/modified",
      })
    );
  }
}

PostDetailPreparation.propTypes = {
  dispatch: PropTypes.func,
};

PostDetailPreparation.defaultProps = {
  dispatch: () => {},
  isAdmin: false,
};

export default connect((state, props) => {
  let enableImproveTranslations = props.post.__vo && state.user.admin;
  let enableEdit = !enableImproveTranslations && state.user.admin;
  return {
    isAdmin: state.user.admin,
    enableImproveTranslations, //state.settings.enableImproveTranslations,
    enableEdit,
    preparation: props.post.preparation,
    units: props.post.summary.units,
  };
})(PostDetailPreparation);
