import { AsyncStorage, firebase, LogError } from "../platform";
import _ from "lodash";
import hash from "../utils/hash";

const firebaseConfig = {
  databaseURL: "https://vegamecum-c057a.firebaseio.com",
};

var dataByPath = {};

var listenCallbacks = {};

const firebaseLogError = (text) => {
  LogError(text);
};

export const getValueOnceFromPath = (
  path,
  cb,
  key,
  times,
  consolidationTimeout,
  cache
) => {
  let ref = firebase.database().ref(path);
  getValueOnceFromRef(ref, cb, key, times, consolidationTimeout, cache);
};

export const getValueOnceFromRef = (
  ref,
  cb,
  key,
  times,
  consolidationTimeout,
  cache
) => {
  var key = key || getKeyFromRef(ref);
  //console.log('getValueOnceFromRef: '+key+ ' ---- times: '+times + '-----> '+getKeyFromRef(ref));
  if (dataByPath[key]) {
    cb(dataByPath[key]);
  } else {
    listenToKey(ref, key, cb, times, consolidationTimeout, cache);
  }
};

export const getValueFromPath = (path, cb, progress = () => {}) => {
  console.log("getValueFromPath--> " + path);
  progress({
    status: "0",
    percent: 10,
  });
  getPathValueFromCache(path).then((result) => {
    progress({
      status: "1",
      percent: 30,
    });
    if (result) {
      result = result.data;
      console.log("FROM CACHE " + path);
      progress({
        status: "2",
        percent: 100,
      });
      cb({
        val: () => {
          return result;
        },
      });
    } else {
      console.log("NOT CACHE " + path);
      progress({
        status: "3 " + path,
        percent: 50,
      });
      let fetchTimeout = setTimeout(() => {
        firebaseLogError("timeout fetching path: " + path);
      }, 10000);
      getValueOnceFromPath(
        path,
        (value) => {
          clearTimeout(fetchTimeout);
          progress({
            status: "4",
            percent: 100,
          });
          cb(value);
        },
        null,
        null,
        null,
        true
      );
      /*
			getValueFromRestFirebase(path).then((value) => {
				cb({
					val: () => {
						return value;
					}
				});
			})
			*/
    }
  });
};

export const stopListeningKey = (key) => {
  if (listenCallbacks[key]) {
    let listener = listenCallbacks[key];
    //console.log('stoplistenToKey: '+key);
    listener.ref.off("value", listener.callback);
    listenCallbacks[key] = null;
    dataByPath[key] = null;
  }
};

const listenToKey = (
  ref,
  key,
  cb,
  times = 1,
  consolidationTimeout = 0,
  cache = false
) => {
  let firstTime = true;
  let callbackTimeout;
  let callback = (snapshot) => {
    //console.log('callback listenToKey: '+key);
    //ref.off('value', callback);
    dataByPath[key] = snapshot;
    if (cache) {
      saveItemForPath(key, snapshot.val());
    }
    clearTimeout(callbackTimeout);
    if (firstTime && consolidationTimeout) {
      firstTime = false;
      callbackTimeout = setTimeout(() => {
        callback(snapshot);
      }, consolidationTimeout);
      return;
    }
    if (cb) {
      cb(dataByPath[key]);
      times--;
      //console.log('listenToKey: '+key + ' times: '+times);
      if (times <= 0) {
        cb = null;
      }
    }
  };
  //console.log('listening '+_.keys(listenCallbacks).length);
  listenCallbacks[key] = {
    ref,
    callback,
  };
  ref.on("value", callback);
};

const getKeyFromRef = (ref) => {
  if (ref._query) {
    return _.reduce(
      ref._query.modifiers,
      (nemo, filter) => {
        return nemo + "|" + filter.id;
      },
      ref.path
    );
  } else {
    return ref.path.toString();
  }
};

let cacheMap = null;
let cacheLoading = false;
let cachePromises = [];

const getFlattenCacheMap = (obj, map, path, validKeysRegex) => {
  map = map || {};
  path = path || [];
  _.each(obj, (v, k) => {
    k = path.concat([k]);
    if (_.isObject(v) || _.isArray(v)) {
      getFlattenCacheMap(v, map, k, validKeysRegex);
    } else {
      k = k.join("/");
      if (!validKeysRegex || validKeysRegex.test(k)) {
        map[k] = v;
      }
    }
  });
  return map;
};

const getCacheMap = () => {
  let promise = new Promise((resolve, reject) => {
    if (!cacheMap) {
      cachePromises.push(resolve);
      if (!cacheLoading) {
        cacheLoading = true;
        let ref = firebase.database().ref("/cache");
        let callback = (snapshot) => {
          cacheMap = getFlattenCacheMap({
            "/cache": snapshot.val(),
          });
          _.each(cachePromises, (resolvePromise) => {
            resolvePromise(cacheMap);
          });
          cachePromises = [];
        };
        ref.on("value", callback);
      }
    } else {
      resolve(cacheMap);
    }
  });
  return promise;
};

const getCacheKeyForPath = (path) => {
  let promise = new Promise((resolve, reject) => {
    getCacheMap().then((cacheMap) => {
      resolve(cacheMap["/cache" + path]);
    });
  });
  return promise;
};

const getPathValueFromCache = (path) => {
  var promise = new Promise((resolve, reject) => {
    let timeout = setTimeout(() => {
      firebaseLogError("error getPathValueFromCache from path: " + path);
      resolve(null);
    }, 5000);
    getCacheKeyForPath(path).then((cacheKey) => {
      clearTimeout(timeout);
      //alert('cacheKey: '+cacheKey);
      if (cacheKey) {
        AsyncStorage.getItem(path, (err, result) => {
          if (result) {
            result = JSON.parse(result);
            //alert(result.cacheKey + '----cacheKey---' + cacheKey);
            let hasBeenRead = cacheKey === "___empty___" || result.data;
            if (result.cacheKey === cacheKey && hasBeenRead) {
              return resolve(result);
            }
          }
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  });
  return promise;
};

const saveItemForPath = (path, val) => {
  //alert(`${path} - ${hash.sha1(val)}`);
  //alert(hash.sha1(val));
  //console.log(path);
  //console.log(val);
  //console.log(hash.sha1(val));
  if (path.indexOf("/") !== 0) {
    path = `/${path}`;
  }
  AsyncStorage.setItem(
    path,
    JSON.stringify({
      data: val,
      cacheKey: hash.digest(val),
    }),
    (error) => {
      if (error) {
        //console.log('error---> '+error);
      }
    }
  );
};

const getValueFromRestFirebase = (path) => {
  var promise = new Promise((resolve, reject) => {
    let url = firebaseConfig.databaseURL + path + ".json";
    console.log("catch");
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response === null) {
          firebaseLogError("error fetchURL returns null: " + path);
        }
        resolve(response);
      })
      .catch(function (error) {
        reject();
      });
  });
  return promise;
};
