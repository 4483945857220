import hash from 'object-hash';
import _ from 'lodash';

const getFromArray = (value) => {
    let seed = _.map(value, (v, k) => {
        return v.modified || v.name || v.title || v.text || v.value;
    });
    return hash.sha1(_.orderBy(seed));
}

const Hash = {
    digest: (value) => {
        if(value) {
            if(_.isArray(value)) {
                return getFromArray(value);
            } else if(value.modified) {
                return hash.sha1(value.modified);
            }
            return hash.sha1(value);
        } else {
          return '___empty___';
        }
    }
}

export default Hash;
