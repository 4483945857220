import {AsyncStorage, firebase} from '../platform'
import * as cache from './firebase-cache'
import _ from 'lodash'
import I18n from '../i18n'

const mergeWithI18nField = (original, path, language, cb) => {
  getI18nField(path, language, snapshot => {
    cb({
      val: () => {
        let merged, defaultNewValue
        if (_.isArray(original)) {
          merged = []
          defaultNewValue = []
        } else {
          merged = {}
          defaultNewValue = {}
        }
        _.merge(merged, original, snapshot.val() || defaultNewValue)
        if (_.isObject(merged)) {
          merged.__vo = original
        }
        return merged
      },
    })
  })
}

const getI18nField = (path, language, cb) => {
  cache.getValueFromPath('/i18n/' + language + path, snapshot => {
    cb(snapshot)
  })
}

export const initialize = language => {
  return new Promise((resolve, reject) => {
    I18n.setLanguage(language)
    resolve(language)
  })
}

export const getTranslatePath = (path = '') => {
  return new Promise((resolve, reject) => {
    let language = I18n.getSelectedLanguage()
    let basePath = language !== 'es' ? `/i18n/${language}` : ''
    resolve(basePath + path)
  })
}

export const translateValueFromPath = (
  original,
  path,
  merge,
  cb,
  progress = () => {}
) => {
  progress({
    status: '0',
    percent: 10,
  })
  let language = I18n.getSelectedLanguage()
  progress({
    status: '1',
    percent: 20,
  })
  if (!language || language === 'es') {
    progress({
      status: '2',
      percent: 100,
    })
    cb({
      val: () => {
        return original
      },
      forEach: () => {
        return original.forEach
      },
    })
  } else {
    progress({
      status: '3',
      percent: 50,
    })
    if (merge) {
      progress({
        status: '4',
        percent: 70,
      })
      mergeWithI18nField(original, path, language, cb)
    } else {
      progress({
        status: '5',
        percent: 80,
      })
      getI18nField(path, language, cb)
    }
  }
}

export const getElasticIndex = () => {
  return new Promise((resolve, reject) => {
    resolve(
      {
        es: 'firebase',
        en: 'firebase-en',
        it: 'firebase-it',
        pt: 'firebase-pt',
        ca: 'firebase-ca',
        fr: 'firebase-fr',
      }[I18n.getSelectedLanguage()] || 'firebase'
    )
  })
}
